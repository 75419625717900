import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { InputField } from "../../../cms/components/commons";

const InteriorForm = ({ control, data, setValue }) => {
  const filterUnitInteriorSize = useSelector(
    (state) => state.unitExplore.filterUnitInteriorSize
  );

  const value = useMemo(() => data?.subComponents[0]?.props, [data]);

  useEffect(() => {
    if (filterUnitInteriorSize) {
      filterUnitInteriorSize?.min &&
        setValue("minInterior", filterUnitInteriorSize?.min);
      filterUnitInteriorSize?.max &&
        setValue("maxInterior", filterUnitInteriorSize?.max);
    }
  }, [filterUnitInteriorSize]);

  return (
    <div className="py-5">
      <h2 className="font-normal mb-4 text-primary font-sans text-[14px] leading-[12px] tracking-[1.4px] uppercase mb-0">
        Interior
      </h2>
      <InputField
        controller={{
          control,
          name: "minInterior",
        }}
        inputClassName="border-none"
        labelClassName="px-2 pt-2 uppercase mb-1"
        wrapperClassName="border border-gray-500 w-full mb-4"
        inputProps={{
          type: "number",
          placeholder: value?.min,
        }}
        label="Min"
      />
      <InputField
        controller={{
          control,
          name: "maxInterior",
        }}
        inputClassName="border-none"
        labelClassName="px-2 pt-2 uppercase mb-1"
        wrapperClassName="border border-gray-500 w-full"
        inputProps={{
          type: "number",
          placeholder: value?.max,
        }}
        label="Max"
      />
    </div>
  );
};

export default InteriorForm;
