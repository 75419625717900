/** @format */

import SideNav from "../../sidenav";
import UnitDetailFooter from "./footer";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../../helper/socket";
import UnitName from "../components/unit-name";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FEATURES_ENUM } from "../../../constants/modules";
import UnitDetailCollapsible from "./unit-detail-collapsible";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { toast } from "react-toastify";
import useQuery from "../../../hooks/useQuery";
import customerApi from "../../../apis/api/customer";
import preferenceApi from "../../../apis/api/preference";
import { reqGetSession } from "../../../reduxs/precinct-explore/action";
import StarButton from "../components/star-button/StarButton";
import { ReactComponent as Like } from "../../../assets/images/compare/icLike.svg";
import { ReactComponent as UnLike } from "../../../assets/images/compare/icUnLike.svg";
import { reqGetCustomerProfile } from "../../../reduxs/user/action";

const UnitDetail = (props) => {
  const {
    visible = false,
    featureConfig = null,
    isPresentation,
    isTransparent,
  } = props;

  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const { query } = useQuery();
  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const session = useSelector((state) => state.precinctExplore.session);
  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const customerId = query?.customer;
  const sessionId = query?.session;
  const [starred, setStarred] = useState(false);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollPercentage *
          (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
    if (content.action === ACTION_NAME.COMPARE_UN_SAVE_PREFERENCE) {
      getSession();
    }
  };

  const getSession = () => {
    dispatch(reqGetSession(sessionId));
  };

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  };

  useEffect(() => {
    sessionId && dispatch(reqGetSession(sessionId));
  }, []);

  const sendToastMessage = (message, type) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
        message,
        type,
      });
    }
    toast[type](message, {
      toastId: `socket-toast-${Date.now()}`,
    });
  };

  const handleSavePreference = async () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.COMPARE_UN_SAVE_PREFERENCE);
    }
    if (!selectedUnit) {
      return sendToastMessage("Please select an unit!", "error");
    }
    setStarred(true);
    let data = {};

    if (selectedUnit) {
      let unitIds = getPreferenceUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;

      const res = await preferenceApi?.savePreferenceOfSession(
        session?.preference?.id,
        {
          unit: selectedUnit?.id,
        }
      );

      if (res?.data) {
        dispatch(reqGetSession(sessionId));
        sendToastMessage("Saved Preference successfully!", "success");
        analytics.track("Agent Saved Property", {
          agentId: authUser?.id,
          clientId: customer?.id,
          clientEmail: customer?.email,
          clientPhone: customer?.mobile,
          propertyId: selectedUnit?.id,
        });
      } else {
        sendToastMessage("Save preference failed!", "error");
      }

      if (customer && customer?.favorite?.id) {
        const resCustomerFav = await customerApi?.updateCustomerFavorite(
          customer?.favorite?.id,
          {
            unit: selectedUnit?.id,
          }
        );

        if (resCustomerFav?.data) {
          dispatch(reqGetCustomerProfile(customer.id));
        }
      }
    }
  };

  const handleUnsavePreference = async () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.COMPARE_UN_SAVE_PREFERENCE);
    }
    if (!selectedUnit) {
      return sendToastMessage("Please select an unit!", "error");
    }
    setStarred(false);
    let data = {};

    if (selectedUnit) {
      let unitIds = getPreferenceUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;

      const res = await preferenceApi?.unSavePreferenceOfSession(
        session?.preference?.id,
        {
          removeUnit: selectedUnit?.id,
        }
      );

      if (res?.data) {
        dispatch(reqGetSession(sessionId));
        sendToastMessage("Unsaved Preference successfully!", "success");
        analytics.track("Agent Unsaved Property", {
          agentId: authUser?.id,
          clientId: customer?.id,
          clientEmail: customer?.email,
          clientPhone: customer?.phone,
          propertyId: selectedUnit?.id,
        });
      } else {
        sendToastMessage("Unsaved preference failed!", "error");
      }

      if (customer && customer?.favorite?.id) {
        const resCustomerFav = await customerApi?.updateCustomerFavorite(
          customer?.favorite?.id,
          {
            removeUnit: selectedUnit?.id,
          }
        );

        if (resCustomerFav?.data) {
          dispatch(reqGetCustomerProfile(customer.id));
        }
      }
    }
  };

  const getPreferenceUnitIds = useCallback(() => {
    const units = session?.preference?.units || [];
    return units.map((item) => item.id);
  }, [session]);

  const getCompareUnitIds = useCallback(() => {
    const units = session?.preference?.comparingUnits || [];
    return units.map((item) => item.id);
  }, [session]);

  const getLikedUnitIds = useCallback(() => {
    const units = session?.preference?.unitsShortlist || [];
    return units.map((item) => item.id);
  }, [session]);

  const handleLike = async () => {
    const res = await preferenceApi?.likePreferenceOfSession(
      session?.preference?.id,
      {
        addComparingUnits: [selectedUnit?.id],
        likeUnit: selectedUnit?.id,
      }
    );
    if (res?.data) {
      dispatch(reqGetSession(sessionId));
      if (customer && customer?.favorite?.id) {
        const resCustomerFav = await customerApi?.updateCustomerFavorite(
          customer?.favorite?.id,
          { likeUnit: selectedUnit?.id }
        );
        if (resCustomerFav?.data) {
          dispatch(reqGetCustomerProfile(customer.id));
        }
      }
    }
  };

  const handleUnLike = async () => {
    const res = await preferenceApi?.unLikePreferenceOfSession(
      session?.preference?.id,
      {
        removeComparingUnits: [selectedUnit?.id],
        unlikeUnit: selectedUnit?.id,
      }
    );

    if (res?.data) {
      dispatch(reqGetSession(sessionId));
      if (customer && customer?.favorite?.id) {
        const resCustomerFav = await customerApi?.updateCustomerFavorite(
          customer?.favorite?.id,
          { unlikeUnit: selectedUnit?.id }
        );
        if (resCustomerFav?.data) {
          dispatch(reqGetCustomerProfile(customer.id));
        }
      }
    }
  };

  const renderStarBtn = useCallback(() => {
    const unitIds = getPreferenceUnitIds();

    if (unitIds.includes(selectedUnit?.id)) {
      return (
        <span
          className="h-[45px] w-[45px] rounded-full flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black"
          onClick={handleUnsavePreference}
        >
          <StarButton selected starred={starred} setStarred={setStarred} />
        </span>
      );
    }

    return (
      <span
        className="h-[45px] w-[45px] rounded-full flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black"
        onClick={handleSavePreference}
      >
        <StarButton starred={starred} setStarred={setStarred} />
      </span>
    );
  }, [getPreferenceUnitIds, selectedUnit, starred]);

  const renderLikeBtn = useCallback(() => {
    const unitIds = getLikedUnitIds();

    if (unitIds.includes(selectedUnit?.id)) {
      return (
        <span className="cursor-pointer" onClick={handleUnLike}>
          <Like />
        </span>
      );
    }

    return (
      <span className="cursor-pointer" onClick={handleLike}>
        <UnLike />
      </span>
    );
  }, [getPreferenceUnitIds, selectedUnit, starred]);

  if (!visible || !featureConfig?.components?.some((c) => c?.props?.visible))
    return null;

  return (
    <div
      className={`wrap-sidenav flex absolute z-[1001] h-[calc(100svh_-_60px_-_60px)] top-0 bottom-0 mt-auto mb-auto left-auto right-0 w-[285px] ${
        isTransparent ? "invisible transform translate-x-[-100%]" : ""
      }`}
    >
      <SideNav
        id="sidenavDetail"
        position="right"
        isDark={false}
        footer={
          <UnitDetailFooter
            isPresentation={isPresentation}
            components={featureConfig?.components || []}
          />
        }
        className="!border-l-0 rounded-l-md flex w-[285px] px-[35px] py-[30px] flex-col items-start shrink-0 bg-brand"
        bodyClassName="!border-t-0"
      >
        <div
          ref={pageRef}
          onScroll={onScroll}
          className={"accordion-sidebar overflow-y-scroll disable-scroll-bar"}
        >
          <div className="flex gap-5">
            <UnitName
              unit={unit}
              {...(featureConfig?.components?.find(
                (c) => c.name === UnitName.displayName
              )?.props || {})}
            />
            {/* <StarIcon
              width={40}
              height={40}
              className="cursor-pointer"
              onClick={() => dispatch(reqSetPage(REACTUI_PAGES.COMPARE_PAGE))}
            /> */}
            <div className="flex flex-col gap-2">
              {renderStarBtn()}
              {renderLikeBtn()}
            </div>
          </div>
          <UnitDetailCollapsible
            components={featureConfig?.components || []}
            isPresentation={isPresentation}
          />
        </div>
      </SideNav>
    </div>
  );
};
UnitDetail.displayName = FEATURES_ENUM.UNIT_DETAIL;

export default UnitDetail;
