/** @format */

import React, { useEffect, useState } from "react";

import AssetContent from "./AssetContent";
import ContentUnitType from "./MediaContent";
import ContentUnitTypeStatic from "./MediaContentStatic";
import { DragDropContext } from "@hello-pangea/dnd";
import unitApi from "../../../../../apis/api/unit";
import { cn } from "../../../../../helper/utils";

const CustomUnit = (props) => {
  const { typeActive, unitId } = props;
  const playListRef = React.useRef(null);
  const [dataMediaType, setDataMediaType] = useState();
  const [isDynamic, setDynamic] = useState(true);

  const getUnitMediaType = () => {
    if (!isDynamic) return handleGetUnitDetail();
    const param = {
      type: typeActive,
    };
    unitApi.getUnitMediaType(unitId, param).then((res) => {
      if (res && res?.data) {
        setDataMediaType(res.data);
      }
    });
  };

  useEffect(() => {
    unitId && typeActive && getUnitMediaType();
  }, [typeActive, unitId, isDynamic]);

  const handleGetUnitDetail = () => {
    unitApi.getUnitDetail(unitId).then((res) => {
      if (res && res?.data) {
        setDataMediaType(res.data?.[typeActive]);
      }
    });
  };

  const onDragEnd = async (result) => {};

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex flex-row pl-5">
        <div
          onClick={() => setDynamic(true)}
          className={cn(
            "bg-white w-[100px] justify-center items-center flex h-10 rounded-t-[10px] cursor-pointer border border-b-transparent",
            !isDynamic && "bg-gray-100 border-none"
          )}
        >
          <span>Dynamic</span>
        </div>
        <div
          onClick={() => setDynamic(false)}
          className={cn(
            "bg-white w-[100px] justify-center items-center flex h-10 rounded-t-[10px] cursor-pointer border border-b-transparent",
            isDynamic && "bg-gray-100 border-none"
          )}
        >
          <span>Static</span>
        </div>
      </div>
      <div className="max-h-[85vh] gap-5 flex flex-row ">
        <div className="h-[80vh]  border-solid flex-1 bg-white rounded-[20px] p-5 relative overflow-hidden">
          {isDynamic ? (
            <ContentUnitType
              {...props}
              ref={playListRef}
              name={typeActive}
              medias={dataMediaType}
              unitId={unitId}
            />
          ) : (
            <ContentUnitTypeStatic
              {...props}
              ref={playListRef}
              name={typeActive}
              medias={dataMediaType}
              unitId={unitId}
            />
          )}
        </div>
        {!isDynamic && (
          <div className="h-[76vh] w-[5px] bg-[rgb(227,227,227)]" />
        )}
        {!isDynamic && (
          <div className="h-[76vh] flex-1 bg-white rounded-[20px] p-5 relative overflow-hidden ">
            <AssetContent
              {...props}
              mediaType={typeActive}
              unitId={unitId}
              handleRefresh={handleGetUnitDetail}
            />
          </div>
        )}
      </div>
    </DragDropContext>
  );
};

export default CustomUnit;
