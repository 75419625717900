import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@headlessui/react";
import _, { debounce } from "lodash";
import { toast } from "react-toastify";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";

import { reqGetVirtualTours } from "../../../../reduxs/virtual-tour/action";
import { SpinLoading } from "../../../../components/svgs/icons";
import EmptyTable from "../../configuration/components/Table/EmptyTable";
import { Button } from "../../components/commons";
import { cn } from "../../../../helper/utils";
import amenitiesApi from "../../../../apis/api/amenities";
import { reqGetListAmenities } from "../../../../reduxs/cms/action";

const VirtualTourSelecting = ({ setIsShow, amenity }) => {
  const { virtualTour } = amenity;
  const dispatch = useDispatch();

  const dataRes = useSelector((state) => state.virtualTour.list);

  const virtualToursRes = useMemo(
    () =>
      dataRes?.filter((v) => v?.data?.scenes && v?.data?.scenes?.length > 0),
    [dataRes]
  );

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedVirtualTour, setSelectedVirtualTour] = useState(
    virtualTour?.id || ""
  );

  useEffect(() => {
    dispatch(reqGetVirtualTours({}, setLoading));
  }, []);

  const onSearch = useCallback(
    debounce((searchText) => {
      dispatch(
        reqGetVirtualTours(
          _.omitBy({ "name[regex]": searchText }, _.isNil),
          setLoading
        )
      );
    }, 500),
    []
  );

  // Update the search state and trigger debounced API call
  const onChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    onSearch(value);
  };

  const onSave = async () => {
    setSaveLoading(true);
    const res = await amenitiesApi.updateAmenity(amenity.id, {
      virtualTour: selectedVirtualTour,
    });

    if (res) {
      dispatch(
        reqGetListAmenities({
          sortBy: JSON.stringify({
            name: 1,
          }),
        })
      );
      setIsShow(false);
      toast.success("Update amenity successfully");
    } else {
      toast.error("Update amenity failed");
    }

    setSaveLoading(false);
  };

  return (
    <div>
      <h2 className="font-medium font-poppins font-semibold">
        Virtual tour selecting
      </h2>
      <p>Please select virtual tour to add amenity</p>
      <div className="relative my-5">
        <input
          type="text"
          value={search}
          onChange={onChange}
          placeholder="Search..."
          className="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
        </div>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-[300px]">
          <SpinLoading className="w-[50px] h-[50px]" />
        </div>
      )}
      {!loading && !!virtualToursRes?.length && (
        <div className="grid grid-cols-3 gap-5 border border-gray-500 p-2 rounded h-[300px] overflow-y-auto">
          {virtualToursRes.map((virtualTour) => (
            <div
              key={virtualTour.id}
              className={cn(
                "cursor-pointer shadow rounded border border-gray-500 h-fit",
                {
                  "border-green-500 border-2":
                    selectedVirtualTour === virtualTour.id,
                }
              )}
              onClick={() => setSelectedVirtualTour(virtualTour.id)}
            >
              <img
                src={virtualTour.data.scenes[0].url}
                className="object-cover w-full h-[150px]"
              />
              <div className="flex flex-wrap items-center justify-between pb-5 px-2">
                <p className="mt-1 pr-2 break-words w-[80%]">
                  {virtualTour.name}
                </p>
                <Checkbox
                  className="w-4 h-4 group border border-black group flex items-center justify-center rounded"
                  onChange={() => setSelectedVirtualTour(virtualTour.id)}
                  checked={selectedVirtualTour === virtualTour.id}
                >
                  <div className="hidden w-3 h-3 bg-green-500 group-data-[checked]:block rounded" />
                </Checkbox>
              </div>
            </div>
          ))}
        </div>
      )}
      {!loading && !virtualToursRes?.length && (
        <div className="flex justify-center items-center h-[300px] border border-gray-500 rounded">
          <div>
            <EmptyTable>
              <h2 className="font-semibold">No virtual tour</h2>
            </EmptyTable>
          </div>
        </div>
      )}
      <div className="mt-10 flex gap-5">
        <Button
          disabled={saveLoading}
          variant="text"
          className="flex-1 py-3"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          isLoading={saveLoading}
          className="flex-1 py-3"
          disabled={
            !selectedVirtualTour ||
            (virtualTour && virtualTour?.id === selectedVirtualTour) ||
            saveLoading
          }
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default VirtualTourSelecting;
