/** @format */

import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import {
  reqGetThemes,
  reqGetConfigs,
  reqGetActiveConfig,
} from "../../reduxs/cms/action";
import {
  reqGetUserProfile,
  reqGetCustomerProfile,
  reqSetCustomerProfile,
  actRemoveSessionId,
} from "../../reduxs/user/action";
import { toast } from "react-toastify";
import socket from "../../helper/socket";
import React, { useEffect, useMemo, useImperativeHandle } from "react";
import jwtAuth from "../../apis/utils/jwtAuth";
import { classNames } from "../../helper/utils";
import { useDispatch, useSelector } from "react-redux";
import { ShowcaseSelector } from "./showcase-selector";
import { useNavigate, useLocation } from "react-router-dom";
import { MODULES_ENUM, PAGES_ENUM } from "../../constants/modules";
import {
  ActiveStarIcon,
  CompareIcon,
  CompareIconV2,
  InActiveStarIcon,
  StarIcon,
} from "../svgs/icons";
import { ReactComponent as Logout } from "../../assets/images/icLogout.svg";
import { ReactComponent as Person } from "../../assets/images/iconPerson.svg";
import { reqSetIsShowExploreModal, reqSetPage } from "../../reduxs/home/action";
import { reqGetSession } from "../../reduxs/precinct-explore/action";
import useQuery from "../../hooks/useQuery";
import _ from "lodash";
import { actSetCompareHistory } from "../../reduxs/compare/action";

const BottomNavigation = React.forwardRef(
  (
    {
      handleClickEndGuideTenantSession,
      handleClickTenantEndSession,
      isPresentation,
      setActiveObjectIds,
      resetState,
      onSelectProfile,
      setIsShowModal,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { query } = useQuery();

    const customerId = query?.customer;
    const sessionId = query?.session;

    const customer = useSelector((state) => state.user.customer);
    const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
    const isShowFloorplan = useSelector(
      (state) => state.unitExplore.isShowFloorplan
    );
    const compareHistory = useSelector((state) => state.compare.compareHistory);
    const session = useSelector((state) => state.precinctExplore.session);
    const authUser = useSelector((state) => state.user.data);
    const isShowEndGuideTenantSession = useSelector(
      (state) => state.home.isShowEndGuideTenantSession
    );
    const reactUiPage = useSelector((state) => state.home.reactUiPage);
    const pages = useSelector((state) => state.configuration.pages);
    const moduleVisible = useMemo(() => {
      return pages
        ?.find(
          (page) =>
            page?.name === PAGES_ENUM.POST_SESSION && !!page?.props?.visible
        )
        ?.modules?.find(
          (module) =>
            module?.name === MODULES_ENUM.POST_SESSION &&
            !!module?.props?.visible
        );
    }, [pages]);

    const loadThemes = async () => {
      dispatch(reqGetThemes());
    };

    const loadConfig = async () => {
      dispatch(reqGetActiveConfig());
      dispatch(reqGetConfigs());
    };

    useEffect(() => {
      loadConfig();
      loadThemes();
      dispatch(reqGetUserProfile());
    }, []);

    const listenerSharedUIAction = ({ content }) => {
      if (content.action == ACTION_NAME.CLICK_END_SESSION) {
        moduleVisible
          ? handleShowPostSessionPopup()
          : handleClickEndSessionWithInvisible();
        navigate(
          `/presentation?customer=${content?.data?.customerId}&session=${content?.data?.sessionId}`
        );
      }
      if (content.action == ACTION_NAME.END_SESSION) {
        onEndGuidedSession(content.cb);
        !moduleVisible && navigate("/presentation", { replace: true });
        !moduleVisible && dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
      }
      if (content.action == ACTION_NAME.CLICK_COMPARE) {
        handleClickCompare();
      }
    };

    useEffect(() => {
      if (isPresentation) {
        socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      }
      return () => {
        socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      };
    }, [isPresentation, moduleVisible]);

    useEffect(() => {
      if (customerId !== "undefined") {
        if (customerId !== customer?.id) {
          getCustomerPreference();
          getCustomerDetail();
        }
      } else {
        if (customer && customer?.id) {
          dispatch(reqSetCustomerProfile(""));
        }
      }
    }, [customerId, customer, sessionId]);

    const onClickUserIcon = () => {
      window.location.href = "/cms";
    };

    // const handleReloadComponents = () => {
    //   loadConfig();
    //   loadThemes();
    //   dispatch(reqGetUserProfile());
    //   dispatch(reqGetPageList());
    //   dispatch(reqGetMeshes());
    // };

    const getCustomerDetail = () => {
      try {
        dispatch(reqGetCustomerProfile(customerId));
      } catch (error) {
        toast.error(error?.message);
      }
    };

    const getCustomerPreference = async () => {
      try {
        sessionId && dispatch(reqGetSession(sessionId));
      } catch (error) {
        toast.error(error?.message);
      }
    };

    const handleShowPostSessionPopup = () => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION, {
          customerId,
          sessionId,
        });
      }
      dispatch(reqSetIsShowExploreModal(false));
      setActiveObjectIds([]);
      analytics.track("Agent Ended Session", {
        agentId: authUser?.id,
        clientId: customer?.id,
        clientEmail: customer?.email,
        clientPhone: customer?.mobile,
      });
      dispatch(reqSetPage(REACTUI_PAGES.SESSION_PAGE));
    };

    const sendToastMessage = (message, type) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
          message,
          type,
        });
      }
      toast[type](message, {
        toastId: `socket-toast-${Date.now()}`,
      });
    };

    const getPreferenceUnitIds = () => {
      const units = session?.preference?.units || [];

      return units.map((item) => item.id);
    };

    const handleLogout = async () => {
      setActiveObjectIds([]);
      await jwtAuth.removeToken();
      navigate("/holding", { replace: true });
      dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
    };

    const onEndGuidedSession = async (cb = () => {}) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.END_SESSION, { cb });
      }
      if (!customer) {
        setIsShowModal(true);
      } else {
        dispatch(actRemoveSessionId());
        cb();
      }
    };

    const endGuided = () =>
      moduleVisible
        ? handleShowPostSessionPopup()
        : handleClickEndSessionWithInvisible();

    useImperativeHandle(ref, () => ({
      endGuided,
    }));

    const handleClickEndSessionWithInvisible = () => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION);
      }
      dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
      navigate(isPresentation ? "/presentation" : "/", { replace: true });
      dispatch(reqSetCustomerProfile(""));
    };

    const renderLogoutBtn = () => {
      if (!authUser) return;

      return (
        <div onClick={handleLogout} className="cursor-pointer logout-btn mr-2">
          <Logout className="*:fill-[var(--navigation-text-color)]" />
        </div>
      );
    };

    // const renderReload = () => {
    //   return (
    //     <span
    //       className="text-navigation text-right text-xs not-italic font-sans font-normal leading-[normal] tracking-[1.2px] uppercase cursor-pointer"
    //       onClick={handleReloadComponents}
    //     >
    //       {!loading ? "REFRESH UI" : ". . ."}
    //     </span>
    //   );
    // };

    const connectProfile = () => {
      if (customer) return;

      onSelectProfile();
    };

    const renderCustomerName = () => {
      if (reactUiPage !== REACTUI_PAGES.ONBOARD_PAGE) {
        return (
          <span
            onClick={connectProfile}
            className="text-navigation font-sans text-right text-xs not-italic font-normal leading-[normal] tracking-[1.2px] uppercase"
          >
            {customer
              ? `${customer?.firstname} ${customer?.surname}`
              : `connect a profile`}
          </span>
        );
      }
    };

    const renderUserBtn = () => {
      if (reactUiPage === REACTUI_PAGES.ONBOARD_PAGE) {
        return (
          <span className="h-[50px] flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 pr-10 p-[unset]">
            <Person
              className="cursor-pointer *:fill-[var(--navigation-text-color)]"
              onClick={() => onClickUserIcon()}
            />
          </span>
        );
      }
    };

    const handleClickCompare = () => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_COMPARE);
      }
      if (selectedUnit && compareHistory) {
        const valuesCompare = compareHistory[`floor-${selectedUnit.level}`];

        const arrayValueCompare = _(compareHistory)
          .values()
          .filter((value) => !_.isEmpty(value))
          .flatten()
          .value();
        const isExist = valuesCompare?.some((item) => item === selectedUnit.id);

        if (isExist) {
          dispatch(reqSetPage(REACTUI_PAGES.COMPARE_PAGE));
        } else if (!isExist && arrayValueCompare.length >= 4) {
          toast.warn("You can only compare up to 4 units at a time");
        } else {
          const newCompare = {
            [`floor-${selectedUnit?.level}`]: [selectedUnit?.id],
          };

          dispatch(
            actSetCompareHistory(
              !valuesCompare
                ? { ...compareHistory, ...newCompare }
                : {
                    ...compareHistory,
                    [`floor-${selectedUnit?.level}`]: [
                      ...valuesCompare,
                      selectedUnit?.id,
                    ],
                  }
            )
          );

          dispatch(reqSetPage(REACTUI_PAGES.COMPARE_PAGE));
        }
        return;
      }
      dispatch(reqSetPage(REACTUI_PAGES.COMPARE_PAGE));
    };

    const renderStarBtn = () => {
      if (reactUiPage === REACTUI_PAGES.ONBOARD_PAGE) return null;

      if (reactUiPage === REACTUI_PAGES.COMPARE_PAGE) {
        return (
          <span
            className="h-[44px] w-[60px] flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black"
            onClick={() => dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE))}
          >
            <CompareIconV2 className="*:fill-[var(--navigation-text-color)]" />
          </span>
        );
      }

      const disabled = compareHistory ? false : !selectedUnit;

      return (
        <button
          disabled={disabled}
          className={`h-[44px] w-[60px] flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black ${
            disabled ? "opacity-50" : "opacity-100"
          }`}
          onClick={handleClickCompare}
        >
          <CompareIcon className="*:fill-[var(--navigation-text-color)]" />
        </button>
      );
    };

    const renderEndSessionBtn = () => {
      // if (!customer || !customerId) return;

      return (
        <span
          className="text-navigation font-sans cursor-pointer text-xs not-italic font-normal leading-[normal] tracking-[1.2px] uppercase"
          onClick={() => onEndGuidedSession(endGuided)}
        >
          END GUIDED SESSION
        </span>
      );
    };

    if (isPresentation) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={classNames(
          "items-center bg-navigation flex h-11 fixed w-full z-[1000] pl-10 border-t-navigation border-t border-solid bottom-0 justify-between",
          reactUiPage === REACTUI_PAGES.VIEWS_LEVEL ? "linear-bottom" : "",
          reactUiPage === REACTUI_PAGES.SESSION_PAGE && "hidden",
          isShowFloorplan && "bg-[#FCFCFC]"
        )}
      >
        <div className="flex items-center text-[13px] font-[bold]">
          {/* {renderLanguageBtn()} */}
          {/*{renderEyeIcon()}*/}
          {!customer && !customerId && renderLogoutBtn()}
          {/*{false && renderMakeAppointmentBtn()}*/}

          {/* Hide while Session Summary in development */}
          {reactUiPage !== REACTUI_PAGES.ONBOARD_PAGE && renderEndSessionBtn()}
        </div>
        <ShowcaseSelector resetState={resetState} />
        <div className="flex items-center text-[13px] font-[bold] gap-[26px]">
          {renderCustomerName()}
          {/* {renderReload()} */}
          {renderStarBtn()}
          {renderUserBtn()}
        </div>
      </div>
    );
  }
);

export default BottomNavigation;
