import React, { useEffect, useMemo, useRef, useState } from "react";
import Marzipano from "marzipano";

import Info from "../../commons/Hotspot/Info";
import Link from "../../commons/Hotspot/Link";
import { renderInfoHotspots, renderLinkHotspots } from "../../utils";
import _ from "lodash";
import { Button } from "../../../components/commons";
import { ArrowLeftIcon } from "@heroicons/react/16/solid";

const PanoramaViewer = ({ data, setPreview }) => {
  const viewerRef = useRef(null);
  const sceneRef = useRef(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);

  const scenes = data.scenes;
  const { levels, url, initialViewParameters, linkHotspots, infoHotspots } =
    scenes[currentSceneIndex];

  const filterLinkHotspots = useMemo(
    () =>
      _.filter(
        linkHotspots,
        (hotspot) =>
          hotspot?.targetScene && _.some(scenes, ["id", hotspot.targetScene])
      ),
    [linkHotspots, scenes]
  );

  // Initialize viewer
  useEffect(() => {
    if (!data) return;

    const viewerElement = document.getElementById("panorama");
    const viewer = new Marzipano.Viewer(viewerElement, {
      controls: {
        mouseViewMode: "drag",
        touchViewMode: "drag",
        disableKeyboardCtrl: true,
      },
    });

    const geometry = new Marzipano.EquirectGeometry(levels);
    const source = Marzipano.ImageUrlSource.fromString(url, {
      cubeMapPreviewUrl: url,
      cubeMapPreviewFaceOrder: "bdflru",
    });
    const limiter = Marzipano.RectilinearView.limit.traditional(
      12288, // max size for the image
      (100 * Math.PI) / 180, // maximum pitch angle in radians
      (100 * Math.PI) / 180 // minimum pitch angle in radians
    );
    const view = new Marzipano.RectilinearView(initialViewParameters, limiter);

    const scene = viewer.createScene({
      source,
      geometry,
      view,
      pinFirstLevel: true,
    });
    scene.switchTo({ transitionDuration: 1000 });

    viewerRef.current = viewer;
    sceneRef.current = scene;

    // Autorotation
    if (data.settings?.autorotateEnabled) {
      const currentPitch = viewerRef.current.view().pitch();
      const currentFov = viewerRef.current.view().fov();

      const autorotate = new Marzipano.autorotate({
        yawSpeed: -0.1, // speed value
        targetPitch: currentPitch || 0,
        targetFov: currentFov || Math.PI / 2,
      });

      let autorotateTimeout;

      // Handle autorotation when mouse up
      const handleMouseUp = () => {
        if (autorotateTimeout) {
          clearTimeout(autorotateTimeout);
        }
        autorotateTimeout = setTimeout(() => {
          viewer.startMovement(autorotate);
        }, 500);
      };

      viewer.startMovement(autorotate); // start autorotation

      viewerElement.addEventListener("mouseup", handleMouseUp);
      viewerElement.addEventListener("touchend", handleMouseUp);

      return () => {
        if (autorotateTimeout) {
          clearTimeout(autorotateTimeout);
        }
        viewer.destroy();
        viewerElement.removeEventListener("mouseup", handleMouseUp);
        viewerElement.removeEventListener("touchend", handleMouseUp);
      };
    }

    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroyAllScenes();
        viewerRef.current.destroy();
      }
      // Clear references
      viewerRef.current = null;
      sceneRef.current = null;
    };
  }, [data, currentSceneIndex]);

  // Add hotspots
  useEffect(() => {
    if (sceneRef.current) {
      // Destroy old hotspots
      sceneRef.current
        .hotspotContainer()
        .listHotspots()
        .forEach((hotspot) => {
          sceneRef.current.hotspotContainer().destroyHotspot(hotspot);
        });

      // Add infoHotspots
      renderInfoHotspots({ infoHotspots, sceneRef });

      // Add linkHotspots
      renderLinkHotspots({
        linkHotspots: filterLinkHotspots,
        sceneRef,
        isView: true,
        onClick: (data) => {
          const indexScene = scenes.findIndex(
            (scene) => scene.id === data.targetScene
          );

          if (indexScene === -1) return;

          setCurrentSceneIndex(indexScene);
        },
      });
    }
  }, [infoHotspots, filterLinkHotspots]);

  return (
    <div className="w-full h-screen relative">
      <div className="absolute z-10 top-5 left-5">
        <Button
          onClick={() => setPreview(false)}
          icon={<ArrowLeftIcon width={20} height={20} />}
        >
          Setting
        </Button>
      </div>
      <div id="panorama" className="w-full h-full absolute" />
    </div>
  );
};

export default PanoramaViewer;
