import * as type from './type';

const initialState = {
  data: '',
  metadata: {},
  obj: {},
  customer: '',
  sessionId: ''
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_USER_PROFILE:
      return {
        ...state,
        data: action.data.data,
        metadata: action.data.metadata,
      };
    case type.GET_CUSTOMER_PROFILE:
      return {
        ...state,
        customer: action.data.data,
      };
    case type.SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.data,
      };
    case type.REMOVE_SESSION_ID:
      return {
        ...state,
        sessionId: '',
      };
    default:
      return state;
  }
};
