import React from "react";
import { useController } from "react-hook-form";
import { cn } from "../../../../helper/utils";

import TextEditor from "./textEditor";

function TextEditorField(props) {
  const {
    textEditorProps,
    controller,
    errorCol = "w-full",
    isReadOnly = false,
    wrapperClassName,
    callbackOnchange,
  } = props;
  const { fieldState, field, formState } = useController(controller);
  const { error } = fieldState;

  const onChange = (e) => {
    const value = e.target.value;
    //help me check empty value

    const cleanContent = value
      .replace(/<[^>]*>/g, "") // remove HTML tags
      .replace(/&nbsp;/g, " ") // convert &nbsp; to normal space
      .replace(/\s+/g, " ") // normalize spaces
      .trim(); // remove leading/trailing spaces

    // Set the value based on empty check
    let finalValue;
    if (!cleanContent) {
      finalValue = "";
    } else {
      finalValue = value;
    }

    field.onChange(finalValue);
    callbackOnchange && callbackOnchange?.(finalValue);
  };

  return (
    <div className={cn(wrapperClassName)}>
      <div
        className={cn(
          "flex flex-wrap w-full [&>.rsw-editor]:w-full [&>.rsw-editor]:!border-black ",
          {
            "[&>.rsw-editor]:!border-none": isReadOnly,
            "[&>.rsw-editor]:!border-[#f44336]": error,
          }
        )}
      >
        <TextEditor
          {...textEditorProps}
          autoFocus
          className="max-h-[100px] overflow-y-auto"
          isReadOnly={isReadOnly}
          name={field.name}
          value={field.value || ""}
          onChange={onChange}
        />
        {error && (
          <div className={`${errorCol}`}>
            <p
              className={cn({
                "text-[#f44336] text-[12px]": error,
              })}
            >
              {error.message}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TextEditorField;
