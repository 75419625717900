import React, { useMemo } from "react";
import { useForm } from "react-hook-form";

import {
  AutocompleteField,
  Button,
} from "../../../../pages/cms/components/commons";
import _ from "lodash";
import userApi from "../../../../apis/api/user";

const StartNewSession = ({ onStartNewSession, onClose }) => {
  const { control, formState, handleSubmit } = useForm();

  const loadAgent = async (inputValue) => {
    const response = await userApi.getAgentList(
      _.omitBy(
        {
          "name[regex]": decodeURIComponent(inputValue),
        },
        _.isNil
      )
    );

    if (response?.data) {
      return response.data.map((agent) => ({
        label: agent.name,
        value: agent.id,
      }));
    }
    return [];
  };

  const options = useMemo(
    () =>
      _.debounce((inputValue, callback) => {
        loadAgent(inputValue, callback)
          .then((options) => callback(options))
          .catch(() => callback([]));
      }, 500), // Delay 500ms
    [],
    []
  );

  const onSubmit = async (data) => {
    await onStartNewSession(
      {
        agentId: data?.agent?.value,
        label: data?.agent?.label,
      },
      onClose
    );
  };

  return (
    <div>
      <h2 className="text-center font-bold text-xl mt-5">Select agent</h2>
      <div className="flex gap-2">
        <div className="flex-1">
          <AutocompleteField
            controller={{
              control,
              name: "agent",
            }}
            placeholder="Search agent"
            autocompleteProps={{
              loadOptions: options,
              maxMenuHeight: 300,
            }}
            label="Agent"
          />
        </div>
      </div>
      <div className="pt-40 flex gap-2">
        <Button
          disabled={formState?.isSubmitting}
          onClick={onClose}
          className="py-4 flex-1"
          variant="text"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isDirty || formState?.isSubmitting}
          isLoading={formState?.isSubmitting}
          className="py-4 flex-1"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default StartNewSession;
