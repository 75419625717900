/** @format */

import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import LoadingTable from "../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../configuration/components/Table/EmptyTable";
import { Button, Modal } from "../../components/commons";
import { AddUnitMediaModal } from "./AddUnitMediaModal";
import { useState } from "react";
import BulkEditorForm from "./bulkEditorForm";
import { reqGetListBulkOptions } from "../../../../reduxs/cms/action";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import mediaApi from "../../../../apis/api/media";
import TrashIcon from "../../configuration/components/svgs/TrashIcon";
import { toast } from "react-toastify";
import EditIcon from "../../configuration/components/svgs/EditIcon";
import Pagination from "../../components/Pagination/Pagination";
import Search from "../../gallery/Search";
import useDebouncedSearch from "../../../../hooks/useDebouncedSearch";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const PAGE_SIZE = 8;

const UnitMedia = () => {
  const dispatch = useDispatch();
  const [isOpenedAddUnitMediaModal, setIsOpenedAddUnitMediaModal] =
    useState(false);
  const [mediaSelectedList, setMediaSelected] = useState([]);
  const [allMedias, setAllMedias] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const refMediaDelete = useRef(null);
  const refTotalPage = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  // pre-fetching
  const queryClient = useQueryClient();
  const [search, setSearch, loading] = useDebouncedSearch();
  const [bedrooms, setBedrooms] = useState("");
  const [level, setLevel] = useState("");
  const [unitType, setUnitType] = useState("");
  const refParams = useRef({
    forUnit: 1,
    meta: JSON.stringify({ page: currentPage, pageSize: PAGE_SIZE }),
    search: search,
  });

  useEffect(() => {
    if (currentPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery([
        "medias-for-units",
        nextPage,
        () => mediaApi.getMediaList(refParams.current),
      ]);
    }
  }, [currentPage, queryClient]);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["medias-for-units", currentPage],
    queryFn: async () => {
      const res = await mediaApi.getMediaList(refParams.current);

      if (res?.data) {
        setAllMedias((prevMedias) => {
          const uniqueMedias = [...prevMedias, ...res?.data].filter(
            (media, index, self) =>
              index === self.findIndex((m) => m.id === media.id)
          );
          return uniqueMedias;
        });
      }

      return res;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (bedrooms !== "") {
      refParams.current = {
        ...refParams.current,
        "unitMetadata.bedrooms": [bedrooms],
      };
    }
    refetch();
  }, [search, bedrooms]);

  useEffect(() => {
    dispatch(reqGetListBulkOptions());
  }, []);

  const handleDeleteMedia = async () => {
    try {
      const deleted = await mediaApi.deleteMedia(refMediaDelete.current);
      if (deleted.data) {
        toast.success("Content deleted successfully!");
        refetch();
        refMediaDelete.current = null;
      }
    } catch (err) {
      toast.error(`Error Deteted!`);
      console.log("Error Deteted", err);
    } finally {
      setShowModal(false);
    }
  };

  const handleShowModalDeleteMedia = (id) => {
    refMediaDelete.current = id;
    setShowModal(true);
  };

  const getDataPagination = (page) => {
    setCurrentPage(page);
  };

  const medias = data?.data || [];
  const unitPlaylistId = data?.unitPlaylistId;
  const titleTable =
    medias &&
    medias?.[0] &&
    medias?.[0]?.unitMetadata &&
    Object.keys(medias[0].unitMetadata);
  const uiColumns = data?.uiColumns;
  if (data?.total) {
    refTotalPage.current = data?.total;
  }
  const renderUnitListMetadata = (item) => {
    const data =
      (item && item?.unitMetadata && Object.entries(item.unitMetadata)) || [];
    const newData = [];
    for (const i of data) {
      if (
        i[0] !== "areaSqm" &&
        i[0] !== "interior" &&
        i[0] !== "price" &&
        i[0] !== "availabilityStatus" &&
        i[0] !== "excludeUnits"
      ) {
        newData.push(i);
      }
    }
    return (
      <>
        {newData &&
          newData.length > 0 &&
          newData.map((val, idx) => {
            let info = val && val.length > 1 && val[1].join(", ");
            if (val[0] === "hasPool") {
              info = info.replace("true", "yes").replace("false", "no");
            }
            return (
              <td key={`${val[0]}-${idx}`} className={thCss}>
                <p className="max-w-[calc(100vw/6)] break-words whitespace-pre-wrap">
                  {info}
                </p>
              </td>
            );
          })}
      </>
    );
  };

  const handleSelect = (item) => {
    const isChecked = mediaSelectedList.includes(item?.id);
    const newArray = !isChecked
      ? [...mediaSelectedList, item?.id]
      : mediaSelectedList.filter((p) => p !== item?.id);
    setMediaSelected(newArray);
  };

  const totalPage = data?.total || refTotalPage.current;

  return (
    <>
      <div className="bg-[rgb(244,244,244)] p-4 h-[calc(100dvh-150px)]">
        <div className="bg-white p-4 rounded-md relative h-full">
          <div className="flex justify-between items-center mb-2 gap-5">
            <Search
              wrapperClassName={"w-full"}
              callbackOnInputChange={setSearch}
            />
            <div className="flex flex-row justify-end items-center w-[50%] gap-5">
              <Button
                className="py-3"
                onClick={() => setIsOpenedAddUnitMediaModal(true)}
              >
                UPLOAD UNIT IMAGES
              </Button>
              <Modal
                disabled={!mediaSelectedList?.length}
                panelClassName="w-[600px] overflow-visible"
                zIndex="z-[9998]"
                maskClassName="bg-opacity-50"
                content={({ setIsShow }) => (
                  <BulkEditorForm
                    setIsShow={setIsShow}
                    mediaSelectedList={mediaSelectedList} //string[]
                    mediasRes={allMedias}
                    onSuccess={() => {
                      setIsShow(false);
                      setAllMedias([]);
                      setMediaSelected([]);
                      dispatch(reqGetListBulkOptions());
                      refetch();
                    }}
                  />
                )}
                triggerClassName="inline-block"
                trigger={
                  <Button
                    disabled={!mediaSelectedList?.length}
                    icon={<EditIcon />}
                    className="py-3"
                  >
                    Bulk Editor
                  </Button>
                }
              />
            </div>
          </div>
          {(totalPage || medias?.length > 0) && (
            <Pagination
              resetPage={""}
              customStyle="bottom-2"
              total={totalPage}
              getDataPagination={getDataPagination}
              pageSize={PAGE_SIZE}
            />
          )}
          <div className=" h-[calc(100dvh-300px)] overflow-y-scroll">
            <table className="w-full border-collapse" id="unit-content-table">
              <thead>
                <tr className="text-sm font-semibold">
                  <th
                    className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                  />
                  <th
                    className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                  >
                    Name
                  </th>
                  <th
                    className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100 `}
                  >
                    <div className="flex flex-row gap-4">
                      Type
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="-mr-1 size-5 text-gray-400"
                      />
                    </div>
                  </th>
                  {titleTable &&
                    titleTable.map((val, idx) => {
                      if (
                        val !== "areaSqm" &&
                        val !== "interior" &&
                        val !== "price" &&
                        val !== "availabilityStatus" &&
                        val !== "excludeUnits"
                      ) {
                        return (
                          <th
                            key={`${val}-${idx}`}
                            className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                          >
                            <div className="flex flex-row gap-4">
                              {uiColumns[`${val}`]}

                              {val !== "exterior" && (
                                <ChevronDownIcon
                                  aria-hidden="true"
                                  className="-mr-1 size-5 text-gray-400"
                                />
                              )}
                            </div>
                          </th>
                        );
                      }
                    })}
                  <th
                    className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                  />
                </tr>
              </thead>
              {isLoading && (
                <tbody className="h-[500px]">
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        <LoadingTable />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {medias?.length === 0 && (
                <tbody className="h-[500px]">
                  <tr>
                    <td
                      colSpan="7"
                      className="text-center py-4 border border-t-0 border-gray-300"
                    >
                      <div className="flex justify-center items-center">
                        <EmptyTable />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {!isLoading && (
                <tbody>
                  {(medias || [])?.map((item, index) => {
                    return (
                      <tr
                        key={`${item.id}-${index}`}
                        className="text-sm transition-all lg:hover:bg-gray-100 cursor-grab"
                        onClick={(e) => {
                          handleSelect(item);
                        }}
                      >
                        <td className={`${thCss} w-10`}>
                          <input
                            type="checkbox"
                            className="cursor-pointer col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            value={item?.id}
                            checked={mediaSelectedList.includes(item?.id)}
                            // onChange={(e) =>
                            //   setMediaSelected(
                            //     e.target.checked
                            //       ? [...mediaSelectedList, item?.id]
                            //       : mediaSelectedList.filter(
                            //           (p) => p !== item?.id
                            //         )
                            //   )
                            // }
                          />
                          <svg
                            className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              className="opacity-0 group-has-[:checked]:opacity-100"
                              d="M3 8L6 11L11 3.5"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              className="opacity-0 group-has-[:indeterminate]:opacity-100"
                              d="M3 7H11"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </td>
                        {/* <td className={thCss}>{item?.id}</td> */}
                        <td className={`${thCss} w-[calc(100vw/4)]`}>
                          <p className="max-w-[calc(100vw/4)] break-words whitespace-pre-wrap">
                            {item?.name}
                          </p>
                        </td>
                        <td className={`${thCss} w-10`}>{item?.type}</td>
                        {renderUnitListMetadata(item)}
                        <td
                          className={`${thCss} w-10 sticky bg-white z-[2] right-0`}
                        >
                          <div
                            variant="text"
                            className="delete-icon text-red-500 "
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShowModalDeleteMedia(item?.id);
                            }}
                          >
                            <TrashIcon />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {isOpenedAddUnitMediaModal && (
        <AddUnitMediaModal
          show={isOpenedAddUnitMediaModal}
          setShow={setIsOpenedAddUnitMediaModal}
          playlistId={unitPlaylistId}
          onCreateSuccess={() => refetch()}
        />
      )}

      <Modal
        show={showModal}
        maskClassName="bg-opacity-50"
        zIndex="z-[9999]"
        content={({ setIsShow }) => (
          <div>
            <p className="text-center text-xl">
              Do you want to remove this Media ?
            </p>
            <div className="flex gap-4 items-center mt-5">
              <Button
                variant="text"
                className="flex-1"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="flex-1 bg-red-500 hover:bg-red-400"
                onClick={handleDeleteMedia}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      />
    </>
  );
};

const thCss =
  "text-left border whitespace-nowrap  overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

export default UnitMedia;
