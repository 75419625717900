/** @format */
import ItemCompare from "./ItemCompare";
import { cn } from "../../../helper/utils";
import { useEffect, useState } from "react";
import unitApi from "../../../apis/api/unit";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { useSelector } from "react-redux";

const MODE = {
  H: "H", // horizontal
  V: "V", // virtical
};
const ListCompare = ({
  data,
  classNameCustom,
  onRemove,
  preferenceUnitIds,
  idPreference,
  isPresentation,
  comparingUnitIds,
  likedUnitIds,
}) => {
  const features = useSelector((state) => state.configuration.features);
  const [mode, setMode] = useState(MODE["H"]);
  const [dataCompare, setDataCompare] = useState([]);
  const lengthDataCompare = data?.length;
  const numberRow = lengthDataCompare / 2 > 1 || mode === MODE["V"] ? 2 : 1;
  const itemHeight = `calc((100dvh - 56px - 56px) / ${numberRow})`;

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  useEffect(() => {
    if (features?.length > 0) {
      const components = features[0]?.components || [];
      const horizontal = components.find(
        (v) => v.name === "compare_floorplans_horizontal"
      );
      if (horizontal) {
        setMode(horizontal?.props?.visible ? MODE["H"] : MODE["V"]);
      }
    }
  }, [features]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action == ACTION_NAME.COMPARE_CHANGE_STYLE) {
      handleChangeStyle(content.data?.mode);
    }
  };

  const handleGetMediaCompare = async () => {
    const newDataCompare = [...dataCompare];
    if (lengthDataCompare < dataCompare.length) {
      for (const [index, i] of dataCompare.entries()) {
        const idx = data.findIndex((unitId) => unitId === i.id);
        if (idx === -1) {
          newDataCompare.splice(index, 1);
        }
      }
    } else {
      for (const u of data) {
        const idx = dataCompare.findIndex((i) => i.id === u);
        if (idx === -1) {
          const res = await unitApi.getUnitDetail(u);
          if (res && res.data) {
            newDataCompare.push(res.data);
          }
        }
      }
    }
    setDataCompare(newDataCompare);
  };

  // const handleChangeStyle = (val) => {
  //   if (!isPresentation) {
  //     socket.emitUIActionEvent(ACTION_NAME.COMPARE_CHANGE_STYLE, {
  //       mode: val,
  //     });
  //   }
  //   if (val === MODE["V"]) {
  //     setMode(MODE["H"]);
  //   } else {
  //     setMode(MODE["V"]);
  //   }
  // };

  useEffect(() => {
    handleGetMediaCompare();
  }, [data]);

  return (
    <div
      className={cn(
        "w-full h-full grid ",
        mode === MODE["H"] || lengthDataCompare > 2
          ? "grid-cols-2"
          : "grid-row-2",
        classNameCustom
      )}
    >
      {dataCompare &&
        dataCompare.map((i) => {
          return (
            <ItemCompare
              key={i.id}
              height={itemHeight}
              onRemove={() => onRemove(i.id, i?.level)}
              item={i}
              preferenceUnitIds={preferenceUnitIds || []}
              idPreference={idPreference}
              isPresentation={isPresentation}
              comparingUnitIds={comparingUnitIds}
              likedUnitIds={likedUnitIds}
            />
          );
        })}
      {/* <div
        onClick={() => handleChangeStyle(MODE[mode])}
        className="absolute flex justify-center items-center z-[9999] top-[60px] left-[80px] bg-black w-[50px] h-[50px] rounded-full opacity-50 cursor-pointer"
      >
        <span className="text-white font-bold">{MODE[mode]}</span>
      </div> */}
    </div>
  );
};

export default ListCompare;
