/** @format */

import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import rolesAccess from "../../../../apis/api/roles-access";
import { Modal, Button } from "../../components/commons";

const RolesAccessDetail = ({
  idSelected,
  isCreate = false,
  rules,
  refreshUserGroup,
}) => {
  const [name, setName] = useState();
  const [des, setDes] = useState();
  const [listRules, setRules] = useState();
  const [errors, setErrors] = useState({});
  const refDetails = useRef();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (!isCreate) {
      getDetailUserGroup();
    } else {
      handleResetValue();
    }
  }, [idSelected, isCreate]);

  const handleResetValue = () => {
    setName("");
    setDes("");
    setRules(rules);
  };

  const getDetailUserGroup = () => {
    try {
      rolesAccess.getDetailUserGroup(idSelected).then((res) => {
        if (res && res?.data) {
          const val = res.data?.rules;
          const newRules = rules.map((item) => ({
            ...item,
            checked: val.some((el) => el.id === item.id),
          }));
          setRules(newRules);
          setDes(res.data?.description);
          setName(res.data?.name);
          refDetails.current = res?.data;
        }
      });
    } catch (e) {
      console.log("Error get detail user group", e);
    }
  };

  const onChangeName = (value) => {
    if (!value) {
      setErrors({ ...errors, name: "Name is required" });
    } else {
      setErrors({ ...errors, name: undefined });
    }

    setName(value);
  };
  const onChangeDescription = (value) => {
    if (!value) {
      setErrors({ ...errors, des: "Description is required" });
    } else {
      setErrors({ ...errors, des: undefined });
    }

    setDes(value);
  };

  const onCheck = (isCheck, idx) => {
    try {
      const newArr = [...listRules];
      newArr[idx].checked = isCheck;
      setRules(newArr);
    } catch (err) {
      console.log("Error check rule", err);
    }
  };

  const handleSave = () => {
    if (name?.trim().length === 0 || des?.trim().length === 0) return;
    if (isCreate) return handleCreate();
    try {
      const rules = [];
      for (const i of listRules) {
        if (i.checked) {
          rules.push(i.id);
        }
      }
      const params = {
        name: name,
        description: des,
        rules: rules,
      };
      rolesAccess.editUserGroup(idSelected, params).then(() => {
        refreshUserGroup(true);
      });
      toast.success(`Updated successfully! `);
    } catch (err) {
      toast.error(`Error update!`);
      console.log("Error update rules", err);
    }
  };

  const handleCreate = () => {
    try {
      const rules = [];
      for (const i of listRules) {
        if (i.checked) {
          rules.push(i.id);
        }
      }
      const params = {
        name: name,
        description: des,
        rules: rules,
      };
      rolesAccess.createUserGroup(params).then(() => {
        refreshUserGroup(true);
      });
      toast.success(`Created successfully! `);
    } catch (err) {
      toast.error(`Error create!`);
      console.log("Error create user group", err);
    }
  };

  const handleDelete = () => {
    try {
      rolesAccess.deleteUserGroup(idSelected).then((res) => {
        if (res && res.data) {
          refreshUserGroup(true);
          toast.success(`Deteted successfully! `);
        } else {
          toast.error(res.toString());
        }
      });
    } catch (err) {
      toast.error(`Error Deteted!`);
      console.log("Error Deteted user group", err);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <section className="flex-1 h-full p-4 block">
      <div className="flex gap-2 items-center flex-row justify-between mb-1">
        <h2 className="capitalize font-semibold">
          {isCreate ? "" : "Details"}
        </h2>
        <div className="flex justify-center items-center gap-4">
          <div className="cursor-pointer" onClick={handleSave}>
            <p className="underline text-sm font-semibold">
              {isCreate ? "Create" : "Save"}
            </p>
          </div>
          {!isCreate && (
            <div className="cursor-pointer" onClick={() => setShowModal(true)}>
              <p className="underline text-sm font-semibold">Delete</p>
            </div>
          )}
        </div>
      </div>
      <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
      <div className="relative">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Name *
        </label>
        <div className="mt-2">
          <input
            onChange={(e) => onChangeName(e.target.value)}
            value={name}
            id="name"
            name="name"
            placeholder="Roles name"
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <span className="text-red-500">{errors?.name}</span>
        </div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900 mt-4"
        >
          Description *
        </label>
        <div className="mt-2">
          <input
            onChange={(e) => onChangeDescription(e.target.value)}
            value={des}
            id="des"
            name="des"
            placeholder="Roles description here"
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <span className="text-red-500">{errors?.des}</span>
        </div>
        <div className="mt-4 rounded-sm h-[50vh] overflow-y-auto overflow-x-hidden border-[1px] border-gray-300 bg-white p-4">
          {listRules?.length > 0 &&
            listRules.map((i, idx) => {
              return (
                <div className="flex gap-4 my-2" key={i.id}>
                  <input
                    onChange={(e) => onCheck(e.target.checked, idx)}
                    disabled={!i.grantable}
                    checked={!!i.checked}
                    type="checkbox"
                    className="w-5 h-5 rounded-md cursor-pointer disabled:opacity-40 disabled:cursor-not-allowed disabled:bg-gray-400/30"
                  />
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    {i.label}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
      <Modal
        show={showModal}
        maskClassName="bg-opacity-50"
        zIndex="z-[9999]"
        content={({ setIsShow }) => (
          <div>
            <p className="text-center text-xl">
              Do you want to remove this Roles & Access ?
            </p>
            <div className="flex gap-4 items-center mt-5">
              <Button
                variant="text"
                className="flex-1"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="flex-1 bg-red-500 hover:bg-red-400"
                onClick={handleDelete}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      />
    </section>
  );
};

export default RolesAccessDetail;
