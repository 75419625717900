import { Vector3 } from "three";
import ReactUI from "./reactui";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setColor } from "../helper/threeHeper";
import CanvasBox from "../components/3d-scene/CanvasBox";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../reduxs/home/action";
import { REACTUI_PAGES } from "../constants/options";
import sessionApi from "../apis/api/session";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { actSetSessionId } from "../reduxs/user/action";

const RefCanvasBox = React.forwardRef((props, ref) => (
  <CanvasBox ref={ref} {...props} />
));
RefCanvasBox.displayName = "RefCanvasBox";

export const orbitConfigTemplate = {
  rotateSpeed: 3,
  zoomSpeed: 2,
  autoRotate: false,
  autoRotateSpeed: 3,
  dampingFactor: 0.05,
  enableDamping: true,
  minAzimuthAngle: -5,
  maxAzimuthAngle: 5,
  maxDistance: 2000,
  minDistance: 2,
  maxPolarAngle: Math.PI / 2,
  minPolarAngle: 0,
  maxZoom: 8,
  minZoom: 0.2,
};

const Index = (props) => {
  const [orbitConfig, setOrbitConfig] = useState({ ...orbitConfigTemplate });
  const { authMiddleware } = props;
  const [setIsIntroduction] = useState(false);
  const controls = useRef();
  const refScene = useRef();
  const navigate = useNavigate();
  const activeObjectIds = useRef([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [isShowSelectAgent, setIsShowSelectAgent] = React.useState(false);
  const user = useSelector((state) => state.user?.data);
  const isSalesAgentRole = user?.isAgent;

  useEffect(() => {
    authMiddleware(navigate);
  }, []);

  async function setActiveObjectIds(ids) {
    let prevIds = [];
    for (const index in activeObjectIds.current) {
      if (!ids.includes(activeObjectIds.current[index])) {
        prevIds.push(activeObjectIds.current[index]);
      }
    }
    activeObjectIds.current = ids;
    await handleResetObjectColor(prevIds);
    handleSetActiveObjectColor(ids);
  }

  function resetActiveColorModel(model) {
  }

  const handleResetObjectColor = async (prevIds = []) => {
    refScene.current &&
      (prevIds || [])?.forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          setColor(
            objectSelector.userData.color,
            objectSelector.userData.active_alpha,
            objectSelector,
            objectSelector.userData.emissive,
          );
          objectSelector.userData.isActive = false;
        }
      });
  };

  const handleSetActiveObjectColor = (ids) => {
    refScene.current &&
    (ids || [])?.forEach((item) => {
      let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          setColor(
            objectSelector.userData.active_color,
            objectSelector.userData.active_alpha,
            objectSelector,
            objectSelector.userData.active_emissive,
          );
          objectSelector.userData.isActive = true;
        }
      });
  };
  const [isShowProfile, setShowProfile] = useState(false);

  const onStartNewSession = async (data, onSuccess) => {
    setLoading(true);
    const res = await sessionApi.createSession({
      // customer: customerId,
      ...(isSalesAgentRole && {agent: user?.id}),
    });

    if (res?.data) {
      setLoading(false);
      // onSuccessSelectProfile();
      onSuccess && onSuccess();
      toast.success("Session created successfully");
      setIsShowSelectAgent(false);
      dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE));
      navigate({
        search: `?customer=undefined&session=${res?.data?.id}`,
      });
      dispatch(actSetSessionId(res.data.id))
    } else {
      toast.error("Session created failed");
      setLoading(false);
    }
  };

  const onStartSession = () => {
    // if (!isPresentation) {
      // socket.emitUIActionEvent(ACTION_NAME.START_SESSION, { userId });
    // } else {
    //   navigate({
    //     search: `?customer=${userId}`,
    //   });
    // }
    // if (isSalesAgentRole) {
    //   onStartNewSession({
    //     agentId: user?.id,
    //   })
    // } else {
      setIsShowSelectAgent(true);
    // }
  }

  const onSelectProfile = () => {
    dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
    setShowProfile(true);
  };

  return (
    <>
      {
        !props.isLoading &&
        <CanvasBox
          orbitConfig={orbitConfig}
          setOrbitConfig={setOrbitConfig}
          orbitConfigTemplate={orbitConfigTemplate}
          isPresentation={false}
          ref={refScene}
          controls={controls}
          objects={props.meshes}
          hotspots={props.hotspots}
          activeObjectIds={activeObjectIds.current}
          setActiveObjectIds={setActiveObjectIds}
          _3dSetting={props.settings}
          fbxs={props.fbxs}
          pagesSettings={props.pagesSettings}
          isIntroduction={true}
          targetPosition={controls.current?.getCurrentTarget() ?? new Vector3()}
          onStartSession={onStartSession}
          onSelectProfile={onSelectProfile}
          isShowSelectAgent={isShowSelectAgent}
          setIsShowSelectAgent={setIsShowSelectAgent}
          onStartNewSession={onStartNewSession}
        />
      }
      <ReactUI
        setOrbitConfig={setOrbitConfig}
        orbitConfigTemplate={orbitConfigTemplate}
        isPresentation={false}
        setIsIntroduction={setIsIntroduction}
        controls={controls}
        refScene={refScene}
        setActiveObjectIds={setActiveObjectIds}
        resetActiveColorModel={resetActiveColorModel}
        activeObjectIds={activeObjectIds.current}
        isShowProfile={isShowProfile}
        setShowProfile={setShowProfile}
        onSelectProfile={onSelectProfile}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { scene } = state;
  return {
    settings: scene.settings,
    meshes: scene.meshes,
    hotspots: scene.hotspots,
    fbxs: scene.fbxs,
    isLoading: scene.isLoading,
    pagesSettings: scene.pagesSettings,
  };
};

export default connect(mapStateToProps)(Index);
