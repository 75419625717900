import React from "react";
import Loading from "../../configuration/components/loading";
import { cn } from "../../../../helper/utils";

const Button = ({
  variant = "default",
  size = "small",
  children,
  isLoading = false,
  icon,
  ...rest
}) => {
  const buttonSize = {
    small: "py-2 px-4",
    medium: "px-10 py-3.5",
    large: "px-20 py-4.5",
  };

  const buttonVariant = {
    primary: "bg-black/80 text-white",
    secondary: "bg-primary text-white",
    text: "bg-white border-[1px] text-black",
    default: "bg-black/80 text-white hover:bg-black/80 hover:text-white",
    icon: "bg-transparent text-black",
    underline: "bg-transparent text-black underline",
  };

  const baseButtonCls =
    "text-sm rounded-md inline-flex gap-2 items-center justify-center py-2.5 hover:opacity-80 disabled:opacity-50 disabled:cursor-not-allowed";

  return (
    <React.Fragment>
      <button
        {...rest}
        className={cn(
          "font-[400]",
          baseButtonCls,
          buttonVariant[variant],
          buttonSize[size],
          rest.className
        )}
        disabled={rest.disabled || isLoading}
      >
        {icon && icon}
        {isLoading && <Loading />}
        {children}
      </button>
    </React.Fragment>
  );
};

export default Button;
