import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";
import socket from "../../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";

const ListUnits = (props) => {
  const { handleUnitClick, isPresentation } = props;

  const refs = useRef({});
  const pageRef = useRef(null);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const filteredUnits = useSelector((state) => state.unitExplore.filteredUnits);
  const unitGroup = groupBy(filteredUnits || [], "level");

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <ul
      ref={pageRef}
      onScroll={onScroll}
      className="no-scrollbar flex flex-col gap-[30px] pt-[20px] pb-[30px] pl-0 rounded-none overflow-y-scroll h-[calc(95%_-_30px)] disable-scroll-bar border-b-[1px] border-b-brand-secondary"
    >
      {unitGroup &&
        (Object.keys(unitGroup) || []).map((valueKey, key) => {
          const items = unitGroup[valueKey];
          return (
            <li
              className={
                "text-primary text-sm font-sans font-normal leading-[200%] p-0 opacity-100"
              }
              key={key}
            >
              <div>
                <p
                  className={
                    "text-base uppercase mb-[15px] tracking-[1.4px] cursor-default"
                  }
                >
                  {`Floor ${valueKey}`}
                </p>
                <ul className="pl-3">
                  {(items || []).map((item, key2) => {
                    return (
                      <li
                        className={`cursor-pointer ${
                          selectedUnit?.id && selectedUnit?.id != item.id
                            ? "opacity-50"
                            : ""
                        }`}
                        key={"item-" + key2}
                        onClick={() => handleUnitClick(item)}
                        ref={(ref) => (refs.current[item.id] = ref)}
                      >
                        {`- `}
                        {item.name.includes("Penthouse") ||
                        item.name.includes("Bayhouse")
                          ? item.name
                          : `${item.name}`}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export default ListUnits;
