import React from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import StarButton from "../../unit-explore/components/star-button/StarButton";
import useQuery from "../../../hooks/useQuery";
import preferenceApi from "../../../apis/api/preference";
import { reqGetSession } from "../../../reduxs/precinct-explore/action";
import { toast } from "react-toastify";

const ToggleFavorite = ({ session, amenityId, customer }) => {
  const dispatch = useDispatch();
  const { query } = useQuery();
  const [starred, setStarred] = React.useState(false);

  const isFavorite = React.useMemo(() => {
    return session?.preference?.amenities?.some((v) => v?.id === amenityId);
  }, [session, amenityId]);

  const prepareToggleData = (shouldAdd) => {
    return _.omitBy(
      {
        amenity: shouldAdd ? amenityId : null,
        removeAmenity: !shouldAdd ? amenityId : null,
      },
      _.isNil
    );
  };

  const handleToggleFavorite = async () => {
    const shouldAdd = !isFavorite;
    const data = prepareToggleData(shouldAdd);

    setStarred(shouldAdd);

    const res1 =
      session?.preference?.id &&
      (await preferenceApi.toggleFavorite(session.preference.id, data));

    const res2 =
      customer?.favorite?.id &&
      (await preferenceApi.toggleCustomerFavorite(customer.favorite.id, data));

    if (res1?.data || res2?.data) {
      dispatch(reqGetSession(query?.session));
    } else {
      setStarred((prev) => !prev);
      toast.error("Toggle favorite failed");
    }
  };

  return (
    <div
      className="h-[45px] w-[45px] bg-black flex items-center justify-center rounded-full"
      onClick={handleToggleFavorite}
    >
      <StarButton
        selected={isFavorite}
        starred={starred}
        setStarred={setStarred}
        wrapperClassName="static"
      />
    </div>
  );
};

export default ToggleFavorite;
