/** @format */

export const getS3FEMediaUrl = (path) => {
  if (!path) return "";

  return encodeURI(
    `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/fe${path}`
  );
};

export const getS3BEMediaUrl = (path) => {
  if (!path) return "";

  return encodeURI(
    `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/be${path}`
  );
};

export const base64ToFile = (base64String) => {
  const arr = base64String.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const extensions = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/bmp": "bmp",
    "image/webp": "webp",
  };
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `envi-${Date.now()}.${extensions[mime]}`, {
    type: mime,
  });
};
