import React, { useImperativeHandle, useMemo, useRef } from "react";
import _ from "lodash";

import sessionApi from "../../../../apis/api/session";
import { AutocompleteField } from "../../../../pages/cms/components/commons";
import dayjs from "dayjs";

const ContinueSession = ({ customerId, form, setCurrentSession }, ref) => {
  const { control } = form;
  const hasFetchedRef = useRef(false);

  const loadSession = async (inputValue) => {
    const response = await sessionApi.getSessionList(
      _.omitBy(
        {
          "name[regex]": inputValue ? decodeURIComponent(inputValue) : null,
          unknown: 1,
          "customer[equalId]": customerId,
          "isEnded[equal]": false,
        },
        _.isNil
      )
    );

    const data = response?.data;

    if (data) {
      const unknownSessions = data.filter(
        (session) => session?.customer?.id !== customerId
      );
      const currentSessions = data.filter(
        (session) => session?.customer?.id === customerId
      );

      hasFetchedRef.current === false &&
        setCurrentSession &&
        setCurrentSession(currentSessions[0]);
      hasFetchedRef.current = true;

      const currentOptions = {
        label: (
          <div className="font-bold text-green-600">
            customer session
          </div>
        ),
        options: currentSessions
          .map((session) => ({
            label: session.name,
            value: session.id,
            time: dayjs(session?.updatedAt).format("HH:mm:ss DD/MM/YYYY"),
            originalUpdatedAt: session?.updatedAt,
          }))
          .sort(
            (a, b) =>
              dayjs(b.originalUpdatedAt).valueOf() -
              dayjs(a.originalUpdatedAt).valueOf()
          ),
      };

      const unKnownOptions = {
        label: (
          <div className="font-bold text-green-600">
            anonymous session
          </div>
        ),
        options: unknownSessions.map((session) => ({
          label: session.name,
          value: session.id,
        })),
      };

      return [{ ...currentOptions }, { ...unKnownOptions }];
    }
    return [];
  };

  // Expose methods to parent through ref
  useImperativeHandle(ref, () => ({
    loadSession,
    getData: () => currentSession,
  }));

  const options = useMemo(
    () =>
      _.debounce((inputValue, callback) => {
        loadSession(inputValue, callback)
          .then((options) => callback(options))
          .catch(() => callback([]));
      }, 500), // Delay 500ms
    [],
    [customerId]
  );

  return (
    <div>
      <div className="flex gap-2">
        <div className="flex-1">
          <AutocompleteField
            controller={{
              control,
              name: "session",
            }}
            placeholder="Search session"
            autocompleteProps={{
              loadOptions: options,
              isClearable: true,
              maxMenuHeight: 300,
              cacheOptions: true,
              defaultOptions: true,
            }}
            label="Session"
          />
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(ContinueSession);
