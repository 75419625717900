/** @format */

import _ from "lodash";
import { cn } from "../../helper/utils";
import { useSelector } from "react-redux";
import jwtAuth from "../../apis/utils/jwtAuth";
import { doesUserGroupsHaveRoute } from "./utils";
import { cmsOptions } from "../../routers/options";
import { getS3BEMediaUrl } from "../../helper/media";
import React, { useEffect, useMemo, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

const TopNavCMS = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [nav, setNav] = useState(<></>);
  const authUser = useSelector((state) => state.user.data);
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const logo = activeConfig?.theme?.cssVariables?.find(
    (variable) => variable.name === "logo-cms"
  )?.value;

  const userAllowedCmsOptions = useMemo(() => {
    return cmsOptions.filter((route) => {
      if (
        !!route.nav &&
        doesUserGroupsHaveRoute(authUser?.userGroups || [], route.key)
      ) {
        return true;
      }
    });
  }, [authUser]);

  useEffect(() => {
    if (String(location?.pathname).replace(/\//g, "") !== "cms") {
      const matchedCmsOption = userAllowedCmsOptions.find((option) => {
        return (
          String(`/cms/${option.path}`).replace(/\//g, "") ===
          String(location?.pathname).replace(/\//g, "")
        );
      });
      if (matchedCmsOption?.key) {
        setNav(
          <div className="flex gap-3 items-center">
            {matchedCmsOption?.ic && <matchedCmsOption.ic className="size-3" />}
            {_.startCase(_.camelCase(matchedCmsOption.key))}
          </div>
        );
      }
    }
  }, [location?.pathname, userAllowedCmsOptions]);

  const getActiveClass = (path) => {
    return String(path).replace(/\//g, "") ===
      String(location?.pathname).replace(/\//g, "")
      ? "opacity-100 underline"
      : "";
  };

  const logout = () => {
    jwtAuth.removeToken();
    navigate("/holding");
  };

  return (
    <div className="sticky top-0 z-[9998] bg-[#000] text-[#fff] min-h-[80px] p-[13px_57px_12px_58px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <a
            className="w-12 h-12 perspective-[1000px] transition-[top] duration-200 relative cursor-grab active:transition-none"
            href="/"
          >
            {logo && <img src={getS3BEMediaUrl(logo)} alt="icon-cube" />}
          </a>
          <hr className="h-6 w-[1px] bg-white" />
          <Link
            className="ml-1 w-fit perspective-[1000px] transition-[top] duration-200 relative cursor-grab active:transition-none font-bold font-poppins text-sm"
            to="/cms"
          >
            CMS
          </Link>
        </div>
        {authUser && (
          <div className="flex justify-between items-center gap-6">
            {String(location?.pathname).replace(/\//g, "") !== "cms" && (
              <Menu as="div" className="relative inline-block text-left">
                <MenuButton className="inline-flex w-56 items-center justify-between gap-x-1.5 rounded-2xl px-4 py-2 text-sm font-semibold text-white/90 font-poppins uppercase shadow-sm ring-1 ring-inset ring-gray-300 bg-white/10 hover:bg-white/15">
                  {nav}
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 size-4"
                  />
                </MenuButton>
                <MenuItems
                  transition
                  className={`
                    absolute right-0 py-1 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-xl bg-white shadow-lg ring-1 ring-black/5
                    transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100
                    data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border border-gray-200 max-h-[500px] overflow-hidden overflow-y-auto
                  `}
                >
                  {userAllowedCmsOptions.map((cmsRoute) => {
                    return (
                      <MenuItem key={cmsRoute.key}>
                        {({ close }) => (
                          <div
                            className={cn(
                              "",
                              getActiveClass(`/cms/${cmsRoute.path}`)
                            )}
                          >
                            <Link
                              to={`/cms/${cmsRoute.path}`}
                              onClick={close}
                              className="text-decoration-none block px-4 py-2 text-sm text-gray-700 hover:text-white hover:bg-black/90 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                            >
                              <div className="font-poppins text-xs font-bold uppercase flex gap-3">
                                {cmsRoute?.ic && (
                                  <cmsRoute.ic className="size-4" />
                                )}
                                {_.startCase(_.camelCase(cmsRoute.key))}
                              </div>
                            </Link>
                          </div>
                        )}
                      </MenuItem>
                    );
                  })}
                </MenuItems>
              </Menu>
            )}
            <div
              className="text-white font-poppins text-sm font-bold uppercase cursor-pointer"
              onClick={logout}
            >
              LOG OUT
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TopNavCMS;
