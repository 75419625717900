/** @format */

export default {
  ROOT: "/",
  LOGIN_SCREEN: "/sign-in",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD_PAGE: "/dashboard",
  NOTFOUND_PAGE: "/404",
  FORBIDDEN_PAGE: "/403",
  FORBIDDEN_CMS_PAGE: "/403cms",
  HOLDING_PAGE: "/holding",
  CMS_PAGE: "/cms",
  CUSTOMER_SESSION_PAGE: "/post-engagement/:customerId",
  PANORAMA_PAGE: "/panorama",
  PRESENTATION_PAGE: "/presentation",
  BUILDER: "/builder",
  COMPARE_PAGE: "/compare",
};
