/** @format */

import { ReactComponent as Close } from "../../../assets/images/compare/icCloseV2.svg";
import { formatMoney } from "../../../helper/unit";

const ItemDetail = ({ onclose, item }) => {
  return (
    <div className="w-[250px] p-5 border bg-white rounded">
      <div className="flex flex-row justify-between items-center mb-2">
        <h2>Detail</h2>
        <div className="cursor-pointer" onClick={onclose}>
          <Close />
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="line flex flex-row justify-between items-center">
          <div className="flex flex-row gap-3 justify-center items-center">
            <div className="dot w-2 h-2 rounded bg-black" />
            <span>Bedrooms</span>
          </div>
          <span>{item?.bedrooms}</span>
        </div>
        <div className="line flex flex-row justify-between items-center">
          <div className="flex flex-row gap-3 justify-center items-center">
            <div className="dot w-2 h-2 rounded bg-black" />
            <span>Bathrooms</span>
          </div>
          <span>{item?.bathrooms}</span>
        </div>
        <div className="line flex flex-row justify-between items-center">
          <div className="flex flex-row gap-3 justify-center items-center">
            <div className="dot w-2 h-2 rounded bg-black" />
            <span>Private Pool</span>
          </div>
          <span>{item?.hasPool ? "Yes" : "No"}</span>
        </div>
        <div className="line flex flex-row justify-between items-center">
          <div className="flex flex-row gap-3 justify-center items-center">
            <div className="dot w-2 h-2 rounded bg-black" />
            <span>Prices</span>
          </div>
          <span>{formatMoney(item?.price)}</span>
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
