/** @format */

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cn } from "../../../../../helper/utils";
import { reqGetListUnits } from "../../../../../reduxs/cms/action";
import Search from "../../../gallery/Search";
import useDebouncedSearch from "../../../../../hooks/useDebouncedSearch";
import { MEDIA_TYPE } from "../../../utils";
import icFilter from "../../../../../assets/images/cms/filter.png";
import { Modal } from "../../../components/commons";
import { ReactComponent as Close } from "../../../../../assets/images/compare/icCloseV2.svg";

const cssActive = "bg-black hover:bg-black/80 text-white font-semibold";
const cssBtn =
  "w-full text-black flex-wrap flex justify-between items-center p-3 text-sm font-medium text-left border bg-white hover:bg-gray-100 rounded";

function compareNumbers(a, b) {
  return a - b;
}

const Sidebar = ({ unitActive, setUnitActive, typeActive, setActiveType }) => {
  const dispatch = useDispatch();
  const [search, setSearch, loading] = useDebouncedSearch();
  const { units } = useSelector((state) => state.cms);
  const [valueLevel, setValueLevel] = useState(null);
  const [valueUnitType, setValueUnitType] = useState(null);
  const [valueBathrooms, setValueBathrooms] = useState(null);
  const [valueBedrooms, setValueBedrooms] = useState(null);
  // const unitGroup = groupBy(units || [], "level");
  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        sortBy: JSON.stringify({
          name: 1,
        }),
      })
    );
  };

  useEffect(() => {
    getListProperties();
  }, []);

  const unitData = useMemo(() => {
    let result = [...units];
    if (valueLevel) {
      result = result?.filter((p) => p?.level === valueLevel) || [];
    }
    if (valueUnitType) {
      result = result?.filter((p) => p?.unitType === valueUnitType) || [];
    }
    if (valueBathrooms) {
      result = result?.filter((p) => p?.bathrooms === valueBathrooms) || [];
    }
    if (valueBedrooms) {
      result = result?.filter((p) => p?.bedrooms === valueBedrooms) || [];
    }
    if (search) {
      return (
        result?.filter((p) =>
          p?.name?.toLowerCase()?.includes(search?.toLowerCase())
        ) || []
      );
    } else {
      return result;
    }
  }, [units, search, valueLevel, valueUnitType, valueBathrooms, valueBedrooms]);

  const handleSetUnitActive = (item) => {
    setActiveType(MEDIA_TYPE.IMAGE);
    setUnitActive(item);
  };

  const renderFilterOption = () => {
    let arrLevel = [];
    let arrUnitType = [];
    let arrBathrooms = [];
    let arrBedrooms = [];
    for (const i of units) {
      arrLevel.push(i?.level);
      arrUnitType.push(i?.unitType);
      arrBathrooms.push(i?.bathrooms);
      arrBedrooms.push(i?.bedrooms);
    }
    arrLevel = [...new Set(arrLevel)].sort(compareNumbers);
    arrUnitType = [...new Set(arrUnitType)].sort();
    arrBathrooms = [...new Set(arrBathrooms)].sort(compareNumbers);
    arrBedrooms = [...new Set(arrBedrooms)].sort(compareNumbers);
    return (
      <div className="space-y-5">
        {arrLevel.length > 0 && (
          <>
            <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
            <div className="flex flex-wrap gap-4">
              {arrLevel?.map((i) => {
                return (
                  <div className="flex gap-3" key={i}>
                    <div className="flex h-6 shrink-0 items-center">
                      <div className="group grid size-4 grid-cols-1">
                        <input
                          checked={i === valueLevel}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValueLevel(i);
                            } else {
                              setValueLevel(null);
                            }
                          }}
                          id={`level-${i}`}
                          name="comments"
                          type="checkbox"
                          aria-describedby="comments-description"
                          className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                        />
                        <svg
                          fill="none"
                          viewBox="0 0 14 14"
                          className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                        >
                          <path
                            d="M3 8L6 11L11 3.5"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:checked]:opacity-100"
                          />
                          <path
                            d="M3 7H11"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:indeterminate]:opacity-100"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="text-sm/6">
                      <label
                        htmlFor={`level-${i}`}
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {`Level ${i}`}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {arrUnitType.length > 0 && (
          <>
            <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
            <div className="flex flex-wrap gap-4">
              {arrUnitType?.map((i) => {
                return (
                  <div className="flex gap-3" key={i}>
                    <div className="flex h-6 shrink-0 items-center">
                      <div className="group grid size-4 grid-cols-1">
                        <input
                          checked={i === valueUnitType}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValueUnitType(i);
                            } else {
                              setValueUnitType(null);
                            }
                          }}
                          id={`unit-type-${i}`}
                          name="comments"
                          type="checkbox"
                          aria-describedby="comments-description"
                          className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                        />
                        <svg
                          fill="none"
                          viewBox="0 0 14 14"
                          className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                        >
                          <path
                            d="M3 8L6 11L11 3.5"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:checked]:opacity-100"
                          />
                          <path
                            d="M3 7H11"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:indeterminate]:opacity-100"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="text-sm/6">
                      <label
                        htmlFor={`unit-type-${i}`}
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {`Unit Type ${i}`}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {arrBathrooms.length > 0 && (
          <>
            <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
            <div className="flex flex-wrap gap-4">
              {arrBathrooms?.map((i) => {
                return (
                  <div className="flex gap-3" key={i}>
                    <div className="flex h-6 shrink-0 items-center">
                      <div className="group grid size-4 grid-cols-1">
                        <input
                          checked={i === valueBathrooms}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValueBathrooms(i);
                            } else {
                              setValueBathrooms(null);
                            }
                          }}
                          id={`bathrooms-${i}`}
                          name="comments"
                          type="checkbox"
                          aria-describedby="comments-description"
                          className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                        />
                        <svg
                          fill="none"
                          viewBox="0 0 14 14"
                          className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                        >
                          <path
                            d="M3 8L6 11L11 3.5"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:checked]:opacity-100"
                          />
                          <path
                            d="M3 7H11"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:indeterminate]:opacity-100"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="text-sm/6">
                      <label
                        htmlFor={`bathrooms-${i}`}
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {`Bathrooms ${i}`}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {arrBedrooms.length > 0 && (
          <>
            <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
            <div className="flex flex-wrap gap-4">
              {arrBedrooms?.map((i) => {
                return (
                  <div className="flex gap-3" key={i}>
                    <div className="flex h-6 shrink-0 items-center">
                      <div className="group grid size-4 grid-cols-1">
                        <input
                          checked={i === valueBedrooms}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setValueBedrooms(i);
                            } else {
                              setValueBedrooms(null);
                            }
                          }}
                          id={`bedrooms-${i}`}
                          name="comments"
                          type="checkbox"
                          aria-describedby="comments-description"
                          className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                        />
                        <svg
                          fill="none"
                          viewBox="0 0 14 14"
                          className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                        >
                          <path
                            d="M3 8L6 11L11 3.5"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:checked]:opacity-100"
                          />
                          <path
                            d="M3 7H11"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="opacity-0 group-has-[:indeterminate]:opacity-100"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="text-sm/6">
                      <label
                        htmlFor={`bedrooms-${i}`}
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {`Bedrooms ${i}`}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-72 text-white rounded-lg bg-white">
      <div className="mt-4">
        <div className="flex flex-row justify-between items-center px-4 relative">
          <Search
            wrapperClassName={"w-fix"}
            callbackOnInputChange={setSearch}
            formClassName="w-[230px]"
          />

          <Modal
            maskClassName="bg-opacity-50"
            zIndex="z-[9999]"
            triggerClassName="h-auto"
            trigger={
              <img className="w-4 cursor-pointer" src={icFilter} alt="" />
            }
            content={({ setIsShow }) => (
              <div>
                <div
                  className="cursor-pointer w-fit ml-auto "
                  onClick={() => setIsShow(false)}
                >
                  <Close />
                </div>
                <span>Filter</span>
                {renderFilterOption()}
              </div>
            )}
          />
          {/* {isShowOptionFilter && (
            <div className="absolute rounded-sm w-[40vw] h-[400px] bg-white top-[40px] -right-[300px] border p-4 overflow-y-auto z-50">
              <div
                className="cursor-pointer w-fit ml-auto "
                onClick={() => showOptionFilter(false)}
              >
                <Close />
              </div>
            </div>
          )} */}
        </div>
        <nav className="flex-1 px-4 py-2 overflow-y-auto pb-4 h-[calc(100vh-170px-80px)]">
          <div className="space-y-2">
            {loading ? (
              <div className="text-black w-full text-center">. . .</div>
            ) : (
              unitData?.map((item) => {
                return (
                  <div key={`unit-${item.id}`}>
                    <button
                      className={cn(
                        `${cssBtn}`,
                        item?.id === unitActive?.id && `${cssActive}`
                      )}
                      onClick={() => handleSetUnitActive(item)}
                    >
                      <div className="flex flex-wrap flex-1">
                        <span>{item?.name}</span>
                      </div>
                    </button>
                    {item?.id === unitActive?.id && (
                      <div className="w-[80%] ml-auto mt-2 flex gap-2 flex-col">
                        <button
                          className={cn(
                            `${cssBtn}`,
                            typeActive === MEDIA_TYPE.IMAGE && `${cssActive}`
                          )}
                          onClick={() => setActiveType(MEDIA_TYPE.IMAGE)}
                        >
                          Gallery
                        </button>
                        <button
                          className={cn(
                            `${cssBtn}`,
                            typeActive === MEDIA_TYPE.FLOORPLANS &&
                              `${cssActive}`
                          )}
                          onClick={() => setActiveType(MEDIA_TYPE.FLOORPLANS)}
                        >
                          FloorPlan
                        </button>
                        <button
                          className={cn(
                            `${cssBtn}`,
                            typeActive === MEDIA_TYPE["360IMAGE"] &&
                              `${cssActive}`
                          )}
                          onClick={() => setActiveType(MEDIA_TYPE["360IMAGE"])}
                        >
                          360
                        </button>
                        <button
                          className={cn(
                            `${cssBtn}`,
                            typeActive === MEDIA_TYPE.VIEWLINES &&
                              `${cssActive}`
                          )}
                          onClick={() => setActiveType(MEDIA_TYPE.VIEWLINES)}
                        >
                          ViewLines
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
