import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Setting from "./Setting";

import {
  reqGetVirtualTour,
  reqGetVirtualTours,
} from "../../../reduxs/virtual-tour/action";
import Header from "./Header";
import { SpinLoading } from "../../../components/svgs/icons";
import useQuery from "../../../hooks/useQuery";

export const initialData = {
  scenes: [],
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: true,
    fullscreenButton: true,
    viewControlButtons: true,
  },
};

const VirtualTour = () => {
  const dispatch = useDispatch();
  const virtualToursRes = useSelector((state) => state.virtualTour.list);

  const [loading, setLoading] = useState(false);
  const { query } = useQuery();
  const virtualId = query?.id;

  //get all virtual tour
  useEffect(() => {
    dispatch(reqGetVirtualTours({}, setLoading));
  }, []);

  //get virtual tour
  useEffect(() => {
    if (virtualId && virtualId !== "undefined") {
      dispatch(reqGetVirtualTour(virtualId));
    }
  }, [virtualId]);

  return (
    <div className="page-container">
      <Header virtualToursRes={virtualToursRes} />
      {loading && (
        <div className="flex justify-center items-center h-[500px]">
          <div>
            <h2 className="text-3xl font-semibold">
              <SpinLoading className="w-[50px] h-[50px]" />
            </h2>
          </div>
        </div>
      )}
      {!loading && !virtualToursRes?.length && (
        <div className="flex justify-center items-center h-[500px]">
          <div>
            <h2 className="text-3xl font-semibold">No Virtual Tour Found</h2>
            <p className="text-gray-600 text-center">
              Please create a virtual tour first
            </p>
          </div>
        </div>
      )}
      {!loading && !!virtualToursRes?.length && <Setting />}
    </div>
  );
};

export default VirtualTour;
