import * as Types from './type';
import authApi from '../../apis/api/auth';
import customerApi from '../../apis/api/customer';
import jwtAuth from '../../apis/utils/jwtAuth';

const actGetUserProfile = (data) => ({
  type: Types.GET_USER_PROFILE,
  data,
});

const actGetCustomerProfile = (data) => ({
  type: Types.GET_CUSTOMER_PROFILE,
  data,
});

export const actSetSessionId = (data) => ({
  type: Types.SET_SESSION_ID,
  data,
});

export const actRemoveSessionId = () => ({
  type: Types.REMOVE_SESSION_ID,
});

export const reqGetUserProfile = () => async (dispatch) => {
  const isToken = await jwtAuth.checkToken();
  if (isToken) {
    return authApi
      .getMe()
      .then((data) => {
        dispatch(actGetUserProfile(data));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const reqGetCustomerProfile = (id) => (dispatch) => {
  if (id) {
    return customerApi
      .getCustomerDetail(id)
      .then((data) => {
        dispatch(actGetCustomerProfile(data));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const reqSetCustomerProfile = (data) => (dispatch) => {
  return dispatch(actGetCustomerProfile(data));
};
