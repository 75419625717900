export const IS_INTRODUCTION = 'IS_INTRODUCTION';
export const IS_DRAGGING3D = 'IS_DRAGGING3D';
export const RECEIVE_3D_SETTINGS = 'RECEIVE_3D_SETTINGS';
export const RECEIVE_ASSETS_LIST = 'RECEIVE_ASSETS_LIST';
export const MESH_LIST = "MESH_LIST";
export const RECEIVE_HOTSPOTS_LIST = 'RECEIVE_HOTSPOTS_LIST';
export const RECEIVE_FBX_FILES = 'RECEIVE_FBX_FILES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_LOADING_MODEL = 'SET_IS_LOADING_MODEL';
export const RECEIVE_HOTSPOT_TEXTURES = 'RECEIVE_HOTSPOT_TEXTURES';
export const RECEIVE_PAGES_SETTINGS = 'RECEIVE_PAGES_SETTINGS';
export const RECEIVE_GLTF_MODELS = 'RECEIVE_GLTF_MODELS';
export const RECEIVE_GLTF_CAMERA_HELPERS = 'RECEIVE_GLTF_CAMERA_HELPERS';
export const RECEIVE_ENV_MAP = 'RECEIVE_ENV_MAP';
export const AUTO_ROTATE_3D = 'AUTO_ROTATE_3D';
