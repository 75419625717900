import React from "react";

const OpenIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.99983H23M4.19355 0.677246H19.8065M4.19355 11.3224H19.8065"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OpenIcon;
