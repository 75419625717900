import React, { Fragment } from "react";
import mediaAPI from "../../../../../apis/api/media";
import { toast } from "react-toastify";
import { Button, Modal } from "../../../components/commons";
import { cn } from "../../../../../helper/utils";
import Asset from "../Asset";

const UploadImage = ({ onUpload, virtualTourRes }) => {
  const [loading, setLoading] = React.useState(false);

  const handleFileChange = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const type = file?.type?.split("/")[1];

      const imageUrl = URL.createObjectURL(file);

      // Create an Image object to check the dimensions
      const img = new Image();
      img.src = imageUrl;

      img.onload = async () => {
        const { width, height } = img;

        // Check for a 2:1 ratio
        if (width / height === 2) {
          const uploaded = await mediaAPI.uploadMedia(file);

          if (uploaded?.message) {
            toast.error(uploaded.message);
          }

          uploaded?.data && onUpload(uploaded?.data?.path); // Proceed with upload if it's equirectangular
          setLoading(false);
        } else {
          toast.warn(
            "Please upload an equirectangular image with a 2:1 aspect ratio."
          );
          setLoading(false);
        }
      };
    }
  };

  return (
    <Fragment>
      <div className="w-full">
        <input
          type="file"
          onChange={handleFileChange}
          accept=".png, .webp"
          id="upload-input"
          className="hidden" // Tailwind class to hide the input
        />
        <Button
          isLoading={loading}
          className={cn("w-full flex justify-center")}
        >
          <label
            htmlFor={loading ? "upload-input-disabled" : "upload-input"}
            className={cn("block w-full text-center cursor-pointer", {
              "cursor-not-allowed w-auto": loading,
            })}
          >
            {!loading && "Upload"}
          </label>
        </Button>
      </div>
      <Modal
        disabled={loading}
        maskClassName="bg-opacity-50"
        zIndex="z-[9998]"
        panelClassName="w-[800px]"
        maskClosable
        trigger={
          <Button disabled={loading} className="flex-1">
            Assets
          </Button>
        }
        content={({ setIsShow }) => {
          return (
            <Asset setIsShow={setIsShow} vTDefault={virtualTourRes?.data} />
          );
        }}
      />
    </Fragment>
  );
};

export default UploadImage;
