import React, { memo } from "react";
import { cn } from "../../../../../helper/utils";
import { getS3BEMediaUrl } from "../../../../../helper/media";
import { Checkbox } from "@headlessui/react";

const MediaItem = ({ media, selected, onSelect }) => {
  return (
    <div
      className={cn(
        "cursor-pointer shadow rounded border relative border-gray-500 h-fit",
        selected && "border-green-500 border-2"
      )}
      onClick={() => onSelect(media)}
    >
      <img
        src={getS3BEMediaUrl(media?.thumbnailPath)}
        alt={media.name}
        className="object-cover w-full h-[150px]"
      />
      <div className="flex flex-wrap items-center justify-between pb-5 px-2">
        <p className="mt-1 pr-2 break-words w-[80%] line-clamp-1">
          {media.name}
        </p>
        <Checkbox
          className="w-4 h-4 group border border-black group flex items-center justify-center rounded"
          checked={selected}
          onSelect={() => onSelect(media)}
        >
          <div className="hidden w-3 h-3 bg-green-500 group-data-[checked]:block rounded" />
        </Checkbox>
      </div>
    </div>
  );
};

export default memo(MediaItem);
