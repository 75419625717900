/** @format */

import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import MediasContent from "./MediasContent";
import useDebouncedSearch from "../../../../../../hooks/useDebouncedSearch";
import Search from "../../../../gallery/Search";

const ContentUnitType = ({ name, unitId, ...rest }, ref) => {
  const refSearch = useRef();
  const [search, setSearch, loading] = useDebouncedSearch();

  const isLoadingTable = loading;

  const medias = useMemo(() => {
    const newMedias = !search
      ? rest?.medias || []
      : (rest?.medias || [])?.filter((media) =>
          media?.name?.toLowerCase()?.includes(search?.toLowerCase())
        );

    return newMedias;
  }, [search, rest?.medias]);

  useEffect(() => {
    refSearch.current?.resetSearch();
  }, [name]);

  return (
    <section className="h-full flex flex-col">
      <div className="flex flex-row mt-1 mb-2 items-center gap-5">
        <div className="flex-1 capitalize">{name}</div>
      </div>
      <Search
        callbackOnInputChange={setSearch}
        formClassName="w-full"
        disabled={!rest?.medias?.length}
        ref={refSearch}
      />
      <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
      <div className="content overflow-y-scroll overflow-x-hidden h-[68vh]">
        <MediasContent
          medias={medias}
          isLoadingTable={isLoadingTable}
          ref={ref}
          unitId={unitId}
        />
      </div>
    </section>
  );
};

export default forwardRef(ContentUnitType);
