import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Marzipano from "marzipano";
import Info from "../../commons/Hotspot/Info";
import Link from "../../commons/Hotspot/Link";
import { addDrag } from "../../utils";
import _ from "lodash";

const View = ({ settings, currentScene, onHotspotUpdate, scenes }) => {
  const { url, infoHotspots, linkHotspots, levels, initialViewParameters } =
    currentScene;

  const filterLinkHotspots = useMemo(
    () =>
      _.filter(
        linkHotspots,
        (hotspot) =>
          hotspot?.targetScene && _.some(scenes, ["id", hotspot.targetScene])
      ),
    [linkHotspots, scenes]
  );

  const viewerRef = useRef(null);
  const sceneRef = useRef(null);

  // Initialize viewer
  useEffect(() => {
    const viewerElement = document.getElementById("panorama");
    const viewer = new Marzipano.Viewer(viewerElement, {
      controls: { mouseViewMode: "drag" },
    });

    const geometry = new Marzipano.EquirectGeometry(levels);
    const source = Marzipano.ImageUrlSource.fromString(url);
    const limiter = Marzipano.RectilinearView.limit.traditional(
      12288, // max size for the image
      (100 * Math.PI) / 180, // maximum pitch angle in radians
      (100 * Math.PI) / 180 // minimum pitch angle in radians
    );
    const view = new Marzipano.RectilinearView(initialViewParameters, limiter);

    const scene = viewer.createScene({
      source,
      geometry,
      view,
      pinFirstLevel: true,
    });
    scene.switchTo({ transitionDuration: 1000 });

    viewerRef.current = viewer;
    sceneRef.current = scene;

    return () => {
      viewer.destroy();
    };
  }, [url, settings, levels]);

  // Autorotation
  useEffect(() => {
    if (settings?.autorotateEnabled && viewerRef.current) {
      const viewerElement = document.getElementById("panorama");

      const currentPitch = viewerRef.current.view().pitch();
      const currentFov = viewerRef.current.view().fov();

      const autorotate = new Marzipano.autorotate({
        yawSpeed: -0.1,
        targetPitch: currentPitch || 0,
        targetFov: currentFov || Math.PI / 2,
      });
      viewerRef.current.startMovement(autorotate); // Start autorotation

      // Handle autorotation when mouse up
      const handleMouseUp = () => {
        if (settings?.autorotateEnabled) {
          viewerRef.current.startMovement(autorotate);
        }
      };

      viewerElement.addEventListener("mouseup", handleMouseUp);

      return () => {
        viewerElement.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [viewerRef, settings, url]);

  // Add hotspots
  useEffect(() => {
    if (!sceneRef.current) return;

    const hotspotContainer = sceneRef.current.hotspotContainer();

    // Clear existing hotspots
    hotspotContainer.listHotspots().forEach((hotspot) => {
      hotspotContainer.destroyHotspot(hotspot);
    });

    // Add info hotspots
    infoHotspots?.forEach((info, index) => {
      const { pitch, yaw, title, description } = info;

      const hotspotElement = Info({
        index,
        data: { description, title },
        sceneRef,
      });

      const hotspot = hotspotContainer.createHotspot(hotspotElement, {
        yaw,
        pitch,
      });

      addDrag(
        hotspotElement,
        hotspot,
        viewerRef.current,
        index,
        "info",
        onHotspotUpdate
      );
    });

    // Add link hotspots
    filterLinkHotspots?.forEach((link, index) => {
      const { pitch, yaw, targetSceneId } = link;

      const hotspotElement = Link({
        index,
        data: { targetSceneId },
        sceneRef,
      });

      const hotspot = hotspotContainer.createHotspot(hotspotElement, {
        yaw,
        pitch,
      });

      addDrag(
        hotspotElement,
        hotspot,
        viewerRef.current,
        index,
        "link",
        onHotspotUpdate
      );
    });
  }, [infoHotspots, filterLinkHotspots, settings]);

  return (
    <div className="w-full h-screen relative">
      <div id="panorama" className="w-full h-full absolute top-0 left-0" />
    </div>
  );
};

export default View;
