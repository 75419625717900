import * as Types from "./type";
import preferenceApi from "../../apis/api/preference";
import sessionApi from "../../apis/api/session";

const actSetIsShowPrecinctExploreDetail = (data) => ({
  type: Types.SET_IS_SHOW_PRECINCT_EXPLORE_DETAIL,
  data,
});

const actSetIsShowReplayVideo = (data) => ({
  type: Types.SET_IS_SHOW_REPLAY_VIDEO,
  data,
});

export const reqSetIsShowPrecinctExploreDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowPrecinctExploreDetail(data));
};

export const reqSetIsShowReplayVideo = (data) => (dispatch) => {
  return dispatch(actSetIsShowReplayVideo(data));
};

const actGetCustomerPreference = (data) => ({
  type: Types.GET_CUSTOMER_PREFERENCE,
  data,
});

const actGetCustomerPreferenceV2 = (data) => ({
  type: Types.GET_CUSTOMER_PREFERENCE_V2,
  data,
});

const actGetSession = (data) => ({
  type: Types.GET_SESSION,
  data,
});

export const reqGetCustomerPreference = (id) => (dispatch) => {
  return preferenceApi
    .getCustomerPreference({
      customer: id,
    })
    .then((data) => {
      dispatch(actGetCustomerPreference(data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reqGetCustomerPreferenceV2 = (id) => (dispatch) => {
  return preferenceApi
    .getCustomerPreferenceV2({
      customer: id,
    })
    .then((data) => {
      dispatch(actGetCustomerPreferenceV2(data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reqGetSessionPreference = (preferenceId) => (dispatch) => {
  return preferenceApi
    .getCustomerPreferenceBySessionId(preferenceId)
    .then((data) => {
      dispatch(actGetCustomerPreference(data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reqGetSession = (id) => (dispatch) => {
  return sessionApi
    .getSessionDetail(id)
    .then((data) => {
      dispatch(actGetSession(data));
    })
    .catch((err) => {
      console.log(err);
    });
};
