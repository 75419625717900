import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "../../../pages/cms/components/commons";
import SessionSelect from "./session-select";
import { LongRightArrowIcon } from "../../svgs/icons";
import profileImage from "../../../assets/images/profile.png";
import sessionApi from "../../../apis/api/session";
import socket from "../../../helper/socket";
import { ACTION_NAME, REACTUI_PAGES } from "../../../constants/options";
import { reqSetPage } from "../../../reduxs/home/action";
import useQuery from "../../../hooks/useQuery";

const InputProfile = (props) => {
  const {
    listUserSelect,
    customerSelected,
    setCustomerSelected,
    onStartUserSession,
    handleCancel,
    showCreate,
    isPresentation,
  } = props;

  const dispatch = useDispatch();
  const { query } = useQuery();
  const sessionId = query?.session;

  const [searchInput, setSearchInput] = useState("");

  const handleExploreNow = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE));
  };

  const selectedUser = listUserSelect.find(
    (user) => user.id === customerSelected || user?._id === customerSelected
  );

  const connectSessionToCustomer = async () => {
    const res = await sessionApi.updateSession(sessionId, {
      customer: customerSelected,
    });

    if (res?.data) {
      onStartUserSession(selectedUser, sessionId);
      toast.success("Session updated successfully");
    } else {
      toast.error("Session updated failed");
    }
  };

  const handleSubmitUser = async (session) => {
    const selectedUser = listUserSelect.find(
      (user) => user.id === customerSelected || user?._id === customerSelected
    );
    await onStartUserSession(selectedUser, session?.id);
  };

  return (
    <div
      id="select-profile-wrapper"
      className="absolute inset-0 w-full h-full bg-white text-[#242422] p-[80px_110px] flex justify-between overflow-y-scroll"
    >
      <div className="flex flex-col w-[534px] h-[846px] relative justify-between before:content-[''] before:block before:absolute before:top-0 before:left-[574px] before:h-full before:w-[1px] before:bg-[#24242226]">
        <div className="h-[307px] text-left w-full pt-[42px]">
          <h2 className="font-domaine text-[75px] !font-normal">
            SELECT
            <br />
            PROFILE
          </h2>
        </div>
        <div className="border-t border-[#24242226] flex flex-col items-start w-full pt-[20px] pb-[30px] px-0">
          <p className="font-['Suisse Intl'] text-[22px] font-normal text-black tracking-tight text-left">
            Select the customer profile for today's session
          </p>
          <input
            type="text"
            className="w-full h-[64px] border-none bg-[#f4f4f4] pl-[20px] placeholder:font-['Suisse Intl'] placeholder:text-[#242422] placeholder:text-[12px] placeholder:font-normal placeholder:tracking-wide focus-visible:outline-none"
            placeholder="SEARCH ..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <ul className="p-0 w-full mt-4 h-[250px] overflow-scroll">
            {listUserSelect
              ?.filter((user) => {
                if (searchInput == "") {
                  return user;
                } else if (
                  user?.name
                    .toLowerCase()
                    .includes(searchInput.toLocaleLowerCase())
                ) {
                  return user;
                }
              })
              .map(({ id, name, _id }) => (
                <li
                  className={`first:border-t list-none flex items-center h-[40px] pl-[20px] border-b border-[#24242212] ${
                    customerSelected === id ? "border-2 border-[#242422]" : ""
                  }`}
                  onClick={() => setCustomerSelected(id)}
                  key={`${id}`}
                >
                  {name}
                </li>
              ))}
          </ul>
          {!sessionId ? (
            <Modal
              disabled={!customerSelected}
              panelClassName="w-[600px] overflow-visible"
              zIndex="z-[9998]"
              maskClassName="bg-opacity-50"
              content={({ setIsShow }) => (
                <SessionSelect
                  selectedUser={selectedUser}
                  customerId={customerSelected}
                  setIsShow={setIsShow}
                  onSuccessSelectProfile={handleSubmitUser}
                />
              )}
              triggerClassName="block w-full h-auto"
              trigger={
                <button
                  disabled={!customerSelected}
                  className="w-full h-[60px] bg-black text-white text-[14px] font-normal mb-[25px] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  SELECT PROFILE
                </button>
              }
            />
          ) : (
            <button
              onClick={connectSessionToCustomer}
              disabled={!customerSelected}
              className="w-full h-[60px] bg-black text-white text-[14px] font-normal mb-[25px] disabled:opacity-50 disabled:cursor-not-allowed"
            >
              SELECT PROFILE
            </button>
          )}
          <div className="button-group flex justify-between w-full">
            <div>
              <button
                className="bg-transparent text-[12px] font-normal p-0 mr-[24px]"
                onClick={() => handleCancel()}
              >
                CANCEL
              </button>
              <button
                className="bg-transparent text-[12px] font-normal p-0"
                onClick={() => showCreate(true)}
              >
                CREATE A NEW PROFILE
              </button>
            </div>
            {/* <div
              className="explore-btn cursor-pointer flex items-center gap-[10px]"
              onClick={handleExploreNow}
            >
              <button
                className="bg-transparent text-[12px] font-normal p-0 mr-0"
                onClick={() => handleCancel()}
              >
                EXPLORE NOW
              </button>
              <LongRightArrowIcon fill="#242422" />
            </div> */}
          </div>
        </div>
      </div>
      <img
        className="object-cover"
        src={profileImage}
        alt="create-profile-image"
        width="534px"
        height="846px"
      />
    </div>
  );
};

export default InputProfile;
