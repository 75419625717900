import React from "react";
import { useDispatch } from "react-redux";

import { Button } from "../../../components/commons";
import virtualTourApi from "../../../../../apis/api/virtual-tour";
import useQuery from "../../../../../hooks/useQuery";
import { toast } from "react-toastify";
import { reqGetVirtualTours } from "../../../../../reduxs/virtual-tour/action";
import { useNavigate } from "react-router-dom";

const DeleteVt = ({ setIsShow }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const { query, push } = useQuery();
  const navigate = useNavigate();

  const handleDelete = async () => {
    setLoading(true);
    const res = await virtualTourApi.deleteVirtualTour(query?.id);

    if (res.data) {
      toast.success("Delete virtual tour successfully");
      await dispatch(reqGetVirtualTours());

      navigate("/cms/virtual-tours", { replace: true });
      setLoading(false);
    } else {
      toast.error("Delete virtual tour failed");
    }
    setLoading(false);
    setIsShow && setIsShow(false);
  };

  return (
    <div>
      <div>
        <p className="pb-10 text-center text-xl">
          Are you sure you want to delete this virtual tour?
        </p>
        <div className="flex gap-5">
          <Button
            variant="text"
            disabled={loading}
            className="flex-1"
            onClick={() => setIsShow(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            isLoading={loading}
            disabled={loading}
            className="bg-red-500 hover:bg-red-400 flex-1"
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteVt;
