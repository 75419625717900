import React, { useCallback, useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import UploadImage from "./Upload";
import SettingView from "./View/SettingView";
import { getS3BEMediaUrl } from "../../../../helper/media";
import playListApi from "../../../../apis/api/playlist";
import { MEDIA_TYPE } from "../../utils";
import mediaApi from "../../../../apis/api/media";
import virtualTourApi from "../../../../apis/api/virtual-tour";
import MultipleAction from "./MultipleAction";
import { reqGetVirtualTour } from "../../../../reduxs/virtual-tour/action";
import useQuery from "../../../../hooks/useQuery";
import { initialData } from "../index";
import PanoramaList from "./PanoramaList";
import HotspotList from "./HospotList";
import PreviewView from "./View/PreviewView";
import { Button, Modal } from "../../components/commons";
import { LinkIcon } from "@heroicons/react/16/solid";
import VirtualTourSelecting from "../../amenities/Table/virtualTourSelecting";
import Asset from "./Asset";
import EmptyTable from "../../configuration/components/Table/EmptyTable";
import { generateMongoLikeId } from "../utils";

const Setting = () => {
  const dispatch = useDispatch();

  const virtualTourRes = useSelector((state) => state.virtualTour.detail);

  const [sceneIndex, setSceneIndex] = useState(0);
  const [virtualTourData, setVirtualTourData] = useState(null);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (virtualTourRes) {
      setVirtualTourData(virtualTourRes?.data || {});
    }
  }, [virtualTourRes]);

  const { query } = useQuery();
  const virtualId = query?.id;

  useEffect(() => {
    setPreview(false);
    setSceneIndex(0);
  }, [virtualId]);

  const existingScenes = virtualTourData?.scenes || [];
  const nameScene = `Virtual tour ${generateMongoLikeId()?.slice(-4)}`;

  const handleImageUpload = useCallback(
    async (path) => {
      const newScene = {
        id: nameScene,
        name: nameScene,
        url: `${getS3BEMediaUrl(path)}`,
        levels: [
          { tileSize: 256, size: 256, fallbackOnly: true },
          { tileSize: 512, size: 512 },
          { tileSize: 1024, size: 1024 },
        ],
        faceSize: 4096,
        initialViewParameters: {
          pitch: 0,
          yaw: 0,
          fov: Math.PI / 3,
        },
        linkHotspots: [],
        infoHotspots: [],
      };

      if (path) {
        let params = {
          isActive: true,
          path,
          type: MEDIA_TYPE.VIRTUALTOURS,
          name: newScene?.name,
          fileName: newScene?.name,
          thumbnailPath: path,
        };
        const createMediaRes = await playListApi.createMediaPlaylist(params);

        if (!createMediaRes?.data) {
          toast.error("Add media failed!");
          return;
        }

        const updatedData = {
          name: virtualTourRes?.name,
          data: {
            scenes: [
              ...existingScenes,
              { ...newScene, mediaId: createMediaRes.data.id },
            ],
            settings: virtualTourData?.settings || initialData.settings,
          },
        };

        const addMediaToVTourRes = await virtualTourApi.updateVirtualTour(
          virtualId,
          updatedData
        );

        if (addMediaToVTourRes?.data) {
          dispatch(reqGetVirtualTour(virtualId));
          toast.success("Add new virtual tour successfully!");
        } else {
          toast.error("Add media failed!");
        }
      }
    },
    [virtualTourData, virtualTourRes, virtualId, existingScenes]
  );

  const handleUpdatePositionHotspot = (hotspotIndex, yaw, pitch, type) => {
    setVirtualTourData((prevData) => {
      const newScenes = [...prevData.scenes];
      const currentScene = { ...newScenes[sceneIndex] };

      if (type === "link") {
        const updatedLinkHotspots = currentScene.linkHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex ? { ...hotspot, yaw, pitch } : hotspot
        );
        currentScene.linkHotspots = updatedLinkHotspots;
      } else if (type === "info") {
        const updatedInfoHotspots = currentScene.infoHotspots.map(
          (hotspot, index) =>
            index === hotspotIndex ? { ...hotspot, yaw, pitch } : hotspot
        );
        currentScene.infoHotspots = updatedInfoHotspots;
      }

      newScenes[sceneIndex] = currentScene;

      return {
        ...prevData,
        scenes: newScenes,
      };
    });
  };

  const onSave = async ({ onPending }) => {
    const updatedData = {
      name: virtualTourRes?.name,
      data: virtualTourData,
    };
    onPending && onPending(true);
    const res = await virtualTourApi.updateVirtualTour(virtualId, updatedData);

    if (!res) {
      toast.error("Some scenes failed to update. Please try again.");
      onPending && onPending(false);
    } else {
      dispatch(reqGetVirtualTour(virtualId));
      onPending && onPending(false);
      toast.success("All scenes updated successfully!");
    }
  };

  const currentScene = virtualTourData?.scenes
    ? virtualTourData?.scenes[sceneIndex]
    : {};

  return (
    <>
      {!preview && (
        <div className="flex gap-4 ">
          <div className="w-64 p-4 bg-gray-50 rounded-lg overflow-y-auto h-[calc(100vh-150px)]">
            <MultipleAction
              onSave={onSave}
              setPreview={setPreview}
              virtualTourRes={virtualTourRes}
              virtualTourData={virtualTourData}
            />

            <div className="rounded border border-gray-400 p-2">
              <h3 className="font-medium mb-2">Settings</h3>
              <div className="flex justify-between mb-5 items-center">
                <div className="flex gap-5 items-center">
                  <input
                    type="checkbox"
                    id="rotate-checkbox"
                    className="rounded"
                    hidden
                    checked={
                      typeof virtualTourData?.settings?.autorotateEnabled ===
                      "undefined"
                        ? initialData.settings?.autorotateEnabled
                        : virtualTourData?.settings?.autorotateEnabled
                    }
                    onChange={(e) =>
                      setVirtualTourData((prevState) => {
                        return {
                          ...prevState,
                          settings: {
                            ...(prevState?.settings || initialData.settings),
                            autorotateEnabled: e.target.checked,
                          },
                        };
                      })
                    }
                  />
                  <label htmlFor="rotate-checkbox" className="cursor-pointer">
                    Rotate
                  </label>
                </div>
              </div>
              <div className="flex gap-2">
                <UploadImage
                  onUpload={handleImageUpload}
                  virtualTourRes={virtualTourRes}
                />
              </div>
            </div>
            {virtualTourData?.scenes && currentScene && (
              <PanoramaList
                virtualTourData={virtualTourData}
                setVirtualTourData={setVirtualTourData}
                setSceneIndex={setSceneIndex}
                sceneIndex={sceneIndex}
              />
            )}
            {virtualTourData?.scenes && currentScene && (
              <HotspotList
                sceneIndex={sceneIndex}
                setVirtualTourData={setVirtualTourData}
                virtualTourData={virtualTourData}
              />
            )}
          </div>

          <div className="flex-1">
            {!currentScene?.url && (
              <div className="flex justify-center items-center h-[calc(100vh-150px)]">
                <h2 className="text-3xl font-semibold">
                  <EmptyTable>
                    <h2 className="text-pretty text-xl font-semibold tracking-tight text-gray-900 sm:text-xl lg:text-balance text-center">
                      No Virtual Tour
                    </h2>
                    <p className="text-sm text-center text-gray-500">
                      Please add a virtual tour to start editing
                    </p>
                  </EmptyTable>
                </h2>
              </div>
            )}
            {currentScene?.url && (
              <SettingView
                scenes={virtualTourData?.scenes}
                currentScene={currentScene}
                onHotspotUpdate={handleUpdatePositionHotspot}
                settings={virtualTourData?.settings}
              />
            )}
          </div>
        </div>
      )}
      {preview && (
        <PreviewView setPreview={setPreview} data={virtualTourData} />
      )}
    </>
  );
};

export default Setting;
