/** @format */

import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../assets/images/close-white.svg";
import { Modal, Button } from "../components/commons";
import { Select } from "@headlessui/react";
import { cn } from "../../../helper/utils";
import { ReactComponent as ArrowRight } from "../../../assets/images/arrow-right.svg";
import TrashIcon from "../configuration/components/svgs/TrashIcon";
import Datepicker from "react-tailwindcss-datepicker";
import { useSelector } from "react-redux";
import sessionApi from "../../../apis/api/session";
import dayjs from "dayjs";
import LoadingTable from "../configuration/components/Table/LoadingTable";
import EmptyTable from "../configuration/components/Table/EmptyTable";
import { formatMoney } from "../../../helper/unit";
import { toast } from "react-toastify";
import { ArrowRightIcon, LinkIcon } from "@heroicons/react/24/solid";

const thCss =
  "text-left border whitespace-nowrap  overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer ";

const SessionsModal = ({ show, setShow, idCustomer }) => {
  const [step, setStep] = useState(0); // 0: Sessions, 1: Detail Session
  const [isShowDateTo, setShowDateTo] = useState(false);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const refDateTo = useRef({
    startDate: null,
    endDate: null,
  });
  const [isLoading, setLoading] = useState(false);
  const agents = useSelector((state) => state.cms.agents);
  const [sessions, setSessions] = useState([]);
  const [detailSession, setDetailSession] = useState({});
  const refSessionID = useRef(null); // use to delete session
  const refUnitId = useRef(null); // use to delete unit
  const [showModalDeleteSession, setShowModalDeleteSession] = useState(false);
  const [showModalDeleteUnit, setShowModalDeleteUnit] = useState(false);
  const agentsElement = document.getElementById("id-agent-select");
  const refAgentSeleted = useRef("empty");
  useEffect(() => {
    handleGetListSession();
  }, []);

  const handleGetListSession = async () => {
    try {
      setLoading(true);
      let param = {
        "unknown": 1,
        "isEnded[equal]": false,
        "meta": JSON.stringify({ sort: ["-createdAt"] }),
      };
      if (refAgentSeleted.current !== "empty") {
        param = {
          ...param,
          "agent[equalId]": refAgentSeleted.current,
        };
      }
      if (
        refDateTo.current.endDate !== null &&
        refDateTo.current.startDate !== null
      ) {
        param = {
          ...param,
          "createdAt[from]": refDateTo.current.startDate,
          "createdAt[to]": refDateTo.current.endDate,
        };
      }
      const res = await sessionApi.getSessionList(param);
      if (res && res?.data) {
        setSessions(res.data);
      }
    } catch (e) {
      console.log("Error load list session", e);
    } finally {
      setLoading(false);
    }
  };

  const handleGetDetailSession = async (id) => {
    try {
      setLoading(true);
      const res = await sessionApi.getSessionDetail(id);
      if (res && res?.data) {
        setDetailSession(res.data);
      }
    } catch (e) {
      console.log("Error load list session", e);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const viewDetail = (id) => {
    setStep(1);
    handleGetDetailSession(id);
  };

  const onChangeTime = (time) => {
    setValue(time);
    if (time.startDate === time.endDate) {
      refDateTo.current = {
        startDate: dayjs(time.startDate).startOf("day").toDate(),
        endDate: dayjs(time.endDate).endOf("day").toDate(),
      };
    } else {
      refDateTo.current = {
        startDate: time.startDate,
        endDate: time.endDate,
      };
    }

    handleGetListSession();
  };

  const handleDeleteSession = async () => {
    try {
      const deleted = await sessionApi.deleteSession(refSessionID.current);
      if (deleted.data) {
        toast.success("Session deleted successfully!");
        handleGetListSession();
        refSessionID.current = null;
      }
    } catch (err) {
      toast.error(`Error Deteted!`);
      console.log("Error Deteted", err);
    } finally {
      setShowModalDeleteSession(false);
    }
  };

  const handleDeleteUnit = async () => {
    try {
      const params = {
        removeUnit: refUnitId.current?.unitId,
      };
      const deleted = await sessionApi.deleteSessionUnit(
        refUnitId.current?.preferenceId,
        params
      );
      if (deleted.data) {
        toast.success("Session deleted successfully!");
        handleGetListSession(detailSession?.id);
        refUnitId.current = null;
      }
    } catch (err) {
      toast.error(`Error Deteted!`);
      console.log("Error Deteted", err);
    } finally {
      setShowModalDeleteUnit(false);
    }
  };

  const handeAddSessionToCustomer = async (id) => {
    try {
      const params = {
        session: id,
      };
      const res = await sessionApi.addSessionToCustomer(idCustomer, params);
      if (res && res.data) {
        toast.success("Successfully!");
        handleGetListSession();
      }
    } catch (err) {
      toast.error(`Error, Try again`);
      console.log("Error Deteted", err);
    }
  };

  const handleChangeSelect = () => {
    refAgentSeleted.current = agentsElement?.value;
    handleGetListSession();
  };

  const renderContent = () => {
    if (step === 0) {
      return (
        <table className="w-full border-collapse" id="unit-content-table">
          <thead>
            <tr className="text-sm font-semibold">
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                SESSION NAME
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                AGENT
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                DATE
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                TIME
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                FAVOURITE
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              />
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              />
            </tr>
          </thead>
          {isLoading && (
            <tbody className="h-[500px]">
              <tr>
                <td colSpan="7" className="text-center py-4">
                  <div className="flex justify-center items-center">
                    <LoadingTable />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && sessions?.length === 0 && (
            <tbody className="h-[500px]">
              <tr>
                <td
                  colSpan="7"
                  className="text-center py-4 border border-t-0 border-gray-300"
                >
                  <div className="flex justify-center items-center">
                    <EmptyTable />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {!isLoading &&
              sessions &&
              sessions.map((i) => {
                return (
                  <tr
                    key={i.id}
                    className="text-sm transition-all lg:hover:bg-gray-50 cursor-grab"
                  >
                    <td className={`${thCss} w-[calc(100vw/4)]`}>
                      <p className="max-w-[calc(100vw/4)] break-words whitespace-pre-wrap">
                        {i?.name}
                      </p>
                    </td>
                    <td className={`${thCss} w-10`}>
                      <p className="max-w-[calc(100vw/4)] break-words whitespace-pre-wrap">
                        {i?.agent?.name || '-'}
                      </p>
                    </td>
                    <td className={`${thCss} w-10`}>
                      {i?.createdAt
                        ? dayjs(i.createdAt).format("YYYY-MM-DD")
                        : ""}
                    </td>
                    <td className={`${thCss} w-10`}>
                      {i?.createdAt ? dayjs(i.createdAt).format("HH:mm") : ""}
                    </td>
                    <td
                      className={`${thCss} w-10  `}
                      onClick={() => viewDetail(i.id)}
                    >
                      <div className="flex gap-2 justify-center items-center">
                        <span>VIEW</span>
                        <div>
                          <ArrowRight />
                        </div>
                      </div>
                    </td>
                    <td
                      className={`${thCss} w-10`}
                      onClick={() => {
                        handeAddSessionToCustomer(i.id);
                      }}
                    >
                      <div className="flex justify-center gap-2">
                        Link <LinkIcon className="size-4" />
                      </div>
                    </td>
                    <td className={`${thCss} w-10`}>
                      <div
                        variant="text"
                        className="text-red-500 "
                        onClick={() => {
                          setShowModalDeleteSession(true);
                          refSessionID.current = i.id;
                        }}
                      >
                        <TrashIcon />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      );
    }
    const unitData = [];
    const preference = detailSession?.preference || {};
    const units = preference.units || [];
    for (const u of units) {
      unitData.push({
        ...u,
        preferenceId: preference?.id,
      });
    }
    return (
      <table className="w-full border-collapse" id="unit-content-table">
        <thead>
          <tr className="text-sm font-semibold table-fixed">
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
              RESIDENCE
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
              AVAILABILITY
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
              PRICE
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
              FLOOR
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
              SQ.FT.
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}>
              BEDS
            </th>
            <th
              className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
            />
          </tr>
        </thead>
        {isLoading && (
          <tbody className="h-[500px]">
            <tr>
              <td colSpan="7" className="text-center py-4">
                <div className="flex justify-center items-center">
                  <LoadingTable />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!isLoading && unitData?.length === 0 && (
          <tbody className="h-[500px]">
            <tr>
              <td
                colSpan="7"
                className="text-center py-4 border border-t-0 border-gray-300"
              >
                <div className="flex justify-center items-center">
                  <EmptyTable />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        <tbody>
          {!isLoading &&
            unitData &&
            unitData.map((i) => {
              return (
                <tr
                  key={i.id}
                  className="text-sm transition-all lg:hover:bg-gray-50 cursor-grab"
                >
                  <td className={`${thCss}`}>{i?.name}</td>
                  <td className={`${thCss} `}>{i?.availabilityStatus}</td>
                  <td className={`${thCss} `}> {formatMoney(i?.price)} </td>
                  <td className={`${thCss} `}>{i?.level}</td>
                  <td className={`${thCss} `}>{i?.areaSqm}</td>
                  <td className={`${thCss} `}>{i?.bedrooms}</td>
                  <td className={`${thCss} w-10 sticky bg-white z-[2] right-0`}>
                    <div
                      variant="text"
                      className="text-red-500 "
                      onClick={() => {
                        setShowModalDeleteUnit(true);
                        refUnitId.current = {
                          unitId: i.id,
                          preferenceId: i?.preferenceId,
                        };
                      }}
                    >
                      <TrashIcon />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const renderModalDelete = () => {
    if (step === 0) {
      return (
        <Modal
          show={showModalDeleteSession}
          maskClassName="bg-opacity-50"
          zIndex="z-[9999]"
          content={() => (
            <div>
              <p className="text-center text-xl">
                Do you want to remove this Session ?
              </p>
              <div className="flex gap-4 items-center mt-5">
                <Button
                  variant="text"
                  className="flex-1"
                  onClick={() => setShowModalDeleteSession(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="flex-1 bg-red-500 hover:bg-red-400"
                  onClick={handleDeleteSession}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
        />
      );
    }
    return (
      <Modal
        show={showModalDeleteUnit}
        maskClassName="bg-opacity-50"
        zIndex="z-[9999]"
        content={() => (
          <div>
            <p className="text-center text-xl">
              Do you want to remove this Unit ?
            </p>
            <div className="flex gap-4 items-center mt-5">
              <Button
                variant="text"
                className="flex-1"
                onClick={() => setShowModalDeleteUnit(false)}
              >
                Cancel
              </Button>
              <Button
                className="flex-1 bg-red-500 hover:bg-red-400"
                onClick={handleDeleteUnit}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      />
    );
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName=""
      panelClassName="w-[calc(100vw-300px)] p-0 rounded-lg"
      zIndex="z-[9998]"
      maskClassName="opacity-100"
      show={show}
      setShow={handleClose}
      content={
        <div className="modal-content py-2 px-5">
          <div className="flex justify-between">
            <div className="flex gap-2 items-center text-black text-2xl pb-[50px] font-[700] uppercase font-poppins">
              {step === 1 && (
                <div
                  className="flex rotate-180 flex-row-reverse cursor-pointer"
                  onClick={() => setStep(0)}
                >
                  <ArrowRightIcon className="size-5" />
                </div>
              )}
              {step === 0 ? "Anonymous Sessions" : "Session Preferences"}
            </div>
            {step === 0 ? (
              <div className="flex flex-row h-[48px] gap-4">
                <Select
                  className={cn(
                    "block  bg-gray-200 hover:bg-gray-300 border-none rounded py-2 pl-4 pr-7 text-black cursor-pointer min-w-[100px] max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
                    // Make the text of each option black on Windows
                    "*:text-black"
                  )}
                  id="id-agent-select"
                  onChange={handleChangeSelect}
                >
                  <option value="empty">Select Agent</option>
                  {agents?.length &&
                    agents.map((a) => (
                      <option
                        key={a.id}
                        value={a.id}
                        className="opacity-75 text-black font-poppins text-sm font-normal leading-normal"
                      >
                        {a.firstname} {a.surname}
                      </option>
                    ))}
                </Select>
                <button
                  className={cn(
                    `bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded`,
                    isShowDateTo && "bg-gray-300"
                  )}
                  onClick={() => setShowDateTo((v) => !v)}
                >
                  From/ To Date
                </button>
              </div>
            ) : (
              <div>{detailSession?.name}</div>
            )}
          </div>
          {isShowDateTo && step === 0 && (
            <div className="flex gap-4 mb-4">
              <Datepicker
                value={value}
                onChange={(newValue) => onChangeTime(newValue)}
                showShortcuts={true}
                primaryColor="blue"
                separator="to"
                displayFormat="DD/MM/YYYY"
                readOnly
              />
            </div>
          )}
          <div className="wrap-modal-body">
            <div className="h-[calc(100dvh-300px)] overflow-y-scroll">
              {renderContent()}
            </div>
          </div>
          {renderModalDelete()}
        </div>
      }
    />
  );
};

export default SessionsModal;
