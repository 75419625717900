import React, { useCallback, useState } from "react";
import _ from "lodash";

import { MEDIA_TYPE } from "../../../utils";
import mediaApi from "../../../../../apis/api/media";

export const useMediaSearch = (initialSearch = "") => {
  const [search, setSearch] = useState(initialSearch);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [medias, setMedias] = useState([]);

  const debouncedSetSearch = useCallback(
    _.debounce(async (value) => {
      await fetchAsset(value);
    }, 500),
    []
  );

  const fetchAsset = useCallback(async (search) => {
    try {
      setLoading(true);
      setError(null);
      const res = await mediaApi.getMediaList(
        _.omitBy(
          {
            "type[equal]": MEDIA_TYPE.VIRTUALTOURS,
            search: search ? decodeURIComponent(search) : "",
          },
          _.isNil
        )
      );
      setMedias(res?.data || []);
    } catch (err) {
      setError(err.message || "Failed to fetch media");
      setMedias([]);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    (() => fetchAsset(""))();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedSetSearch(e.target.value);
  };

  return {
    search,
    handleSearch,
    loading,
    error,
    medias,
    refetch: fetchAsset,
  };
};
