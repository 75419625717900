/** @format */

import ApiClient from "../apiClient";
import { defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(defaultFetcher);

const getSessionList = (data) => client.get(endPoint.SESSION_LIST, data);
const getSessionDetail = (id, data) =>
  client.get(endPoint.SESSION_DETAIL.replace(":id", id), data);
const createSession = (data) => client.post(endPoint.SESSION_CREATE, data);
const updateSession = (id, data) =>
  client.put(endPoint.SESSION_UPDATE.replace(":id", id), data);
const deleteSession = (id, data) =>
  client.delete(endPoint.SESSION_DELETE.replace(":id", id), data);
const deleteSessionUnit = (preferenceId, data) =>
  client.put(
    endPoint.SESSION_UNIT_DELETE.replace(":preferenceId", preferenceId),
    data
  );
const addSessionToCustomer = (id, data) =>
  client.put(endPoint.CUSTOMER_UPDATE.replace(":id", id), data);
const unLinkSessionToCustomer = (id, data) =>
  client.put(endPoint.SESSION_DELETE.replace(":id", id), data);
const updateAgent = (id, data) =>
  client.put(endPoint.SESSION_DELETE.replace(":id", id), data);

const updateHistoryCompare = (preferenceId, data) =>
  client.put(`${endPoint.PREFERENCE_UPDATE}/${preferenceId}`, data);

const getHistoryCompare = (preferenceId, data) =>
  client.get(`${endPoint.PREFERENCE_UPDATE}/${preferenceId}`, data);

export default {
  getSessionList,
  getSessionDetail,
  createSession,
  updateSession,
  deleteSession,
  deleteSessionUnit,
  addSessionToCustomer,
  unLinkSessionToCustomer,
  updateAgent,
  updateHistoryCompare,
  getHistoryCompare,
};
