import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import StartNewSession from "./start-new-session";
import ContinueSession from "./continue-session";
import { Button, Modal } from "../../../../pages/cms/components/commons";
import sessionApi from "../../../../apis/api/session";
import RolesAccess from "../../../../apis/api/roles-access";
import dayjs from "dayjs";
import CancelIcon from "../../../svgs/cancelIcon";

//Props ={
//  onSuccessSelectProfile: () => void
//  setIsShow: (v: boolean) => void
//  customerId: string
// }

const SessionSelect = (props) => {
  const { onSuccessSelectProfile, setIsShow, customerId } = props;
  const user = useSelector((state) => state.user?.data);
  const [loading, setLoading] = React.useState(false);

  const isSalesAgentRole = user?.isAgent;

  const onStartNewSession = async (data, onSuccess) => {
    setLoading(true);
    const res = await sessionApi.createSession({
      customer: customerId,
      agent: data?.agentId,
    });

    if (res?.data) {
      setLoading(false);
      onSuccessSelectProfile({
        id: res?.data?.id, // id is id of session
      });
      onSuccess && onSuccess();
      toast.success("Session created successfully");
      setIsShow(false);
    } else {
      toast.error("Session created failed");
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-center font-bold text-xl mt-5">Select session</h2>

      <p className="text-center my-5">Do you want to start a new session?</p>
      <div className="absolute top-3 right-3" onClick={() => setIsShow(false)}>
        <CancelIcon className="[&>circle]:fill-black cursor-pointer" />
      </div>
      <div className="flex gap-5 justify-space-between">
        <Modal
          panelClassName="w-[700px] overflow-visible"
          zIndex="z-[9998]"
          maskClassName="bg-opacity-50"
          content={({ setIsShow }) => (
            <ContinueSession {...props} onClose={() => setIsShow(false)} />
          )}
          triggerClassName="flex-1"
          trigger={<Button className="w-full py-4">CONTINUE SESSION</Button>}
        />
        <div className="flex-1">
          {isSalesAgentRole && (
            <Button
              isLoading={loading}
              disabled={loading}
              variant="text"
              className="w-full py-4"
              onClick={() =>
                onStartNewSession({
                  agentId: user?.id,
                })
              }
            >
              {!loading && "START A NEW SESSION"}
            </Button>
          )}
          {!isSalesAgentRole && (
            <Modal
              panelClassName="w-[700px] overflow-visible"
              zIndex="z-[9998]"
              maskClassName="bg-opacity-50"
              content={({ setIsShow }) => (
                <StartNewSession
                  onStartNewSession={onStartNewSession}
                  onClose={() => setIsShow(false)}
                />
              )}
              triggerClassName="flex-1"
              trigger={
                <Button variant="text" className="w-full py-4">
                  START A NEW SESSION
                </Button>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionSelect;
