/** @format */

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";

import sessionApi from "../../../../apis/api/session";
import {
  AutocompleteField,
  Button,
} from "../../../../pages/cms/components/commons";
import { toast } from "react-toastify";

const ContinueSession = ({
  onClose,
  onSuccessSelectProfile,
  setIsShow,
  customerId,
}) => {
  const { control, formState, handleSubmit } = useForm();
  const user = useSelector((state) => state.user?.data);
  const isSalesAgentRole = user?.isAgent;

  const loadSession = async (inputValue) => {
    const response = await sessionApi.getSessionList(
      _.omitBy(
        {
          "name[regex]": inputValue ? decodeURIComponent(inputValue) : "",
          unknown: 1,
          "customer[equalId]": customerId,
          "isEnded[equal]": false,
          ...(isSalesAgentRole && {"agent[equalId]": user.id})
        },
        _.isNil
      )
    );

    if (response?.data) {
      const unknownSessions = response.data;

      const unKnownOptions = {
        label: (
          <div className="font-bold text-green-600">
            anonymous customer session
          </div>
        ),
        options: unknownSessions.map((session) => ({
          label: session.name,
          value: session.id,
        })),
      };

      return [{ ...unKnownOptions }];
    }
    return [];
  };

  const options = useMemo(
    () =>
      _.debounce((inputValue, callback) => {
        loadSession(inputValue, callback)
          .then((options) => callback(options))
          .catch(() => callback([]));
      }, 500), // Delay 500ms
    [],
    [customerId]
  );

  const onSubmit = async (values) => {
    onSuccessSelectProfile({
      id: values?.session?.value, // id is id of session
    });
    setIsShow(false);
    onClose && onClose();
    toast.success("Session updated successfully");
  };

  return (
    <div>
      <h2 className="text-center font-bold text-xl mt-5">Select session</h2>
      <div className="flex gap-2">
        <div className="flex-1">
          <AutocompleteField
            controller={{
              control,
              name: "session",
            }}
            placeholder="Search session"
            autocompleteProps={{
              loadOptions: options,
              maxMenuHeight: 300,
            }}
            label="Session"
          />
        </div>
      </div>
      <div className="pt-40 flex gap-2">
        <Button
          disabled={formState?.isSubmitting}
          onClick={onClose}
          className="py-4 flex-1"
          variant="text"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isDirty || formState?.isSubmitting}
          isLoading={formState?.isSubmitting}
          className="py-4 flex-1"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ContinueSession;
