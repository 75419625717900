/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "../components/Create";
import {
  reqGetListAgents,
  reqGetListCustomers,
} from "../../../reduxs/cms/action";
import EditCustomerModal from "./EditCustomerModal";
import AddCustomerModal from "./AddCustomerModal";
import { toast } from "react-toastify";
import CustomerFavoritesModal from "./CustomerFavoritesModal";
import customersAPI from "../../../apis/api/customer";
import { ReactComponent as ArrowRight } from "../../../assets/images/arrow-right.svg";
import CmsPageHeader from "../components/PageHeader";
import { useNavigate } from "react-router-dom";
// import { SpinLoading } from "../../../components/svgs/icons";
import SessionsModal from "./SessionsModal";
import ViewModal from "./ViewModal";
import EmptyTable from "../configuration/components/Table/EmptyTable";
import LoadingTable from "../configuration/components/Table/LoadingTable";
import Pagination from "../components/Pagination/Pagination";
import { PAGE_SIZE } from "../utils";
import { cn } from "../../../helper/utils";
import dayjs from "dayjs";
import SearchIcon from "../components/Search";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const thCss =
  "text-left border whitespace-nowrap  overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

export const PurchaseOptions = [
  { label: "Primary Residence", value: "Primary Residence" },
];

// const Row = ({
//   data,
//   onEditCustomer,
//   onViewFavorite,
//   onSaveSuccess,
//   onConnect,
// }) => {
//   const [show, setShow] = useState(false);

//   const handleCopyURL = () => {
//     if (!data) return;
//     const url =
//       data?.uniqueUrl ||
//       `${window.location.origin}/post-engagement/${data?.id}`;
//     navigator.clipboard.writeText(url);
//     toast.success("Copied customer session url!");
//   };

//   const handleUpdateActive = async (e) => {
//     if (!data) return;

//     try {
//       const updated = {
//         isActive: e?.target?.checked || false,
//       };
//       const res = await customersAPI.updateCustomer(data.id, updated);
//       if (res.data) {
//         toast.success("Customer updated successfully!");
//         onSaveSuccess && onSaveSuccess();
//       }
//     } catch (err) {
//       toast.error(err.message);
//     }
//   };

//   return (
//     <div className="grid-cols-[15%_15%_23%_13%_12%_12%_calc(10%_-_35px)_35px] min-h-[35px] grid border-b-[1px] border-solid border-[#C5C5C566]">
//       <div className="text-black font-poppins text-sm leading-normal flex items-center inline-block align-middle">
//         {data?.firstname}
//       </div>
//       <div className="text-black font-poppins text-sm leading-normal flex items-center inline-block align-middle">
//         {data?.surname}
//       </div>
//       <div className="text-black font-poppins text-sm leading-normal flex items-center inline-block align-middle">
//         {data?.email}
//       </div>
//       <div className="text-black font-poppins text-sm leading-normal flex items-center inline-block align-middle">
//         {data?.agent ? `${data?.agent?.firstname} ${data?.agent?.surname}` : ""}
//       </div>
//       {data?.sessions?.length > 0 ? (
//         <div
//           className="cursor-pointer gap-[20px] justify-start flex items-center"
//           onClick={() => onViewFavorite?.(data)}
//         >
//           <div className="text-sm font-medium">VIEW</div>
//           <div>
//             <ArrowRight />
//           </div>
//         </div>
//       ) : (
//         <div
//           className="cursor-pointer gap-[20px] justify-start flex items-center"
//           onClick={() => onConnect?.(data)}
//         >
//           <div className="text-sm font-medium text-green-400">CONNECT + </div>
//         </div>
//       )}

//       <div
//         className="flex gap-[20px] cursor-pointer justify-start items-center"
//         onClick={() => handleCopyURL()}
//       >
//         <div className="text-sm font-medium">COPY</div>
//         <div>
//           <ArrowRight />
//         </div>
//       </div>
//       <div className="flex items-center">
//         <div className="flex items-center h-full">
//           <input
//             className={`m-0 p-0 bg-[#CCCCCC59] w-[44px] h-[20px] !switch-bg-image !border-none bg-black rounded-[50px] checked:bg-[right] !bg-no-repeat !shadow-none cursor-pointer !checked:bg-[#000000] !checked:bg-auto !bg-auto transition-[300ms_ease-in-out]`}
//             type="checkbox"
//             id="customer-state-1"
//             defaultChecked={data?.isActive}
//             onChange={handleUpdateActive}
//           />
//           <label className="form-check-label" htmlFor="customer-state-1" />
//         </div>
//       </div>
//       <div onClick={() => onEditCustomer?.(data)} className="flex items-center">
//         <div className="text-right rounded-[50px] bg-[#D3D0D159] w-[31px] h-[10px] cursor-pointer flex justify-center items-center">
//           <img className="w-full h-full" src="/icons/edit-button.svg" alt="" />
//         </div>
//       </div>
//     </div>
//   );
// };

const CMSCustomers = ({ accessMiddleware }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShowEditCustomerModal, setIsShowEditCustomerModal] = useState(false);
  const [isShowAddCustomerModal, setIsShowAddCustomerModal] = useState(false);
  const [isShowCustomerFavoritesModal, setIsShowCustomerFavoritesModal] =
    useState(false);
  const [seletedCustomer, setSelectedCustomer] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [isShowSessionModal, setShowSessionModal] = useState(false);
  const [isShowViewModal, setShowViewModal] = useState(false);

  const customers = useSelector((state) => state.cms.customers);
  const total = useSelector((state) => state.cms.totalCustomer);
  const refCustomer = useRef(null);
  const refCurrentPage = useRef(1);

  useEffect(() => {
    accessMiddleware(navigate, "/403cms", () => setLoading(false));
    getListCustomers();
    getListAgents();
  }, []);

  const getListCustomers = (currentPage = 1) => {
    dispatch(
      reqGetListCustomers({
        search,
        sortBy: JSON.stringify({
          createdAt: -1,
        }),
        meta: JSON.stringify({ page: currentPage, pageSize: PAGE_SIZE }),
      })
    );
    refCurrentPage.current = currentPage;
  };

  const getListAgents = () => {
    dispatch(
      reqGetListAgents({
        sortBy: JSON.stringify({
          name: 1,
        }),
        isActive: true,
      })
    );
  };

  const onSaveSuccess = () => {
    getListCustomers();
  };

  const onCreateSuccess = () => {
    getListCustomers();
  };

  const onEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsShowEditCustomerModal(true);
  };

  const onAddNewCustomer = () => {
    setIsShowAddCustomerModal(true);
  };

  const onViewFavorite = (customer) => {
    refCustomer.current = {
      id: customer?.id,
      name: customer?.name,
    };
    // setSelectedCustomer(customer);
    // setIsShowCustomerFavoritesModal(true);
    setShowViewModal(true);
  };

  const onConnect = (customer) => {
    refCustomer.current = {
      id: customer?.id,
      name: customer?.name,
    };
    setShowSessionModal(true);
  };

  const handleCopyURL = (data) => {
    if (!data) return;
    const url =
      data?.uniqueUrl ||
      `${window.location.origin}/post-engagement/${data?.id}`;
    navigator.clipboard.writeText(url);
    toast.success("Copied customer session url!");
  };

  const handleUpdateActive = async (e, data) => {
    if (!data) return;

    try {
      const updated = {
        isActive: e?.target?.checked || false,
      };
      const res = await customersAPI.updateCustomer(data.id, updated);
      if (res.data) {
        toast.success("Customer updated successfully!");
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleCloseSessionModal = () => {
    getListCustomers(refCurrentPage.current);
    setShowSessionModal(false);
  };

  const handleCloseViewModal = () => {
    getListCustomers(refCurrentPage.current);
    setShowViewModal(false);
  };

  const renderContentV2 = () => {
    return (
      <div className=" h-[calc(100dvh-400px)] overflow-y-scroll w-full">
        <table className="w-full border-collapse" id="unit-content-table">
          <thead>
            <tr className="text-sm font-semibold">
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                FIRST NAME
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                LAST NAME
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                GENERATED BY
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                LAST SESSION WITH
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                EMAIL
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                AGENT
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100 text-center`}
              >
                SESSIONS
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                URL
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              >
                ACTIVE
              </th>
              <th
                className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
              />
            </tr>
          </thead>
          {loading && (
            <tbody className="h-[500px]">
              <tr>
                <td colSpan="7" className="text-center py-4">
                  <div className="flex justify-center items-center">
                    <LoadingTable />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!loading && customers?.length === 0 && (
            <tbody className="h-[500px]">
              <tr>
                <td
                  colSpan="7"
                  className="text-center py-4 border border-t-0 border-gray-300"
                >
                  <div className="flex justify-center items-center">
                    <EmptyTable />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!loading && customers && (
            <tbody>
              {(customers || [])?.map((data, index) => {
                return (
                  <tr
                    key={`${data.id}-${index}`}
                    className="text-sm transition-all lg:hover:bg-gray-100 cursor-grab"
                  >
                    <td className={thCss}>{data?.firstname}</td>
                    <td className={`${thCss}`}>{data?.surname}</td>
                    <td className={`${thCss} w-10`}>
                      {data?.createdBy
                        ? `${data?.createdBy?.firstname} ${data?.createdBy?.surname}`
                        : "_"}
                    </td>
                    <td className={`${thCss}`}>
                      {`${data?.latestSession?.name || ""} - ${data?.latestSession?.agent?.firstname || ""
                        } ${data?.latestSession?.agent?.surname || ""}`}
                    </td>
                    <td className={`${thCss} w-10`}>{data?.email}</td>
                    <td className={`${thCss} w-10`}>
                      {data?.agent
                        ? `${data?.agent?.firstname} ${data?.agent?.surname}`
                        : "_"}
                    </td>
                    <td className={`${thCss} w-10 px-2 py-0`}>
                      <div className="flex flex-row gap-4">
                        <div
                          className={cn(
                            "cursor-pointer gap-2 justify-start flex items-center",
                            data?.sessions?.length === 0 &&
                            "pointer-events-none opacity-50"
                          )}
                          onClick={() => onViewFavorite?.(data)}
                        >
                          <div className="text-sm font-medium">VIEW</div>
                          <div>
                            <ArrowRight />
                          </div>
                        </div>
                        <div
                          className="cursor-pointer justify-start flex items-center pl-2 border-l border-solid border-[#ddd]"
                          onClick={() => onConnect?.(data)}
                        >
                          <div className="text-sm font-medium text-green-400">
                            CONNECT +{" "}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className={`${thCss} w-10`}
                      onClick={() => handleCopyURL(data)}
                    >
                      <div className="cursor-pointer gap-[20px] justify-start flex items-center">
                        <div className="text-sm font-medium">COPY</div>
                        <div>
                          <ArrowRight />
                        </div>
                      </div>
                    </td>
                    <td className={`${thCss} w-10`}>
                      <div className="flex items-center h-full">
                        <input
                          className={`m-0 p-0 bg-[#CCCCCC59] w-[44px] h-[20px] !switch-bg-image !border-none bg-black rounded-[50px] checked:bg-[right] !bg-no-repeat !shadow-none cursor-pointer !checked:bg-[#000000] !checked:bg-auto !bg-auto transition-[300ms_ease-in-out]`}
                          type="checkbox"
                          id="customer-state-1"
                          defaultChecked={data?.isActive}
                          onChange={(e) => handleUpdateActive(e, data)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customer-state-1"
                        />
                      </div>
                    </td>
                    <td
                      className={`${thCss} w-10`}
                      onClick={() => onEditCustomer?.(data)}
                    >
                      <div className="text-right rounded-[50px] bg-[#D3D0D159] w-[31px] h-[10px] cursor-pointer flex justify-center items-center">
                        <img
                          className="w-full h-full"
                          src="/icons/edit-button.svg"
                          alt=""
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    );
  };

  return (
    <div className="page-container">
      <CmsPageHeader title={"CUSTOMERS"} />
      <div className="page-body m-0 pt-2 px-8 pb-0 flex flex-col items-center overflow-auto">
        <div class="w-full flex items-center justify-end py-4">
          <div class="relative w-full max-w-60">
            <input
              type="text"
              id="simple-search"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full ps-4 p-2.5"
              placeholder="Search customer"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
              required
            />
          </div>
          <button
            class="rounded-xl ml-2 bg-blue-50 border-blue-200 text-blue-900 p-2.5 border-2 text-center text-sm transition-all shadow-sm hover:shadow-lg active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            onClick={() => getListCustomers()}
          >
            <MagnifyingGlassIcon className="size-5" />
          </button>
        </div>
        {renderContentV2()}
        {!!total && (
          <Pagination
            customStyle="bottom-14"
            resetPage={""}
            total={total}
            getDataPagination={getListCustomers}
            pageSize={PAGE_SIZE}
          />
        )}
        <div className="w-full">
          <CreateIcon createCustomer={true} onClick={onAddNewCustomer} />
        </div>
      </div>
      {isShowEditCustomerModal && (
        <EditCustomerModal
          show={isShowEditCustomerModal}
          setShow={setIsShowEditCustomerModal}
          customer={seletedCustomer}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {isShowAddCustomerModal && (
        <AddCustomerModal
          show={isShowAddCustomerModal}
          setShow={setIsShowAddCustomerModal}
          onCreateSuccess={onCreateSuccess}
        />
      )}
      {isShowCustomerFavoritesModal && (
        <CustomerFavoritesModal
          show={isShowCustomerFavoritesModal}
          setShow={setIsShowCustomerFavoritesModal}
          customer={seletedCustomer}
        />
      )}
      {isShowSessionModal && (
        <SessionsModal
          show={isShowSessionModal}
          setShow={handleCloseSessionModal}
          idCustomer={refCustomer.current}
        />
      )}
      {isShowViewModal && (
        <ViewModal
          show={isShowViewModal}
          setShow={handleCloseViewModal}
          idCustomer={refCustomer.current}
        />
      )}
    </div>
  );
};
export default CMSCustomers;
