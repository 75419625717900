import React from "react";

import {
  Button,
  InputField,
  TextEditorField,
  UploadField,
} from "../../components/commons";
import ArrowUp from "../../configuration/components/svgs/ArrowUp";
import ArrowDown from "../../configuration/components/svgs/ArrowDown";
import TrashIcon from "../../configuration/components/svgs/TrashIcon";

const MultipleRightImage = ({
  control,
  index,
  move,
  disableDown,
  disableUp,
  remove,
}) => {
  return (
    <div className="my-5">
      <div className="border-[1px]  relative rounded-lg border-solid">
        <div className="flex items-center gap-5 justify-between px-10 py-5">
          <div className="flex flex-[0_0_70%] flex-wrap items-center justify-center">
            <InputField
              controller={{
                control,
                name: `sections.${index}.tag`,
              }}
              inputProps={{
                placeholder: "Tag",
              }}
              wrapperClassName="w-full mb-4"
            />
            <InputField
              controller={{
                control,
                name: `sections.${index}.title`,
              }}
              inputProps={{
                placeholder: "Title",
              }}
              wrapperClassName="flex-[0_0_100%] mb-4"
            />
            <TextEditorField
              wrapperClassName="w-full"
              controller={{
                control,
                name: `sections.${index}.description`,
              }}
            />
          </div>

          <div className=" flex-1 flex flex-wrap items-center gap-2 justify-end">
            <UploadField
              wrapperClassName="!min-h-[110px]"
              imageClassName="!h-[110px]"
              wrapperFormClassName="flex-[0_0_100%]"
              description="Media"
              controller={{
                control,
                name: `sections.${index}.media1`,
              }}
            />
            <UploadField
              wrapperClassName="!min-h-[110px]"
              imageClassName="!h-[110px]"
              wrapperFormClassName="flex-[0_0_100%]"
              description="Media"
              controller={{
                control,
                name: `sections.${index}.media2`,
              }}
            />
          </div>
        </div>
        <div className="absolute top-2 right-[-10px]">
          <Button
            onClick={() => remove(index)}
            className="!border-none text-red-500"
            variant="icon"
            icon={<TrashIcon width={20} height={20} />}
          />
        </div>
        <div className="absolute top-1/2 right-[-50px] -translate-y-1/2">
          <div className="pb-5 ">
            <Button
              disabled={disableUp}
              onClick={() => move(index, index - 1)}
              className="!border-none"
              variant="icon"
              icon={<ArrowUp width={20} height={20} />}
            />
          </div>
          <div className="cursor-pointer">
            <Button
              disabled={disableDown}
              onClick={() => move(index, index + 1)}
              className="!border-none"
              variant="icon"
              icon={<ArrowDown width={20} height={20} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleRightImage;
