import React from "react";
import {
  BtnBold,
  BtnItalic,
  BtnStrikeThrough,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";

const TextEditor = React.forwardRef(({ isReadOnly, error, ...props }, ref) => {
  const stripStyles = (html) => {
    // Create a temporary div
    const temp = document.createElement("div");
    temp.innerHTML = html;

    // Remove all style attributes from all elements
    const elements = temp.getElementsByTagName("*");
    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute("style");
      elements[i].removeAttribute("class");
      elements[i].removeAttribute("bgcolor");
      elements[i].removeAttribute("color");
    }

    // Remove specific elements we don't want (like spans with background)
    const spans = temp.getElementsByTagName("span");
    while (spans.length > 0) {
      const span = spans[0];
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    }

    return temp.innerHTML;
  };

  const handlePaste = (e) => {
    e.preventDefault();

    let content;

    // Try to get HTML content first
    const htmlContent = e.clipboardData.getData("text/html");
    const plainText = e.clipboardData.getData("text/plain");

    if (htmlContent) {
      // If we have HTML, clean it
      content = stripStyles(htmlContent);
    } else {
      // If no HTML, use plain text
      content = plainText
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\n/g, "<br>");
    }

    // Insert the cleaned content
    document.execCommand("insertHTML", false, content);
  };

  return (
    <EditorProvider>
      <Editor {...props} ref={ref} onPaste={handlePaste}>
        {!isReadOnly && (
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
          </Toolbar>
        )}
      </Editor>
    </EditorProvider>
  );
});

TextEditor.displayName = "TextEditor";

export default TextEditor;
