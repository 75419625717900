/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import _ from "lodash";

import AddMeidaContentModal from "../../../../media/add-modal";
import { getS3BEMediaUrl } from "../../../../../../helper/media";
import Search from "../../../Search";
import useDebouncedSearch from "../../../../../../hooks/useDebouncedSearch";
import LeftArrowIcon from "../../../../../../components/svgs/LeftArrowIcon";
import { Button, Modal } from "../../../../components/commons";
import playlistApi from "../../../../../../apis/api/playlist";
import unitApi from "../../../../../../apis/api/unit";
import {
  reqGetListMedia,
  reqGetListFolder,
} from "../../../../../../reduxs/cms/action";
import EmptyTable from "../../../../configuration/components/Table/EmptyTable";
import { getUploadParams } from "../../../../utils";
import LoadingTable from "../../../../configuration/components/Table/LoadingTable";
import defaultImage from "../../../../../../assets/images/cms/icImg.webp";
import defaultVideo from "../../../../../../assets/images/cms/icVideo.webp";
import Pagination from "../../../../components/Pagination/Pagination";
import { PAGE_SIZE } from "../../../../utils";

const AssetContent = ({
  mediaType,
  gallery,
  forUnit = false,
  forAmenity,
  unitId,
  handleRefresh,
}) => {
  const dispatch = useDispatch();
  const { media, total } = useSelector((state) => state.cms);
  const { gallery: galleryRes } = useSelector((state) => state.gallery);

  const [createLoading, setCreateLoading] = useState(false);
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [loadingCreateMedia, setLoadingCreateMedia] = useState(false);
  const refSearch = useRef();
  const assets = useSelector((state) => state.cms.folders) || [];

  const [search, setSearch] = useDebouncedSearch();
  const getMediaList = (currentPage = 1) => {
    const params = {
      "type[equal]": mediaType,
      meta: JSON.stringify({ page: currentPage, pageSize: PAGE_SIZE }),
      search: search,
    };
    dispatch(reqGetListMedia(_.omitBy(params, _.isEmpty)));
  };

  useEffect(() => {
    getMediaList();
  }, [search, mediaType, galleryRes?.id]);

  useEffect(() => {
    if (!assets.length) {
      dispatch(reqGetListFolder());
    }
  }, [assets]);

  const onDrop = async (files) => {
    if (!!files?.length) {
      setLoadingCreateMedia(true);
      const params = await getUploadParams(
        files,
        mediaType,
        assets.find((asset) => asset.value === mediaType).label
      );

      if (params?.length) {
        let completed = 0;

        await Promise.all(
          params.map(async (param) => {
            await playlistApi.createMediaPlaylist(param);
            completed++;
          })
        );
        toast.success("Add new content successfully!");
        getMediaList();
      }
      setLoadingCreateMedia(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const handleAddMedia = async (media, callBack) => {
    try {
      setCreateLoading(true);
      const param = {
        mediaId: media?.id,
        mediaType: mediaType, // images, floorplans, viewlines, 360image
      };
      unitApi.addUnitMedia(unitId, param).then((res) => {
        if (res && res?.data) {
          toast.success("Add new media successfully!");
          callBack();
          getMediaList();
          handleRefresh();
        }
      });
    } catch (e) {
      console.log("Error add media", e);
      toast.error("Add media failed!");
      callBack();
    }
  };

  const onCreateSuccess = () => {
    getMediaList();
    setIsShowAddContentModal(false);
  };

  useEffect(() => {
    refSearch.current?.resetSearch();
  }, [gallery]);

  return (
    <>
      <section className="h-full flex flex-col">
        <div className="flex gap-2 items-center flex-row justify-between mb-1">
          <h2 className="capitalize font-semibold">{`Media type: ${mediaType}`}</h2>
          {/* <p className="italic text-sm">Images (only show compatible assets)</p> */}
          <div
            className="cursor-pointer"
            onClick={() => setIsShowAddContentModal(true)}
          >
            <p className="underline text-sm font-semibold">Add</p>
          </div>
        </div>
        <Search
          ref={refSearch}
          callbackOnInputChange={setSearch}
          formClassName="w-full"
          // disabled={!media?.length}
        />
        <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
        <div
          className="h-[58vh] overflow-y-scroll overflow-x-hidden"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Droppable droppableId="asset-content">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="grid grid-cols-2 md:grid-cols-3 gap-2"
              >
                {!!media?.length &&
                  media?.map((item, index) => {
                    const isDisabledAdd =
                      forUnit || forAmenity
                        ? selectedPlaylist.medias?.some(
                            (media) => media?.id === item?.id
                          )
                        : galleryRes?.playlist?.medias?.some(
                            (media) => media?.id === item?.id
                          );

                    return (
                      <Draggable
                        key={item?.id + `_${index}`}
                        draggableId={item?.id + `_${index}`}
                        index={index}
                        isDragDisabled={isDisabledAdd}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item.id}
                            className="rounded-[10px]"
                          >
                            <LazyLoadImage
                              src={getS3BEMediaUrl(item?.thumbnailPath)}
                              className="h-[120px] object-cover w-full rounded-tl-lg rounded-tr-lg m-auto"
                              controls
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  item?.type === "videos"
                                    ? defaultVideo
                                    : defaultImage;
                              }}
                            />
                            <div className="bg-black p-2 text-white text-xs min-h-[48px]">
                              <p className="line-clamp-2 overflow-hidden">
                                {item?.name}
                              </p>
                            </div>
                            <Modal
                              disabled={isDisabledAdd}
                              maskClassName="bg-opacity-50"
                              zIndex="z-[9999]"
                              triggerClassName="h-auto"
                              trigger={
                                <Button
                                  disabled={isDisabledAdd}
                                  className="rounded-bl-2xl rounded-br-2xl w-full rounded-none flex cursor-pointer justify-start items-center gap-2 px-2  bg-[rgb(117,117,117)]"
                                >
                                  <LeftArrowIcon className="size-3 text-white" />
                                  <span className="text-[14px] text-white">
                                    Add
                                  </span>
                                </Button>
                              }
                              content={({ setIsShow }) => (
                                <div>
                                  <p className="text-center text-xl">
                                    Do you want to add this media to playlist ?
                                  </p>
                                  <div className="flex gap-4 items-center mt-5">
                                    <Button
                                      disabled={createLoading}
                                      variant="text"
                                      className="flex-1"
                                      onClick={() => setIsShow(false)}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      isLoading={createLoading}
                                      className="flex-1"
                                      onClick={() =>
                                        handleAddMedia(item, () => {
                                          setIsShow(false);
                                          setCreateLoading(false);
                                        })
                                      }
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {!media?.length && (
                  <div className="col-span-2 md:col-span-3">
                    <EmptyTable />
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {isDragActive && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-70">
              <p className="text-2xl text-black font-bold">
                Drop the files here ...
              </p>
            </div>
          )}
          {loadingCreateMedia && (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-gray-200 bg-opacity-70">
              <LoadingTable />
            </div>
          )}
        </div>
        {isShowAddContentModal && (
          <AddMeidaContentModal
            mediaType={mediaType}
            show={isShowAddContentModal}
            setShow={setIsShowAddContentModal}
            onCreateSuccess={onCreateSuccess}
            isFromMediaFolder={true}
          />
        )}
      </section>
      {!!total && (
        <Pagination
          resetPage={unitId}
          customStyle="bottom-0"
          total={total}
          getDataPagination={getMediaList}
          pageSize={PAGE_SIZE}
        />
      )}
    </>
  );
};

export default AssetContent;
