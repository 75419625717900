/** @format */
import { useCallback, useEffect, useMemo } from "react";
import { cn } from "../../helper/utils";
import ListCompare from "./components/ListCompare";
import PopoverResidence from "./popover-residence";
import { useForm, useWatch } from "react-hook-form";
import _, { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  actSetCompareHistory,
  updateHistoryCompare,
} from "../../reduxs/compare/action";
import { refetchGetFeatureList } from "../../reduxs/page-configuration/action";

const ComparePage = ({ isPresentation, pages }) => {
  const dispatch = useDispatch();
  const selectedUnitExplore = useSelector(
    (state) => state.unitExplore.selectedUnit
  );
  const session = useSelector((state) => state.precinctExplore.session);
  const compareHistory = useSelector((state) => state.compare.compareHistory);

  const form = useForm();
  const { setValue, getValues } = form;
  const residenceValues = useWatch({
    control: form.control,
  });

  const preferenceUnitIds = useMemo(() => {
    const units = session?.preference?.units || [];
    return units.map((item) => item.id);
  }, [session]);

  const comparingUnitIds = useMemo(() => {
    const units = session?.preference?.comparingUnits || [];
    return units.map((item) => item.id);
  }, [session]);

  const likedUnitIds = useMemo(() => {
    const units = session?.preference?.unitsShortlist || [];
    return units.map((item) => item.id);
  }, [session]);

  useEffect(() => {
    const handleTouchMove = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  //set default Value for residence form
  useEffect(() => {
    if (selectedUnitExplore) {
      const data = getValues(`floor-${selectedUnitExplore.level}`) || [];
      if (!data.includes(selectedUnitExplore.id)) {
        data.push(selectedUnitExplore.id);
        setValue(`floor-${selectedUnitExplore.level}`, data);
      }
    }
  }, [selectedUnitExplore]);

  //save history compare
  useEffect(() => {
    if (compareHistory) {
      Object.keys(compareHistory).forEach((key) => {
        setValue(key, compareHistory[key]);
      });
    }
    handleGetStyle();
  }, []);

  const handleGetStyle = () => {
    dispatch(refetchGetFeatureList({ idModule: "677c8e9cad106270785c735f" }));
  };

  const selectedUnits = useMemo(
    () =>
      _(residenceValues)
        .values()
        .filter((value) => !_.isEmpty(value))
        .flatten()
        .value(),
    [residenceValues]
  );

  const setHistoryCompare = useCallback(() => {
    dispatch(
      actSetCompareHistory(
        _.pickBy(
          residenceValues,
          (value) =>
            !_.isUndefined(value) && !(_.isArray(value) && _.isEmpty(value))
        )
      )
    );
    dispatch(
      updateHistoryCompare(session?.preference?.id, {
        comparingUnits: selectedUnits,
      })
    );
  }, [residenceValues, session]);

  const debouncedSetHistory = useMemo(
    () => debounce(setHistoryCompare, 700),
    [setHistoryCompare]
  );

  useEffect(() => {
    debouncedSetHistory();

    return () => {
      debouncedSetHistory.cancel();
    };
  }, [residenceValues]);

  const onRemove = (unitId, level) => {
    const data = getValues(`floor-${level}`) || [];
    if (data.length === 1) {
      setValue(`floor-${level}`, undefined);
    } else {
      const idx = data.findIndex((i) => i === unitId);
      if (idx !== -1) {
        data.splice(idx, 1);
        setValue(`floor-${level}`, data);
      }
    }
  };

  return (
    <section
      className={cn(
        "compare-page overflow-x-hidden  py-14 h-[calc(100dvh-40px)]  bg-[rgb(253,251,247)] disable-scroll-bar w-screen absolute top-0  mb-[60px] overflow-y-scroll",
        {
          "h-full mt-0": isPresentation,
        }
      )}
    >
      <PopoverResidence
        popoverClassName={isPresentation ? "invisible" : "visible"}
        pages={pages}
        form={form}
        selectedUnits={selectedUnits}
        preferenceUnitIds={preferenceUnitIds}
        isPresentation={isPresentation}
      />
      <ListCompare
        data={selectedUnits}
        onRemove={onRemove}
        preferenceUnitIds={preferenceUnitIds}
        idPreference={session?.preference?.id}
        isPresentation={isPresentation}
        comparingUnitIds={comparingUnitIds || []}
        likedUnitIds={likedUnitIds || []}
      />
    </section>
  );
};

export default ComparePage;
