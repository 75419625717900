/** @format */

import ApiClient from "../apiClient";
import { defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(defaultFetcher);

const savePreference = (data) => client.post(endPoint.PREFERENCE_UPDATE, data);
const savePreferenceOfSession = (idPreference, data) =>
  client.put(`${endPoint.PREFERENCE_UPDATE}/${idPreference}`, data); //data: {unit: string}
const unSavePreferenceOfSession = (idPreference, data) =>
  client.put(`${endPoint.PREFERENCE_UPDATE}/${idPreference}`, data); // data: {removeUnit: string}

//data: {
// "amenity": <amenity_ID>  --> fav amenity
//   "removeAmenity": <amenity_ID>  --> unfav amenity
//     }
const toggleFavorite = (idPreference, data) =>
  client.put(`${endPoint.PREFERENCE_UPDATE}/${idPreference}`, data);

const toggleCustomerFavorite = (idPreference, data) =>
  client.put(endPoint.CUSTOMER_FAVORITE.replace(":id", idPreference), data);
///---------

const getCustomerPreference = (data) =>
  client.get(endPoint.PREFERENCE_CUSTOMER, data);
const getCustomerPreferenceV2 = (data) =>
  client.get(endPoint.PREFERENCE_CUSTOMER_V2, data);
const getCustomerPreferenceBySessionId = (preferenceId) =>
  client.get(
    endPoint.PREFERENCE_CUSTOMER_BY_SESSION_ID.replace(
      ":preferenceId",
      preferenceId
    )
  );
const unLikePreferenceOfSession = (preferenceId, data) =>
  client.put(`${endPoint.PREFERENCE_UPDATE}/${preferenceId}`, data);
const likePreferenceOfSession = (preferenceId, data) =>
  client.put(`${endPoint.PREFERENCE_UPDATE}/${preferenceId}`, data);

export default {
  savePreference,
  toggleFavorite,
  toggleCustomerFavorite,
  getCustomerPreference,
  getCustomerPreferenceV2,
  savePreferenceOfSession,
  unSavePreferenceOfSession,
  getCustomerPreferenceBySessionId,
  unLikePreferenceOfSession,
  likePreferenceOfSession,
};
