/** @format */
import React, { useEffect, useRef, useState } from "react";
import { Select } from "@headlessui/react";
import { useSelector } from "react-redux";
import { cn } from "../../../helper/utils";
import { formatMoney } from "../../../helper/unit";
import LoadingTable from "../configuration/components/Table/LoadingTable";
import EmptyTable from "../configuration/components/Table/EmptyTable";
import sessionApi from "../../../apis/api/session";
import dayjs from "dayjs";
import { PlusIcon, LinkSlashIcon, PaintBrushIcon, PencilSquareIcon, MinusIcon, XMarkIcon } from "@heroicons/react/24/solid";

const thCss =
  "text-left border whitespace-nowrap  overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

const ItemUnitData = ({
  data,
  expandAll,
  handleShowModalUnlink,
  handleGetListSession,
}) => {
  const [isLoading, setLoading] = useState(false);
  const agents = useSelector((state) => state.cms.agents);
  const agentsElement = document.getElementById(`id-agent-select-${data.id}`);
  const refAgentSeleted = useRef("empty");
  const [currentAgent, setcurrentAgent] = useState("");
  const [editing, setEditing] = useState(false);
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    if (data) {
      setcurrentAgent(
        data?.agent ? `${data?.agent?.firstname} ${data?.agent?.surname}` : ""
      );
    }
  }, [data]);

  useEffect(() => {
    setExpand(!expandAll);
  }, [expandAll]);

  const handleChangeSelect = () => {
    refAgentSeleted.current = agentsElement?.value;
    handleUpdateAgent();
  };

  const handleUpdateAgent = async () => {
    try {
      setLoading(true);
      const params = {
        agent: refAgentSeleted.current,
      };
      const res = await sessionApi.updateAgent(data.id, params);
      if (res && res?.data) {
        console.log("res.data", res.data);
        handleGetListSession();
      }
    } catch (e) {
      console.log("Error load list session", e);
    } finally {
      setLoading(false);
    }
  };

  const renderUnitList = (units) => {
    return (
      <table className="w-full border-collapse" id="unit-content-table">
        <thead>
          <tr className="text-sm font-semibold table-fixed">
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-300`}>
              RESIDENCE
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-300`}>
              AVAILABILITY
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-300`}>
              PRICE
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-300`}>
              FLOOR
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-300`}>
              SQ.FT.
            </th>
            <th className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-300`}>
              BEDS
            </th>
          </tr>
        </thead>
        {isLoading && (
          <tbody className="h-[120px]">
            <tr>
              <td colSpan="7" className="text-center py-4">
                <div className="flex justify-center items-center">
                  <LoadingTable />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!isLoading && units?.length === 0 && (
          <tbody className="h-[120px]">
            <tr>
              <td
                colSpan="7"
                className="text-center py-4 border border-t-0 border-gray-300"
              >
                <div className="flex justify-center items-center">
                  <EmptyTable className={'py-0'} />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        <tbody>
          {!isLoading &&
            units &&
            units.map((i) => {
              return (
                <tr
                  key={i.id}
                  className="text-sm transition-all lg:hover:bg-gray-50 cursor-grab"
                >
                  <td className={`${thCss}`}>{i?.name}</td>
                  <td className={`${thCss} `}>{i?.availabilityStatus}</td>
                  <td className={`${thCss} `}> {formatMoney(i?.price)} </td>
                  <td className={`${thCss} `}>{i?.level}</td>
                  <td className={`${thCss} `}>{i?.areaSqm}</td>
                  <td className={`${thCss} `}>{i?.bedrooms}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="mb-4">
      <div class="mx-auto max-w-screen">
        {expand
          ? <div
            class="divide-y divide-gray-300 overflow-hidden rounded-lg border-gray-300 border-2 bg-white shadow-sm">
            <div class="group">
              <div class="p-5 flex justify-between">
                <div>
                  <h3 class="text-xl font-bold text-gray-900">
                    <span className="flex gap-3">
                      <MinusIcon onClick={() => setExpand(false)} className="size-5 mt-1 text-green-800" />
                      {data?.name}
                      {
                        data?.isEnded &&
                        <span class="text-red-900 bg-red-100 border border-red-800 text-xs font-medium flex items-center px-3 rounded-xl leading-none">
                          Ended
                        </span>
                      }
                    </span>
                  </h3>
                  <p class="text-sm text-gray-500 mt-1">{dayjs(data?.createdAt).format('DD MMM YYYY - HH:mm')}</p>
                  <div class="space-y-1 mt-5 flex flex-col gap-2">
                    {editing
                      ? <div class="flex items-center gap-1 text-base text-gray-900">
                        <div>• <b>Agent</b>:</div>
                        <div className="flex flex-row items-center !border-b !border-b-green-800 gap-2">
                          <PaintBrushIcon className="size-4 text-green-800" />
                          <Select
                            className={cn(
                              "block !outline-none !border-none font-medium py-1 pl-2 pr-9 text-green-800 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis",
                              "*:text-black text-base"
                            )}
                            id={`id-agent-select-${data.id}`}
                            onChange={handleChangeSelect}
                          >
                            <option value="empty text-base">{currentAgent || <span className="opacity-60">Select Agent</span>}</option>
                            {agents?.length &&
                              agents.map((a) => (
                                <option
                                  key={a.id}
                                  value={a.id}
                                  className="opacity-75 text-black font-poppins text-base font-normal leading-normal"
                                >
                                  {a.firstname} {a.surname}
                                </option>
                              ))}
                          </Select>
                        </div>
                      </div>
                      : <div class="text-base text-gray-900">• <b>Agent</b>: {data?.agent?.name || 'Anonymous'}</div>
                    }


                    <div class="text-base text-gray-900">• <b>Customer</b>: {data?.customer?.name || 'Anonymous'}</div>
                  </div>
                </div>

                <div class="flex flex-col h-full gap-2">
                  {!!editing
                    ? <>
                      <button
                        onClick={() => setEditing(false)}
                        class="items-center flex justify-between gap-2 w-full text-xs font-medium p-2 px-3 rounded-xl transition-colors border border-red-800 bg-red-100 text-red-800"
                      >
                        <span className="leading-none">Exit</span> <XMarkIcon className="size-3" />
                      </button>
                    </>
                    : <>
                      <button
                        onClick={() => setEditing(true)}
                        class="items-center flex justify-between gap-2 w-full text-xs font-medium p-2 px-3 rounded-xl transition-colors border border-green-800 bg-green-100 text-green-800"
                      >
                        <span className="leading-none">Edit mode</span> <PencilSquareIcon className="size-3" />
                      </button>
                      <button
                        onClick={() => handleShowModalUnlink(data)}
                        class="items-center flex justify-between gap-2 w-full text-xs font-medium p-2 px-3 rounded-xl transition-colors border border-gray-800 bg-gray-100 text-gray-800"
                      >
                        <span className="leading-none">Unlink</span> <LinkSlashIcon className="size-3" />
                      </button>
                    </>
                  }
                </div>
              </div>
            </div>
            <details class="group" open={!!data?.preference?.units?.length}>
              <summary
                class="flex cursor-pointer list-none items-center justify-between py-3 px-4 text-base font-bold text-black bg-gray-50 group-open:bg-gray-100">
                <span>
                  Favorites
                  {data?.preference?.units?.length
                    ? <span class="ml-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-700/10 ring-inset">
                      {data?.preference?.units?.length} Items
                    </span>
                    : null
                  }
                </span>
                <div class="text-black">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="block h-5 w-5 transition-all duration-300 group-open:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
              </summary>
              <div class="border-t border-t-stone-100 p-0 text-black">
                {renderUnitList(data?.preference?.units || [])}
              </div>
            </details>
            <details class="group">
              <summary
                class="flex cursor-pointer list-none items-center justify-between py-3 px-4 text-base font-bold text-black bg-gray-50 group-open:bg-gray-100 ">
                <span>
                  Shortlist
                  {data?.preference?.unitsShortlist?.length
                    ? <span class="ml-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-700/10 ring-inset">
                      {data?.preference?.unitsShortlist?.length} Items
                    </span>
                    : null
                  }
                </span>
                <div class="text-black">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="block h-5 w-5 transition-all duration-300 group-open:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
              </summary>
              <div class="border-t border-t-stone-100 p-0 text-black">
                {renderUnitList(data?.preference?.unitsShortlist || [])}
              </div>
            </details>
            <details class="group">
              <summary
                class="flex cursor-pointer list-none items-center justify-between py-3 px-4 text-base font-bold text-black bg-gray-50 group-open:bg-gray-100 ">
                <span>
                  Amenities
                  {data?.preference?.amenities?.length
                    ? <span class="ml-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-700/10 ring-inset">
                      {data?.preference?.amenities?.length} Items
                    </span>
                    : null
                  }
                </span>
                <div class="text-black">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="block h-5 w-5 transition-all duration-300 group-open:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
              </summary>
              <div class="border-t border-t-stone-100 p-0 text-black">
                {renderUnitList(data?.preference?.amenities || [])}
              </div>
            </details>
            <details class="group">
              <summary
                class="flex cursor-pointer list-none items-center justify-between py-3 px-4 text-base font-bold text-black bg-gray-50 group-open:bg-gray-100 ">
                <span>
                  Comparing Units
                  {data?.preference?.comparingUnits?.length
                    ? <span class="ml-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-700/10 ring-inset">
                      {data?.preference?.comparingUnits?.length} Items
                    </span>
                    : null
                  }
                </span>
                <div class="text-black">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="block h-5 w-5 transition-all duration-300 group-open:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
              </summary>
              <div class="border-t border-t-stone-100 p-0 text-black">
                {renderUnitList(data?.preference?.comparingUnits || [])}
              </div>
            </details>
          </div>
          : <div
            class="divide-y divide-gray-300 overflow-hidden rounded-lg border-gray-300 border-2 bg-white shadow-sm">
            <div class="group">
              <div class="p-5 flex justify-between">
                <div>
                  <h3 class="text-xl font-bold text-gray-900">
                    <span className="flex gap-3">
                      <PlusIcon onClick={() => setExpand(true)} className="size-5 mt-1 text-green-800" />
                      {data?.name}
                      {
                        data?.isEnded &&
                        <span class="text-red-900 bg-red-100 border border-red-800 text-xs font-medium flex items-center px-3 rounded-xl leading-none">
                          Ended
                        </span>
                      }
                    </span>
                  </h3>
                  <p class="text-sm text-gray-500 mt-1">{dayjs(data?.createdAt).format('DD MMM YYYY - HH:mm')}</p>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ItemUnitData;
