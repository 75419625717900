import React from "react";
import {
  ArrowRightIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/16/solid";

import { Button, Modal } from "../../../components/commons";
import DeleteVt from "./deleteVt";
import EditVt from "./EditVt";
import { hasDataChanged } from "../../utils";

const MultipleAction = ({
  virtualTourRes,
  virtualTourData,
  onSave,
  setPreview,
}) => {
  const [loading, setLoading] = React.useState(false);
  const disabledSave = !hasDataChanged(virtualTourRes?.data, virtualTourData);

  return (
    <div className="mb-5 border rounded border-gray-400 p-2">
      <div className="flex gap-2 mb-2 flex-wrap">
        <h2 className="flex-[0_0_100%] font-medium mb-2 break-words">
          {virtualTourRes?.name || "Virtual tour"}
        </h2>
        <Button
          disabled={!virtualTourData?.scenes?.length}
          className="flex-1 flex-row-reverse"
          icon={<ArrowRightIcon width={20} height={20} />}
          onClick={() => setPreview(true)}
        >
          Preview
        </Button>
      </div>
      <div className="flex gap-2">
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          maskClosable
          trigger={
            <Button
              variant="icon"
              className="text-red-500 bg-red-500 text-white hover:bg-red-400"
              icon={<TrashIcon width={20} height={20} />}
            />
          }
          content={({ setIsShow }) => {
            return <DeleteVt setIsShow={setIsShow} />;
          }}
        />
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          maskClosable
          title="Edit virtual tour"
          trigger={
            <Button
              icon={<PencilIcon width={20} height={20} />}
              variant="icon"
              className="bg-blue-500 text-white hover:bg-blue-400"
            />
          }
          content={({ setIsShow }) => {
            return (
              <EditVt
                onClose={() => setIsShow(false)}
                defaultVtName={virtualTourRes?.name}
              />
            );
          }}
        />
        <Button
          className="flex-1"
          disabled={disabledSave}
          isLoading={loading}
          onClick={() => onSave({ onPending: setLoading })}
        >
          {!loading && "Save"}
        </Button>
      </div>
    </div>
  );
};

export default MultipleAction;
