import React, { useEffect } from "react";
import { useController } from "react-hook-form";

import _ from "lodash";
import { cn } from "../../../../helper/utils";

const CheckboxGroupField = (props, ref) => {
  const {
    controller,
    label,
    labelClassName,
    isRequired = false,
    options,
    inputWrapperClassName,
    optionsWrapperClassName,
    callbackOnchange,
    customLabel,
    ...rest
  } = props;

  const { fieldState, field } = useController(controller);
  const { error } = fieldState;

  useEffect(() => {
    rest.defaultValue && field.onChange(rest.defaultValue);
  }, [rest.defaultValue]);

  const onChange = (v, checked) => {
    const newValue = typeof field.value !== "undefined" ? [...field.value] : [];

    if (checked) {
      const index = newValue.indexOf(v);
      if (index === -1) {
        newValue.push(v);
      }
    } else {
      const index = newValue.indexOf(v);
      if (index !== -1) {
        newValue.splice(index, 1);
      }
    }
    field.onChange(newValue);
    callbackOnchange && callbackOnchange(newValue, v);
  };

  return (
    <div>
      {label && (
        <legend
          className={cn(
            "text-gray-800 mb-2 text-sm font-medium",
            labelClassName
          )}
        >
          {label} {isRequired && <span className="text-red-500">*</span>}
        </legend>
      )}
      <div className={optionsWrapperClassName}>
        {options.map((v) => (
          <div
            key={`${v.value}-${v.label}`}
            className={cn(
              "w-full cursor-pointer flex items-center gap-2",
              inputWrapperClassName
            )}
          >
            <input
              type="checkbox"
              {...field}
              id={v.value}
              checked={field.value ? field.value?.includes(v.value) : false}
              onChange={(e) => onChange(v.value, e.target.checked)}
              disabled={v.disabled}
              className={cn(
                "h-4 w-4 rounded cursor-pointer  text-green-300  border border-gray-300  focus:ring-transparent peer-checked:ring-green-300 peer-checked:bg-green-300",
                rest.inputClassName,
                {
                  "border !border-red-700 border-solid focus:!border-red-700 focus:ring-red-50":
                    error,
                }
              )}
            />
            {customLabel ? (
              customLabel(v)
            ) : (
              <label
                className={cn("cursor-pointer", rest?.labelInputClassName)}
                htmlFor={v.value}
              >
                {v.label}
              </label>
            )}
          </div>
        ))}
      </div>

      {error && (
        <div>
          <p className={error ? "text-red-500 text-xs" : ""}>
            {_.get(error, "message")}
          </p>
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(CheckboxGroupField);
