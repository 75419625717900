import * as Types from "./type";
import sessionApi from "../../apis/api/session";
import { groupBy } from "lodash";

export const actSetCompareHistory = (data) => ({
  type: Types.COMPARE_HISTORY,
  data,
});

export const reqGetHistoryCompare = (preferenceId, query) => (dispatch) => {
  return sessionApi.getHistoryCompare(preferenceId, query).then((data) => {
    const comparingUnits = data?.data?.comparingUnits;
    if (comparingUnits?.length) {
      const convertCompare = Object.entries(groupBy(comparingUnits, "level"))
        .sort(([levelA], [levelB]) => Number(levelA) - Number(levelB))
        .reduce((acc, [level, items]) => {
          acc[`floor-${level}`] = items.map((item) => item.id);
          return acc;
        }, {});

      dispatch(actSetCompareHistory(convertCompare));
    }
  });
};

export const updateHistoryCompare = (preferenceId, data) => (dispatch) => {
  return sessionApi.updateHistoryCompare(preferenceId, data).then((data) => {
    // dispatch(actSetCompareHistory(data));
  });
};
