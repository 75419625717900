import React, { useEffect, useState } from "react";
import { cn } from "../../../helper/utils";

const CmsPageHeader = (props) => {
  const { title, titleClassName } = props;

  return (
    <div
      className={cn(
        "page-header bg-black flex justify-center items-center pt-[36px] pb-[50px] h-[131px]",
        props?.wrapperClassName
      )}
    >
      <h1
        className={cn(
          "font-poppins font-bold text-[30px] leading-[45px] uppercase text-center text-white mb-0",
          titleClassName
        )}
      >
        {title}
      </h1>
    </div>
  );
};

export default CmsPageHeader;
