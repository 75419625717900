/** @format */
import { useEffect } from "react";
import {
  ActiveStarIcon,
  InActiveStarIcon,
} from "../../../components/svgs/icons";
import { ReactComponent as Info } from "../../../assets/images/compare/icInfo.svg";
import { ReactComponent as Close } from "../../../assets/images/compare/icClose.svg";
import { ReactComponent as Like } from "../../../assets/images/compare/icLike.svg";
import { ReactComponent as UnLike } from "../../../assets/images/compare/icUnLike.svg";
import { useState, useRef } from "react";
import { getS3BEMediaUrl } from "../../../helper/media";
import ItemDetail from "./ItemDetail";
import customerApi from "../../../apis/api/customer";
import preferenceApi from "../../../apis/api/preference";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../../hooks/useQuery";
import { reqGetSession } from "../../../reduxs/precinct-explore/action";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useQuery as useQueryHook } from "@tanstack/react-query";
import unitApi from "../../../apis/api/unit";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { reqGetCustomerProfile } from "../../../reduxs/user/action";

const ItemCompare = ({
  height,
  onRemove,
  item,
  preferenceUnitIds,
  idPreference,
  isPresentation,
  comparingUnitIds,
  likedUnitIds,
}) => {
  const customer = useSelector((state) => state.user.customer);
  const session = useSelector((state) => state.precinctExplore.session);

  const [isShowInfo, setShowInfo] = useState(false);
  const { query } = useQuery();
  const dispatch = useDispatch();
  const sessionId = query?.session;

  const { data: medias } = useQueryHook({
    queryKey: ["unit-media", item.id],
    queryFn: async () => (await unitApi.getUnitMedia(item?.id))?.data,
  });

  const transformComponentRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action == ACTION_NAME.COMPARE_SAVE_PREFERENCE) {
      getSession();
    }
    if (content.action == ACTION_NAME.COMPARE_UN_SAVE_PREFERENCE) {
      getSession();
    }
    if (content.action === ACTION_NAME.COMPARE_SHOW_INFO) {
      setShowInfo(true);
    }
    if (content.action === ACTION_NAME.COMPARE_HIDE_INFO) {
      setShowInfo(false);
    }
    if (content.action === ACTION_NAME.COMPARE_REMOVE) {
      handleRemove();
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const getSession = () => {
    dispatch(reqGetSession(sessionId));
  };

  const handleSavePreference = async () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.COMPARE_SAVE_PREFERENCE);
    }
    if (isFavorited) return handleUnsavePreference();
    const res = await preferenceApi?.savePreferenceOfSession(idPreference, {
      unit: item?.id,
    });

    if (res?.data) {
      dispatch(reqGetSession(sessionId));
    }

    if (customer && customer?.favorite?.id) {
      const resCustomerFav = await customerApi?.updateCustomerFavorite(
        customer?.favorite?.id,
        { unit: item?.id },
      );

      if (resCustomerFav?.data) {
        dispatch(reqGetCustomerProfile(customer.id));
      }
    }
  };

  const handleLike = async () => {
    if (isLiked) return handleUnLike();
    const res = await preferenceApi?.likePreferenceOfSession(session?.preference?.id, {
      addComparingUnits: [item?.id],
      likeUnit: item?.id,
    });
    if (res?.data) {
      dispatch(reqGetSession(sessionId));
      if (customer && customer?.favorite?.id) {
        const resCustomerFav = await customerApi?.updateCustomerFavorite(
          customer?.favorite?.id,
          { likeUnit: item?.id },
        );
        if (resCustomerFav?.data) {
          dispatch(reqGetCustomerProfile(customer.id));
        }
      }
    }
  };

  const handleUnLike = async () => {
    const res = await preferenceApi?.unLikePreferenceOfSession(session?.preference?.id, {
      removeComparingUnits: [item?.id],
      unlikeUnit: item?.id,
    });
    if (res?.data) {
      dispatch(reqGetSession(sessionId));
      if (customer && customer?.favorite?.id) {
        const resCustomerFav = await customerApi?.updateCustomerFavorite(
          customer?.favorite?.id,
          { unlikeUnit: item?.id },
        );
        if (resCustomerFav?.data) {
          dispatch(reqGetCustomerProfile(customer.id));
        }
      }
    }
  };

  const handleUnsavePreference = async () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.COMPARE_UN_SAVE_PREFERENCE);
    }
    const res = await preferenceApi?.unSavePreferenceOfSession(idPreference, {
      removeUnit: item?.id,
    });

    if (res?.data) {
      dispatch(reqGetSession(sessionId));
    }

    if (customer && customer?.favorite?.id) {
      const resCustomerFav = await customerApi?.updateCustomerFavorite(customer?.favorite?.id, {
        removeUnit: item?.id,
      });

      if (resCustomerFav?.data) {
        dispatch(reqGetCustomerProfile(customer.id));
      }
    }
  };

  const handleShowInfo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.COMPARE_SHOW_INFO);
    }
    setShowInfo(true);
  };
  const handleHideInfo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.COMPARE_HIDE_INFO);
    }
    setShowInfo(false);
  };

  const handleRemove = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.COMPARE_REMOVE);
    }
    onRemove();
  };

  const handleZoomIn = () => {
    transformComponentRef.current.zoomIn();
  };

  const handleZoomOut = () => {
    transformComponentRef.current.zoomOut();
  };

  const urlImg = medias?.floorplans?.[0]?.path;
  const isFavorited = preferenceUnitIds.includes(item.id);
  const isLiked = likedUnitIds.includes(item.id);
  return (
    <div
      className="item-compare relative p-5 border border-solid border-[#24242226] overflow-hidden"
      style={{ height: height }}
    >
      <div className="absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-8 cursor-pointer bg-gray-200 p-2 rounded z-10">
        <span>
          {item.name.includes("Penthouse") || item.name.includes("Bayhouse")
            ? item.name
            : `${item.name}`}
        </span>
      </div>
      <TransformWrapper
        ref={transformComponentRef}
        maxScale={5}
        // minPositionX={window.screen.width * 1.55 * -1}
        // minPositionY={window.screen.height * 1.7 * -1}
        // maxPositionX={window.screen.width * 1.55}
        // maxPositionY={window.screen.height * 1.7}
        panning={{
          // allowLeftClickPan: false,
          allowMiddleClickPan: false,
          allowRightClickPan: false,
        }}
        doubleClick={{
          disabled: false,
        }}
      >
        <TransformComponent
          wrapperClass="!w-full !h-full"
          className="cursor-zoom-in"
          contentClass="!w-full !h-full"
        >
          {urlImg ? (
            <img
              src={getS3BEMediaUrl(urlImg)}
              alt="floorplan"
              className="w-full h-full object-contain"
            />
          ) : (
            <div className="h-full w-full !flex items-center justify-center">
              Image Not Found
            </div>
          )}
        </TransformComponent>
      </TransformWrapper>
      <div className="action absolute top-8 right-8 flex flex-col gap-2 z-10">
        <div
          onClick={handleSavePreference}
          className="cursor-pointer h-[45px] w-[45px] rounded-full flex flex-col justify-center items-center self-stretch px-[22.5px] py-0 bg-black"
        >
          {isFavorited ? (
            <ActiveStarIcon className="*:fill-[var(--navigation-text-color)]" />
          ) : (
            <InActiveStarIcon className="*:fill-[var(--navigation-text-color)]" />
          )}
        </div>
        <div className="cursor-pointer" onClick={handleLike}>
          {isLiked ? <Like /> : <UnLike />}
        </div>
        <div className="cursor-pointer" onClick={handleShowInfo}>
          <Info />
        </div>
        <div className="cursor-pointer" onClick={handleRemove}>
          <Close />
        </div>
      </div>
      {isShowInfo && (
        <div className="absolute top-[135px] right-8 z-50">
          <ItemDetail onclose={handleHideInfo} item={item} />
        </div>
      )}
      {urlImg && (
        <div className="action absolute bottom-8 right-8 flex flex-col gap-2 z-10 w-12">
          <img
            className="h-full cursor-pointer"
            src="/icons/plus.svg"
            alt=""
            onClick={handleZoomIn}
          />
          <img
            className="h-full cursor-pointer"
            src="/icons/minus.svg"
            alt=""
            onClick={handleZoomOut}
          />
        </div>
      )}
    </div>
  );
};

export default ItemCompare;
