/** @format */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingTable from "../../../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../../../configuration/components/Table/EmptyTable";
import unitApi from "../../../../../../apis/api/unit";
import { getS3BEMediaUrl } from "../../../../../../helper/media";
import { Button, Modal } from "../../../../components/commons";
import defaultVideo from "../../../../../../assets/images/cms/icVideo.webp";
import defaultImage from "../../../../../../assets/images/cms/icImg.webp";

const MediasContent = ({ isLoadingTable, medias, mediaType, unitId }, ref) => {
  const [mediasState, setMediasState] = useState(medias);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    setMediasState(medias);
  }, [medias]);

  const onDragEnd = async (result) => {};

  //expose onDragEnd for parent component to trigger
  useImperativeHandle(ref, () => ({
    triggerDragEnd: onDragEnd,
  }));

  const handleRemoveMedia = async (media, index, callBack) => {
    try {
      setRemoveLoading(true);
      const param = {
        mediaId: media?.id,
        mediaType: mediaType, // images, floorplans, viewlines, 360image
      };
      unitApi.removeMediaUnit(unitId, param).then((res) => {
        if (res && res?.data) {
          const arr = [...mediasState];
          arr.splice(index, 1);
          setMediasState(arr);
          toast.success("Remove media successfully!");
          callBack();
        }
      });
    } catch (e) {
      console.log("Error remove media", e);
      toast.error("Remove media failed!");
      callBack();
    }
  };

  return (
    <>
      {!isLoadingTable && (
        <Droppable droppableId="gallery-list">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {!!mediasState?.length &&
                mediasState?.map((v, index) => {
                  return (
                    <Draggable key={v?.id} draggableId={v?.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          key={v?.id}
                          className={`flex items-center rounded gap-5 bg-gray-100 mb-2 p-2`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="flex-1">
                            <div className="flex gap-5">
                              <div>
                                <LazyLoadImage
                                  className="w-16 h-16 rounded-lg m-auto object-cover"
                                  alt={v?.name}
                                  src={getS3BEMediaUrl(v?.thumbnailPath)}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      v?.type === "videos"
                                        ? defaultVideo
                                        : defaultImage;
                                  }}
                                />
                              </div>
                              <div className="flex-1 min-w-0">
                                <div>{v?.type}</div>
                                <div className="break-words overflow-wrap-anywhere">
                                  {v?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Modal
                            maskClassName="bg-opacity-50"
                            zIndex="z-[9999]"
                            trigger={
                              <div className="flex gap-5 items-center">
                                <span className="underline text-sm">
                                  Remove
                                </span>
                              </div>
                            }
                            content={({ setIsShow }) => (
                              <div>
                                <p className="text-center text-xl">
                                  Do you want to remove this media from playlist
                                  ?
                                </p>
                                <div className="flex gap-4 items-center mt-5">
                                  <Button
                                    disabled={removeLoading}
                                    variant="text"
                                    className="flex-1"
                                    onClick={() => setIsShow(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    isLoading={removeLoading}
                                    className="flex-1 bg-red-500 hover:bg-red-400"
                                    onClick={() =>
                                      handleRemoveMedia(v, index, () => {
                                        setIsShow(false);
                                        setRemoveLoading(false);
                                      })
                                    }
                                  >
                                    Confirm
                                  </Button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

              {(!mediasState || !mediasState?.length) && (
                <div className="flex justify-center items-center h-full">
                  <EmptyTable />
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      {isLoadingTable && (
        <div className="flex justify-center items-center h-full">
          <LoadingTable />
        </div>
      )}
    </>
  );
};

export default forwardRef(MediasContent);
