import React, { useCallback, useMemo, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { useDispatch } from "react-redux";
import { differenceBy } from "lodash";
import { toast } from "react-toastify";

import { SpinLoading } from "../../../../../components/svgs/icons";
import EmptyTable from "../../../configuration/components/Table/EmptyTable";
import { Button } from "../../../components/commons";
import { useMediaSearch } from "./useMediaSearch";
import MediaItem from "./MediaItem";
import { initialData } from "../../index";
import virtualTourApi from "../../../../../apis/api/virtual-tour";
import { reqGetVirtualTour } from "../../../../../reduxs/virtual-tour/action";
import useQuery from "../../../../../hooks/useQuery";
import { getS3BEMediaUrl } from "../../../../../helper/media";

const AssetVT = ({ vTDefault, setIsShow }) => {
  const dispatch = useDispatch();

  const [selectedVT, setSelectedVT] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const { query } = useQuery();
  const { search, loading, error, medias, refetch, handleSearch } =
    useMediaSearch();

  const newMedias = useMemo(() => {
    const scenesWithId = vTDefault?.scenes.map((scene) => ({
      id: scene.mediaId,
    }));

    return differenceBy(medias, scenesWithId, "id");
  }, [vTDefault?.scenes, medias]);

  const toggleSelect = useCallback((media) => {
    setSelectedVT((prevVTs) => {
      if (prevVTs.some((v) => v?.id === media?.id)) {
        return prevVTs.filter((v) => v?.id !== media?.id);
      } else {
        return [...prevVTs, media];
      }
    });
  }, []);

  const handleSave = useCallback(async () => {
    setSaveLoading(true);
    const defaultScenes = vTDefault?.scenes?.length
      ? [...vTDefault?.scenes]
      : [];

    const updatedData = {
      data: {
        scenes: [
          ...defaultScenes,
          ...selectedVT.map((media, index) => ({
            mediaId: media?.id,
            id: `scene-${defaultScenes.length + index + 1}`,
            name: `Scene ${defaultScenes.length + index + 1}`,
            url: `${getS3BEMediaUrl(media?.path)}`,
            levels: [
              { tileSize: 256, size: 256, fallbackOnly: true },
              { tileSize: 512, size: 512 },
              { tileSize: 1024, size: 1024 },
            ],
            faceSize: 4096,
            initialViewParameters: {
              pitch: 0,
              yaw: 0,
              fov: Math.PI / 3,
            },
            linkHotspots: [],
            infoHotspots: [],
          })),
        ],
        settings: vTDefault?.settings || initialData.settings,
      },
    };

    const addMediaToVTourRes = await virtualTourApi.updateVirtualTour(
      query?.id,
      updatedData
    );

    if (addMediaToVTourRes.data) {
      toast.success("Add media to virtual tour successfully");
      await dispatch(reqGetVirtualTour(query?.id));
      setSaveLoading(false);

      setIsShow && setIsShow(false);
    } else {
      toast.error("Add media to virtual tour failed");
    }
    setSaveLoading(false);
  }, [selectedVT]);

  return (
    <div>
      <h2 className="font-medium font-poppins font-semibold">
        Virtual Tour In Asset
      </h2>
      <p>You can select multiple virtual tour</p>

      {/* Search Input */}
      <div className="relative my-5">
        <input
          type="text"
          value={search}
          onChange={handleSearch}
          placeholder="Search..."
          className="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
        </div>
      </div>

      {/* Loading State */}
      {loading && (
        <div className="flex justify-center items-center h-[500px]">
          <SpinLoading className="w-[50px] h-[50px]" />
        </div>
      )}

      {/* Error State */}
      {error && (
        <div className="flex justify-center items-center h-[500px] text-red-500">
          {error}
          <Button onClick={refetch}>Retry</Button>
        </div>
      )}

      {/* Media Grid */}
      {!loading && !error && !!newMedias?.length && (
        <div className="grid grid-cols-3 gap-5 border border-gray-500 p-2 rounded h-[500px] overflow-y-auto">
          {newMedias.map((media) => (
            <MediaItem
              key={media.id}
              media={media}
              selected={selectedVT.some((v) => v?.id === media?.id)}
              onSelect={toggleSelect}
            />
          ))}
        </div>
      )}

      {/* Empty State */}
      {!loading && !error && !medias?.length && (
        <div className="flex justify-center items-center h-[500px] border border-gray-500 rounded">
          <div>
            <EmptyTable>
              <h2 className="font-semibold">No virtual tour</h2>
            </EmptyTable>
          </div>
        </div>
      )}

      {/* Actions */}
      <div className="mt-10 flex gap-5">
        <Button
          disabled={saveLoading}
          variant="text"
          className="flex-1 py-3"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          isLoading={saveLoading}
          className="flex-1 py-3"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AssetVT;
