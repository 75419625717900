import * as type from "./type";

const initialState = {
  isShowPrecinctExploreDetail: false,
  customerPreference: null,
  session: null,
  isShowReplayVideo: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_IS_SHOW_PRECINCT_EXPLORE_DETAIL:
      return {
        ...state,
        isShowPrecinctExploreDetail: action.data,
      };
    case type.GET_CUSTOMER_PREFERENCE:
      return {
        ...state,
        customerPreference: action.data.data,
      };
    case type.GET_CUSTOMER_PREFERENCE_V2:
      return {
        ...state,
        customerPreference: action.data.data.preferences,
      };
    case type.GET_SESSION:
      return {
        ...state,
        session: action.data.data,
      };
    case type.SET_IS_SHOW_REPLAY_VIDEO:
      return {
        ...state,
        isShowReplayVideo: action.data,
      };
    default:
      return state;
  }
};
