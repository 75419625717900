import React from "react";
import { getS3BEMediaUrl } from "../../helper/media";
import { COMPONENTS_ENUM } from "../../constants/modules";
import { formatMoney, numberWithCommas } from "../../helper/unit";
import { AVAILABILITY_STATUS_ENUM } from "../../constants/master-data";

const PreferencesList = ({ preferences, handleOpenFloorplan, components = [] }) => {
  return (
    <div className="preferences-list">
      {preferences?.units?.length &&
        preferences.units.map((item, i) => (
          <div
            className="w-screen flex justify-center bg-white border-t-[1px] border-[rgba(36, 36, 34, 0.15)]"
            key={i}
          >
            <div
              className={`flex items-start w-[1366px] gap-[40px] px-[109px] py-[40px] ${i % 2 === 1 ? "flex-row-reverse" : ""
                }`}
            >
              <div className="residence-info flex w-[534px] min-h-[534px] flex-col gap-[40px] items-start px-0 py-[40px] shrink-0">
                {
                  components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_NAME) &&
                  <div className="flex flex-col gap-[32px] text-left text-[52px] font-[400] font-domaine text-[#242422] leading-[95%] tracking-[-0.52px] uppercase ">
                    {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_NAME)?.props?.label || 'RESIDENCE'}
                    <br />
                    {item.name}
                  </div>
                }
                <div className="flex flex-col gap-[32px] w-[300px] mb-0 ml-0 items-start list-none">
                  <div className="flex flex-col gap-[15px] items-start self-stretch">
                    {
                      (
                        components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_STATUS) &&
                        item.bedrooms
                      ) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_STATUS)?.props?.label || 'Status'} — {item?.availabilityStatus ? AVAILABILITY_STATUS_ENUM[String(item.availabilityStatus).toUpperCase()] : ''}
                        </div>
                      )
                    }
                    {
                      (
                        components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_BEDROOM) &&
                        item.bedrooms
                      ) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_BEDROOM)?.props?.label || 'Bedrooms'} — {`${item.bedrooms}`}
                        </div>
                      )
                    }
                    {
                      (
                        components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_BATHROOM) &&
                        item.bathrooms
                      ) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_BATHROOM)?.props?.label || 'Bathrooms'} — {`${item.bathrooms}`}
                        </div>
                      )
                    }
                    {
                      (
                        components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_PRICE) &&
                        item.price
                      ) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_PRICE)?.props?.label || 'Price'} — {formatMoney(item.price)}
                        </div>
                      )
                    }
                    {
                      (components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_SIZE)) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_SIZE)?.props?.label || 'Lot Size'} — {numberWithCommas(item.areaSqm)} SF /{" "}
                          {Math.round(item.areaSqm * 0.092903)} SM
                        </div>
                      )
                    }
                    {
                      (components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_SIZE_INTERIOR)) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_SIZE_INTERIOR)?.props?.label || 'Interior'} — {numberWithCommas(item.interier)} SF /{" "}
                          {Math.round(item.interior * 0.092903)} SM
                        </div>
                      )
                    }
                    {
                      (components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_SIZE_EXTERIOR)) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          {components?.find(c => c.name === COMPONENTS_ENUM.PREFERENCE_SIZE_EXTERIOR)?.props?.label || 'Exterior'} — {numberWithCommas(item.exterior)} SF /{" "}
                          {Math.round(item.exterior * 0.092903)} SM
                        </div>
                      )
                    }
                    {
                      (
                        components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_POOL) &&
                        item.hasPool
                      ) && (
                        <div className="text-black pl-[33px] -indent-[33px] font-suisse text-base font-light leading-6 flex-1 relative before:content-['-'] before:pr-3 before:text-[rgba(36,36,34,0.70)] before:font-suisse before:text-xs before:font-normal before:leading-normal before:tracking-[1.2px] before:uppercase">
                          Private Pool
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="h-[682.569px] border-l-[1px] border-[rgba(36,36,34,0.15)]" />
              {
                components?.some(c => c.name === COMPONENTS_ENUM.PREFERENCE_FLOORPLAN) &&
                item?.medias?.find(m => ['floorplans'].includes(m.type)) && (
                  <img
                    className="w-[534px] h-[682.569px] cursor-pointer shrink-0 object-contain"
                    onClick={() =>
                      handleOpenFloorplan(item?.medias?.find(m => ['floorplans'].includes(m.type))?.path)
                    }
                    alt="imgFloorplan"
                    src={getS3BEMediaUrl(`${item?.medias?.find(m => ['floorplans'].includes(m.type))?.path}`)}
                  />
                )
              }
            </div>
          </div>
        ))}
    </div>
  );
};

export default PreferencesList;
