import ApiClient from "../apiClient";
import { defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const defaultClient = new ApiClient(defaultFetcher);

const getVirtualTours = (query) =>
  defaultClient.get(endPoint.VIRTUAL_TOURS, query);

const getVirtualTour = (id) =>
  defaultClient.get(`${endPoint.VIRTUAL_TOURS}/${id}`);

const updateVirtualTour = (id, data) =>
  defaultClient.put(`${endPoint.VIRTUAL_TOURS}/${id}`, data);

const deleteVirtualTour = (id) =>
  defaultClient.delete(`${endPoint.VIRTUAL_TOURS}/${id}`);

const createVirtualTour = (data) =>
  defaultClient.post(endPoint.VIRTUAL_TOURS, data);

export default {
  getVirtualTours,
  getVirtualTour,
  updateVirtualTour,
  deleteVirtualTour,
  createVirtualTour,
};
