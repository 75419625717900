import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { InputField } from "../../../cms/components/commons";

const PriceForm = ({ control, data, setValue }) => {
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );

  const value = useMemo(() => data?.subComponents[0]?.props, [data]);

  useEffect(() => {
    if (filterUnitPrice) {
      filterUnitPrice?.min && setValue("minPrice", filterUnitPrice?.min);
      filterUnitPrice?.max && setValue("maxPrice", filterUnitPrice?.max);
    }
  }, [filterUnitPrice]);

  return (
    <div className="py-5">
      <h2 className="font-normal mb-4 text-primary font-sans text-[14px] leading-[12px] tracking-[1.4px] uppercase mb-0">
        price range
      </h2>
      <InputField
        controller={{
          control,
          name: "minPrice",
        }}
        inputClassName="border-none"
        labelClassName="px-2 pt-2 uppercase mb-1"
        wrapperClassName="border border-gray-500 w-full mb-4"
        inputProps={{
          type: "number",
          placeholder: value?.min,
        }}
        label="Min"
      />
      <InputField
        controller={{
          control,
          name: "maxPrice",
        }}
        inputClassName="border-none"
        labelClassName="px-2 pt-2 uppercase mb-1"
        wrapperClassName="border border-gray-500 w-full"
        inputProps={{
          type: "number",
          placeholder: value?.max,
        }}
        label="Max"
      />
    </div>
  );
};

export default PriceForm;
