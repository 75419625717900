import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InputField } from "../../../../components/commons";
import SelectField from "../../../../configuration/components/selectField";

const LinkForm = ({ setIsShow, onCreateHotspot, scenes }) => {
  const schema = yup.object().shape({
    link: yup.object().nullable().required("Link is required"),
  });
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      link: {},
    },
    resolver: yupResolver(schema),
  });
  const options = scenes.map((scene) => ({
    id: scene.id,
    name: "Scene # " + scene?.mediaId?.slice(-4),
  }));

  const onSubmit = (formValues) => {
    onCreateHotspot({
      type: "link",
      targetScene: formValues.link?.id,
    });
    setIsShow(false);
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-5">Info link setting</h2>
      <div className="mb-5">
        <SelectField
          controller={{
            control,
            name: "link",
          }}
          options={options}
          placeholder="Select link"
        />
      </div>
      <div className="flex gap-5">
        <Button
          onClick={() => setIsShow(false)}
          disabled={formState.isSubmitting}
          className="flex-1"
          variant="text"
        >
          Cancel
        </Button>
        <Button
          disabled={!formState.isDirty || formState.isSubmitting}
          isLoading={formState.isSubmitting}
          className="flex-1"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default LinkForm;
