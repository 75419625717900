/** @format */

import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import customersAPI from "../../../apis/api/customer";
import { useSelector } from "react-redux";
import ChevronDown from "../../../assets/images/chevron-down.svg";
import { cn } from "../../../helper/utils";
import { Modal } from "../components/commons";

const validationSchema = yup.object().shape({
  firstname: yup.string().trim().required("First name is a required field"),
  surname: yup.string().trim().required("Last name is a required field"),
  email: yup
    .string()
    .trim()
    .email("Invalid email format")
    .required("Email is a required field"),
});

const EditCustomerModal = ({ customer, show, setShow, onSaveSuccess }) => {
  const [firstname, setFirstName] = useState("");
  const [surname, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [agent, setAgent] = useState("");
  const [isShowDeleteCustomerModal, setIsShowDeleteCustomerModal] =
    useState(false);
  const [errors, setErrors] = useState({});

  const agentList = useSelector((state) => state.cms.agents);

  useEffect(() => {
    if (customer) {
      setFirstName(customer.firstname || "");
      setSurName(customer.surname || "");
      setEmail(customer.email || "");
      setAgent(customer?.agent?.id || agentList?.[0]?.id);
    }
  }, [customer]);

  const handleUpdateCustomer = async () => {
    if (!customer) return;

    try {
      const data = {
        firstname,
        surname,
        email,
        agent,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });
      const res = await customersAPI.updateCustomer(customer.id, result);
      if (res.data) {
        toast.success("Customer updated successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleDeleteCustomer = async () => {
    if (!customer) return;

    try {
      const res = await customersAPI.deleteCustomer(customer.id);
      if (res.data) {
        toast.success("Customer deleted successfully!");
        handleCloseDeleteCustomerModal();
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      setErrors(err);
      toast.error(err.message);
    }
  };

  const handleOpenDeleteCustomerModal = () => {
    setIsShowDeleteCustomerModal(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseDeleteCustomerModal = () => {
    setIsShowDeleteCustomerModal(false);
  };

  if (isShowDeleteCustomerModal) {
    return (
      <Modal
        iconCancelMark={() => (
          <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
            <img src={closeIcon} alt="close-icon" />
          </div>
        )}
        wrapperClassName="wrap-delete-customer-modal"
        panelClassName="w-[396px] p-0 rounded-none"
        zIndex="z-[9998]"
        maskClassName="opacity-100"
        show={isShowDeleteCustomerModal}
        setShow={handleCloseDeleteCustomerModal}
        content={
          <div className="flex flex-col gap-[14px] p-[40px]">
            <h2 className="text-black font-poppins text-[30px] font-bold uppercase">
              Delete Customer?
            </h2>
            <div className="text-black mb-[40px] font-poppins text-[14px] font-[400] leading-normal">
              Are you sure you want to delete {firstname} {surname}?
            </div>
            <div
              className="flex px-[18px] py-2.5 m-0 justify-center items-center gap-[10px] flex-1 border-[1px] border-solid border-[#E11736] text-[#E11736] font-poppins text-sm font-bold uppercase hover:text-white hover:bg-[#E11736] cursor-pointer"
              onClick={handleDeleteCustomer}
            >
              YES, DELETE
            </div>
          </div>
        }
      />
    );
  }

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName=""
      panelClassName="w-[609px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-100"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] modal-content">
          <h2 className="text-black text-[30px] pb-[50px] font-[700] uppercase font-poppins">
            Edit Customer
          </h2>
          <div className="wrap-modal-body">
            <div className="flex gap-5">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase mb-[15px]">
                  First Name*
                </span>
                <input
                  id="firstname"
                  name="firstname"
                  className="border-solid  border-t-0 border-l-0 border-r-0 text-black mt-[15px] w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={firstname}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <span className="error w-full text-red-500">
                  {errors?.firstname}
                </span>
              </div>
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase mb-[15px]">
                  Last Name*
                </span>
                <input
                  id="surname"
                  name="surname"
                  className="border-solid  border-t-0 border-l-0 border-r-0 text-black mt-[15px] w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={surname}
                  placeholder="Last Name"
                  onChange={(e) => setSurName(e.target.value)}
                />
                <span className="error w-full text-red-500">
                  {errors?.surname}
                </span>
              </div>
            </div>
            <div className="flex gap-5 mt-[50px]">
              <div className="flex-1">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase ">
                  Email*
                </span>
                <input
                  id="email"
                  name="email"
                  value={email}
                  className=" border-solid  border-t-0 border-l-0 border-r-0 text-black  w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="error w-full text-red-500">
                  {errors?.email}
                </span>
              </div>
              <div className="flex-1 ">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase ">
                  SALES AGENT*
                </span>
                <div className="w-full relative">
                  <select
                    className={cn(
                      "bg-none appearance-none p-0 border-l-0 border-r-0 border-t-0 border-b-[1px] border-black font-poppins text-sm font-normal leading-[10px] text-black h-[23px] w-full bg-white focus:outline-none focus:ring-0",
                      {
                        error: errors?.agent,
                      }
                    )}
                    onChange={(e) => setAgent(e.target.value)}
                    id="sales-agent"
                    value={agent}
                    placeholder="Sales Agent"
                  >
                    {agentList?.length &&
                      agentList.map((a) => (
                        <option
                          key={a.id}
                          value={a.id}
                          className="opacity-75 text-black font-poppins text-sm font-normal leading-normal"
                        >
                          {a.firstname} {a.surname}
                        </option>
                      ))}
                  </select>
                  <img
                    className="absolute right-0 top-[50%] transform translate-y-[-50%]"
                    src={ChevronDown}
                  />
                </div>
                <span>{errors?.agent}</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-[50px]">
            <span
              className="flex justify-center items-center h-[37px] px-[18px] py-[12px] m-0 gap-[10px] border border-black font-poppins text-[14px] font-bold leading-[10px] hover:text-white hover:bg-black hover:cursor-pointer"
              onClick={handleUpdateCustomer}
            >
              Save
            </span>
            <span
              className="text-[#CCC] text-center font-poppins text-sm font-normal p-0 border-none bg-none m-0 cursor-pointer hover:text-black"
              onClick={handleOpenDeleteCustomerModal}
            >
              DELETE CUSTOMER
            </span>
          </div>
        </div>
      }
    />
  );
};

export default EditCustomerModal;
