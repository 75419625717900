import Info from "./commons/Hotspot/Info";
import Link from "./commons/Hotspot/Link";
import _ from "lodash";

export const renderInfoHotspots = ({ sceneRef, infoHotspots, onClick }) => {
  infoHotspots?.forEach((info, index) => {
    const { pitch, yaw, title, description } = info;

    const hotspotElement = Info({
      index,
      data: {
        description,
        title,
      },
      onClick,
    });

    sceneRef.current
      .hotspotContainer()
      .createHotspot(hotspotElement, { yaw, pitch });
  });
};

export const renderLinkHotspots = ({
  sceneRef,
  linkHotspots,
  isView,
  onClick,
}) => {
  linkHotspots?.forEach((link, index) => {
    const { pitch, yaw, targetScene } = link;

    const hotspotElement = Link({
      index,
      data: {
        targetScene,
      },
      isView,
      sceneRef,
      onClick,
    });

    sceneRef.current
      .hotspotContainer()
      .createHotspot(hotspotElement, { yaw, pitch });
  });
};

export function addDrag(
  element,
  hotspot,
  viewer,
  index,
  hotspotType,
  onUpdateSuccess
) {
  let isDragging = false;
  let lastX = 0;
  let lastY = 0;

  function onMouseDown(event) {
    isDragging = true;
    lastX = event.clientX;
    lastY = event.clientY;

    viewer.controls().disable(); // Disables panorama control for dragging
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  }

  function onMouseMove(event) {
    if (!isDragging) return;

    lastX = event.clientX;
    lastY = event.clientY;

    // Get the offset position within the viewer
    const { left, top } = viewer.domElement().getBoundingClientRect();
    const x = lastX - left;
    const y = lastY - top;

    // Translate screen coordinates to Marzipano coordinates
    const coords = viewer.view().screenToCoordinates({ x, y });
    if (coords) {
      hotspot.setPosition(coords); // Set position with Marzipano's spherical coordinates
    }
  }

  function onMouseUp() {
    isDragging = false;
    viewer.controls().enable(); // Re-enable panorama control

    onUpdateSuccess(
      index,
      hotspot._coords.yaw,
      hotspot._coords.pitch,
      hotspotType
    );

    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  }

  element.onmousedown = onMouseDown;
}

export function hasDataChanged(data, virtualTourData) {
  // So sánh số lượng scenes
  if (data?.scenes?.length !== virtualTourData?.scenes?.length) {
    return true;
  }

  // So sánh từng scene và các thuộc tính của chúng
  const scenesChanged = data?.scenes?.some((scene, index) => {
    const virtualScene = virtualTourData?.scenes[index];

    // So sánh sâu các thuộc tính của scene
    return !_.isEqual(scene, virtualScene);
  });

  // So sánh settings
  const settingsChanged = !_.isEqual(data?.settings, virtualTourData?.settings);

  return scenesChanged || settingsChanged;
}

export const generateMongoLikeId = () => {
  // Get current timestamp in seconds (4 bytes)
  const timestamp = Math.floor(Date.now() / 1000)
    .toString(16)
    .padStart(8, "0");

  // Generate machine identifier (3 bytes)
  const machineId = _.times(6, () => _.sample("0123456789abcdef")).join("");

  // Generate process id (2 bytes)
  const processId = _.times(4, () => _.sample("0123456789abcdef")).join("");

  // Generate increment (3 bytes)
  const increment = _.times(6, () => _.sample("0123456789abcdef")).join("");

  // Combine all parts to create 24-character hex string
  return `${timestamp}${machineId}${processId}${increment}`;
};
