import React, { useMemo } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import _ from "lodash";
import BathroomForm from "./bathroomForm";
import AvailableForm from "./availableForm";
import PriceForm from "./priceForm";
import SizeForm from "./sizeForm";
import ExteriorForm from "./exteriorForm";
import InteriorForm from "./interiorForm";
import BedroomForm from "./bedroomForm";
import PoolForm from "./poolForm";
import { FILTERS } from "../../../../helper/constant";
import { cn } from "../../../../helper/utils";

const Filters = ({ setIsFilter, isFilter, pages, ...form }) => {
  const listFilter = useMemo(() => {
    const newPage = pages?.find((page) => page.name === "interactive_3d_page");
    const module = newPage?.modules?.find(
      (page) => page.name === "unit_explore"
    );
    const feature = module?.features?.find(
      (item) => item.name === "unit_filtering"
    );
    if (
      newPage?.props?.visible &&
      module?.props?.visible &&
      feature?.props?.visible
    ) {
      const components = feature?.components?.filter(
        (item) => item?.props?.visible && item?.name !== "list_units"
      );

      return _.sortBy(components, (item) =>
        Object.values(FILTERS).indexOf(item.name)
      );
    }
  }, [pages]);

  const groupFilter = _.groupBy(listFilter, "name");

  const renderFilterComponent = (key, Component) => {
    return groupFilter[key]?.[0] ? (
      <Component data={groupFilter[key][0]} {...form} />
    ) : null;
  };

  const formFilter = {
    filter_status: renderFilterComponent("filter_status", AvailableForm),
    filter_price: renderFilterComponent("filter_price", PriceForm),
    filter_size: renderFilterComponent("filter_size", SizeForm),
    filter_size_exterior: renderFilterComponent(
      "filter_size_exterior",
      ExteriorForm
    ),
    filter_size_interior: renderFilterComponent(
      "filter_size_interior",
      InteriorForm
    ),
    filter_bedroom: renderFilterComponent("filter_bedroom", BedroomForm),
    filter_bathroom: renderFilterComponent("filter_bathroom", BathroomForm),
    filter_pool: renderFilterComponent("filter_pool", PoolForm),
  };

  return (
    <div
      className={cn(
        "border-solid mt-5 mr-4 bg-white w-[250px] max-h-[600px] overflow-y-auto border-[1px] border-[#242422] hidden",
        {
          block: isFilter,
        }
      )}
    >
      <div className="p-5">
        <div className="flex justify-between items-center pb-4 border-b-[1px] border-gray-300">
          <h2 className="text-[14px]">FILTERS</h2>
          <XMarkIcon
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={() => setIsFilter(!isFilter)}
          />
        </div>
        <div className="mt-5">
          {listFilter?.map((v) => {
            return (
              <div key={v?.id} className="mt-5">
                {formFilter[v?.name]}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Filters;
