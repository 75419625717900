/** @format */
import React, { useState } from "react";
import LeftPannel from "./left-panel";
import CustomUnit from "./detail-unit";
import { MEDIA_TYPE } from "../../utils";
//typeActive:

export const UnitContent = ({}) => {
  const [unitActive, setUnitActive] = useState();
  const [typeActive, setTypeActive] = useState(MEDIA_TYPE.IMAGE);

  return (
    <section className="flex h-[calc(100vh-80px)] bg-[rgb(244,244,244)] p-4 gap-4">
      <LeftPannel
        unitActive={unitActive}
        setUnitActive={setUnitActive}
        setActiveType={setTypeActive}
        typeActive={typeActive}
      />
      {unitActive && (
        <div className="relative bg-white rounded-lg right-content flex-1 pt-5 overflow-hidden">
          <CustomUnit
            playlistId={unitActive?.playlist?.id}
            typeActive={typeActive}
            unitId={unitActive?.id}
          />
        </div>
      )}
    </section>
  );
};
