export const FILTERS = {
  STATUS: "filter_status",
  PRICE: "filter_price",
  SIZE: "filter_size",
  SIZE_EXTERIOR: "filter_size_exterior",
  SIZE_INTERIOR: "filter_size_interior",
  BEDROOM: "filter_bedroom",
  BATHROOM: "filter_bathroom",
  POOL: "filter_pool",
};
