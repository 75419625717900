/** @format */

import socket from "../../helper/socket";
import React, { useEffect, useRef, useState } from "react";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { getS3BEMediaUrl } from "../../helper/media";
import { cn } from "../../helper/utils";
import { useSelector } from "react-redux";
import { PAGES_ENUM } from "../../constants/modules";
import TextEditor from "../../pages/cms/components/commons/textEditor";

const RIGHT = "right-image";
const MULTI_RIGHT = "multi-right-image";

const Neighborhood = (props) => {
  const { isPresentation } = props;
  const pageRef = useRef(null);
  const contentStaticType1 = useSelector(
    (state) => state.gallery.contentStaticType1
  );
  const pages = useSelector((state) => state.configuration.pages);
  const loading = useSelector((state) => state.configuration.loading);
  const [contents, setContents] = useState([]);
  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollPercentage *
          (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  useEffect(() => {
    if (pages && !loading) {
      pages.map((items) => {
        if (items.name === PAGES_ENUM.STATIC_PAGE) {
          items.modules?.map((i) => {
            if (
              i?.props?.type === "template-1" &&
              contentStaticType1.path === i?.props?.path
            ) {
              setContents(i?.props.sections);
            }
          });
        }
      });
    }
  }, [pages, loading, contentStaticType1]);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollTo(0, 0);
    }
  }, [contentStaticType1.path]);

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  };

  return (
    <div
      ref={pageRef}
      onScroll={onScroll}
      className={cn(
        "neighborhood-page overflow-x-hidden disable-scroll-bar w-screen absolute top-0 mt-[49px] h-[calc(100dvh-49px)] bg-brand mb-[60px] overflow-y-scroll",
        {
          "h-full mt-0": isPresentation,
        }
      )}
    >
      <div className="w-full mb-[47px]">
        <div className="w-full flex items-center flex-col">
          <div className="w-full flex items-center flex-col bg-brand-secondary">
            <div className="flex items-start relative px-[209px] py-[114px] w-[1366px] gap-[10px] flex-col">
              <div className="flex flex-col w-[521px] items-start">
                <div className="flex flex-col w-[948px] items-start gap-[50px]">
                  <div className="webkit-amenities-text-stroke text-primary text-[75px] font-[400] font-domaine max-w-[600px] uppercase leading-[120%] ">
                    {contents[0]?.title}
                  </div>
                  <div className="w-[500px] [&>.rsw-editor]:!border-none text-secondary text-[16px] font-suisse font-[300] self-end	 opacity-60 leading-[24px]">
                    <TextEditor
                      value={contents[0]?.description}
                      isReadOnly
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full flex items-center flex-col bg-brand">
          {contents?.map((info, index) => {
            if (index !== 0) {
              return (
                <div
                  className={cn(
                    "items-center flex flex-row gap-10 min-h-[768px] justify-between relative w-[1366px] pl-10 pr-[88px]",
                    {
                      "flex-row-reverse":
                        info?.type === RIGHT || info?.type === MULTI_RIGHT,
                    },
                    {
                      "border-t-[#24242226] border-t border-solid": index > 1,
                    }
                  )}
                  key={info?.tag}
                >
                  <div className="flex flex-col items-start gap-[15px] w-[679px] flex-[1_0_0] self-stretch py-10">
                    {info?.media && (
                      <img
                        src={getS3BEMediaUrl(info?.media?.path)}
                        className={cn(
                          "flex-[1_0_0] h-full self-stretch bg-no-repeat bg-cover bg-[50%] w-[679px]",
                          {
                            "w-[679px]": index === 0,
                            "w-[708px]": index === 1,
                            "w-[579px]": index === 2,
                          }
                        )}
                        alt={`media`}
                      />
                    )}
                    {info?.media1 && (
                      <img
                        className={cn(
                          "flex-[1_0_0] h-full self-stretch bg-no-repeat bg-cover bg-[50%] w-[679px]",
                          {
                            "w-[679px]": index === 0,
                            "w-[708px]": index === 1,
                            "w-[579px]": index === 2,
                          }
                        )}
                        src={getS3BEMediaUrl(info?.media1?.path)}
                        alt={`media1`}
                      />
                    )}
                    {info?.media2 && (
                      <img
                        className={cn(
                          "flex-[1_0_0] h-full self-stretch bg-no-repeat bg-cover bg-[50%] w-[679px]",
                          {
                            "w-[679px]": index === 0,
                            "w-[708px]": index === 1,
                            "w-[579px]": index === 2,
                          }
                        )}
                        src={getS3BEMediaUrl(info?.media2?.path)}
                        alt={`media1`}
                      />
                    )}
                  </div>
                  <div
                    className={cn(
                      "w-[479px] flex flex-col items-start border-r-[1px] border-solid border-[#24242226] py-[20px] min-h-[648px] self-stretch gap-[60px]",
                      {
                        "w-[479px]": index === 0,
                        "w-[450px]": index === 1,
                        "w-[579px]": index === 2,
                        "w-[550px]": index === 3,
                      },
                      {
                        "border-r-0 border-l-[1px] pl-[50px]": index % 2 === 0,
                      }
                    )}
                  >
                    <span className="flex items-center gap-[14px] text-primary justify-center text-[12px] font-suisse font-[400] leading-normal tracking-[1.2px] uppercase">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="9"
                        viewBox="0 0 8 9"
                        fill="none"
                        className="fill-current text-primary"
                      >
                        <circle cx="4" cy="4.5" r="4" />
                      </svg>
                      <span>{info.tag}</span>
                    </span>
                    <span className="whitespace-pre-line w-full text-primary text-[75px] webkit-amenities-text-stroke font-domaine font-[400] leading-[110%] uppercase break-words">
                      {info.title}
                    </span>
                    <span className="flex gap-[12px] [&>.rsw-editor]:!border-none whitespace-pre-line  font-suisse text-[16px] font-[400] tracking-[-0.36px] leading-6 text-secondary">
                      <TextEditor
                        value={info.description}
                        disabled
                        isReadOnly
                      />
                    </span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      {!isPresentation && <div className="bottom-top" />}
    </div>
  );
};

export default Neighborhood;
