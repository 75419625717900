import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import Marzipano from "marzipano";
import { WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";
import { cn } from "../../helper/utils";
import {
  renderInfoHotspots,
  renderLinkHotspots,
} from "../../pages/cms/virtual-tour/utils";
import { transformedScenes } from "../../pages/cms/utils";
import _ from "lodash";

const VirtualModal = ({ isPresentation }) => {
  const authUser = useSelector((state) => state.user.data);
  const { isShowAmenityVirtualTour } = useSelector(
    (state) => state.exploreModal
  );
  const virtualTourAmenity = useSelector(
    (state) => state.amenities.activeAmenity
  )?.virtualTour;

  const viewerRef = useRef(null);
  const sceneRef = useRef(null);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);

  const data = useMemo(() => {
    return virtualTourAmenity?.data || [];
  }, [virtualTourAmenity]);

  const scenes = data?.scenes || [];
  const { levels, url, initialViewParameters, linkHotspots, infoHotspots } =
    scenes[currentSceneIndex] || {};

  const filterLinkHotspots = useMemo(
    () =>
      _.filter(
        linkHotspots,
        (hotspot) =>
          hotspot?.targetScene && _.some(scenes, ["id", hotspot.targetScene])
      ),
    [linkHotspots, scenes]
  );

  const handleViewChange = (urlArg) => {
    if (!viewerRef.current) return;

    const pitch = viewerRef.current.view().pitch();
    const yaw = viewerRef.current.view().yaw();
    const fov = viewerRef.current.view().fov();

    socket.emit(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, {
      content: {
        yaw,
        pitch,
        fov,
        imageUrl: urlArg || url,
      },
      to: authUser?.id,
      from: authUser?.id,
    });
  };

  const handleViewInfoHotspot = ({ content }) => {
    if (!viewerRef.current || isPresentation) return;

    const pitch = viewerRef.current.view().pitch();
    const yaw = viewerRef.current.view().yaw();
    const fov = viewerRef.current.view().fov();

    socket.emit(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, {
      content: {
        yaw,
        pitch,
        fov,
        imageUrl: url,
        index: content.index,
        isVisible: content.isVisible,
      },
      to: authUser?.id,
      from: authUser?.id,
    });
  };

  // Unified initialization effect
  useEffect(() => {
    if (!data) return;

    const viewerElement = document.getElementById("panorama");
    const viewer = new Marzipano.Viewer(viewerElement, {
      controls: { mouseViewMode: "drag" },
    });

    const geometry = new Marzipano.EquirectGeometry(levels);
    const source = Marzipano.ImageUrlSource.fromString(url);
    const limiter = Marzipano.RectilinearView.limit.traditional(
      12288, // max size for the image
      (100 * Math.PI) / 180, // maximum pitch angle in radians
      (100 * Math.PI) / 180 // minimum pitch angle in radians
    );
    const view = new Marzipano.RectilinearView(initialViewParameters, limiter);

    const scene = viewer.createScene({ source, geometry, view });
    scene.switchTo({ transitionDuration: 1000 });

    viewerRef.current = viewer;
    sceneRef.current = scene;

    // Setup view change listener
    viewer.view().addEventListener("change", handleViewChange);

    // Setup autorotation if enabled
    let autorotateInstance = null;
    if (data?.settings?.autorotateEnabled) {
      const currentPitch = viewer.view().pitch();
      const currentFov = viewer.view().fov();

      autorotateInstance = new Marzipano.autorotate({
        yawSpeed: -0.1,
        targetPitch: currentPitch || 0,
        targetFov: currentFov || Math.PI / 2,
      });

      viewer.startMovement(autorotateInstance);

      const handleMouseUp = () => {
        viewer.startMovement(autorotateInstance);
      };

      viewerElement.addEventListener("mouseup", handleMouseUp);
      viewerElement.addEventListener("wheel", handleMouseUp);
    }

    return () => {
      viewer.view().removeEventListener("change", handleViewChange);

      if (autorotateInstance) {
        viewer.stopMovement(autorotateInstance);
      }
    };
  }, [data, url]);

  // Autorotation
  useEffect(() => {
    if (data?.settings?.autorotateEnabled && viewerRef.current) {
      const viewerElement = document.getElementById("panorama");

      const currentPitch = viewerRef.current.view().pitch();
      const currentFov = viewerRef.current.view().fov();

      const autorotate = new Marzipano.autorotate({
        yawSpeed: -0.1,
        targetPitch: currentPitch || 0,
        targetFov: currentFov || Math.PI / 2,
      });
      viewerRef.current.startMovement(autorotate); // Start autorotation

      // Handle autorotation when mouse up
      const handleMouseUp = () => {
        if (data?.settings?.autorotateEnabled) {
          viewerRef.current.startMovement(autorotate);
        }
      };

      viewerElement.addEventListener("mouseup", handleMouseUp);

      return () => {
        viewerElement.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [data?.settings?.autorotateEnabled, viewerRef.current]);

  // Cleanup effect when component unmounts
  useEffect(() => {
    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
    };
  }, []);

  // Socket listener for presentation mode
  useEffect(() => {
    if (!sceneRef.current || !isPresentation) return;

    const handleCameraAction = ({ content }) => {
      if (!sceneRef.current) return;

      const view = sceneRef.current.view();
      view.setParameters({
        yaw: content.yaw,
        pitch: content.pitch,
        fov: content.fov,
      });

      if (typeof content?.index === "number") {
        const hotspotContainer = sceneRef.current.hotspotContainer();
        const hotspots = hotspotContainer.listHotspots();
        const infoHotspots = hotspots.filter((hotspot) => {
          const domElement = hotspot.domElement();
          const id = domElement.id;

          return id.includes("hotspot-info");
        });

        const targetHotspot = infoHotspots[content.index];

        const hotspotElement = targetHotspot.domElement();

        if (
          targetHotspot &&
          typeof hotspotElement.setPopupVisibility === "function"
        ) {
          hotspotElement.setPopupVisibility(content.isVisible);
        }
      }

      setCurrentSceneIndex(
        scenes.findIndex((scene) => scene.url === content.imageUrl)
      );
    };

    socket.on(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, handleCameraAction);

    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, handleCameraAction);
    };
  }, [isPresentation, scenes]);

  // Add hotspots
  useEffect(() => {
    if (!sceneRef.current) return;

    // Destroy old hotspots
    sceneRef.current
      .hotspotContainer()
      .listHotspots()
      .forEach((hotspot) => {
        sceneRef.current.hotspotContainer().destroyHotspot(hotspot);
      });

    // Add infoHotspots
    renderInfoHotspots({
      infoHotspots,
      sceneRef,
      onClick: handleViewInfoHotspot,
      isView: true,
    });

    // Add linkHotspots
    renderLinkHotspots({
      linkHotspots: filterLinkHotspots,
      sceneRef,
      isView: true,
      onClick: (data) => {
        const indexScene = scenes.findIndex(
          (scene) => scene.id === data.targetScene
        );

        if (indexScene === -1 || isPresentation) return;

        handleViewChange(scenes[indexScene].url);
        setCurrentSceneIndex(indexScene);
      },
    });
  }, [infoHotspots, filterLinkHotspots, scenes, isPresentation]);

  return (
    <div
      className={cn(
        "w-screen absolute z-[1000] right-0",
        isPresentation ? "h-dvh top-0" : "h-[calc(100dvh_-_92px)] top-[48px]",
        {
          hidden: !isShowAmenityVirtualTour,
        }
      )}
    >
      <div id="panorama" className="w-full h-full absolute" />
    </div>
  );
};

export default VirtualModal;
