/** @format */

import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import closeIcon from "../../../assets/images/close-white.svg";
import customersAPI from "../../../apis/api/customer";
import { Modal } from "../components/commons";
import { cn } from "../../../helper/utils";

const validationSchema = yup.object().shape({
  firstname: yup.string().trim().required("First name is a required field"),
  surname: yup.string().trim().required("Last name is a required field"),
  email: yup
    .string()
    .trim()
    .email("Invalid email format")
    .required("Email is a required field"),
  agent: yup.string().trim().required("Sales Agent is a required field"),
});

const AddCustomerModal = ({ show, setShow, onCreateSuccess }) => {
  const [firstname, setFirstName] = useState("");
  const [surname, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [agent, setAgent] = useState("");
  const [errors, setErrors] = useState({});

  const agents = useSelector((state) => state.cms.agents);

  useEffect(() => {
    if (agents?.[0]?.id) {
      setAgent(agents?.[0]?.id);
    }
  }, [agents]);

  const handleAddNewCustomer = async () => {
    try {
      const data = {
        userGroups: [],
        firstname,
        surname,
        email,
        agent,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });
      const res = await customersAPI.createCustomer(result);

      if (res.data) {
        toast.success("Customer created successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      panelClassName="w-[609px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-100"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] ">
          <h2 className="text-black text-[30px] mb-[40px] font-[700] uppercase font-poppins">
            CREATE NEW CUSTOMER
          </h2>
          <div className="wrap-modal-body">
            <div className="wrap-form-group">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase mb-[15px]">
                  First Name*
                </span>
                <input
                  id="firstname"
                  name="firstname"
                  className="border-solid  border-t-0 border-l-0 border-r-0 text-black mt-[15px] w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={firstname}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <span className="error w-full text-red-500">
                  {errors?.firstname}
                </span>
              </div>
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase mb-[15px]">
                  Last Name*
                </span>
                <input
                  id="surname"
                  name="surname"
                  className=" border-solid  border-t-0 border-l-0 border-r-0 text-black mt-[15px] w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={surname}
                  placeholder="Last Name"
                  onChange={(e) => setSurName(e.target.value)}
                />
                <span className="error w-full text-red-500">
                  {errors?.surname}
                </span>
              </div>
            </div>
            <div className="wrap-form-group">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase mb-[15px]">
                  Email*
                </span>
                <input
                  id="email"
                  name="email"
                  value={email}
                  className=" border-solid border-t-0 border-l-0 border-r-0 text-black mt-[15px] w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="error w-full text-red-500">
                  {errors?.email}
                </span>
              </div>
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-[10px] uppercase mb-[15px]">
                  SALES AGENT*
                </span>
                <div className="w-full relative">
                  <select
                    className={cn(
                      "bg-none appearance-none p-0 border-l-0 border-r-0 border-t-0 border-b-[1px] border-black font-poppins text-sm font-normal leading-[10px] text-black h-[23px] w-full bg-white focus:outline-none focus:ring-0",
                      {
                        error: errors?.agent,
                      }
                    )}
                    onChange={(e) => setAgent(e.target.value)}
                    id="sales-agent"
                    value={agent}
                    placeholder="Sales Agent"
                  >
                    {agents?.length &&
                      agents.map((c) => (
                        <option
                          key={c.id}
                          value={c.id}
                          className="opacity-75 text-black font-poppins text-sm font-normal leading-normal"
                        >
                          {c.name}
                        </option>
                      ))}
                  </select>
                </div>
                <span className="error w-full text-red-500">
                  {errors?.agent}
                </span>
              </div>
            </div>
          </div>
          <div
            className="flex mt-[50px] justify-center items-center flex-1 h-[37px] px-[18px] py-[12px] m-0 gap-[10px] border border-black font-poppins text-[14px] font-bold leading-[10px] hover:text-white hover:bg-black hover:cursor-pointer"
            onClick={handleAddNewCustomer}
          >
            SAVE
          </div>
        </div>
      }
    />
  );
};

export default AddCustomerModal;
