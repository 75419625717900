/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import customerAPi from "../../../apis/api/customer";
import { reqSetIsShowListInvestorModal } from "../../../reduxs/guide-session/action";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { getS3FEMediaUrl } from "../../../helper/media";
import InputProfile from "./input-profile";
import CreateProfile from "./create-profile";

const Profile = (props) => {
  const { startDiscover, handleCancel, agents, isPresentation } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [listUserSelect, setListUserSelect] = useState([]);
  const [isCreate, showCreate] = useState(false);

  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  useEffect(() => {
    dispatch(reqSetIsShowListInvestorModal(true));

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  useEffect(() => {
    if (isShowListInvestorModal) {
      getListInvestor();
    }
  }, [isShowListInvestorModal]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CREATE_PROFILE_SUCCESS) {
      onStartUserSession(content?.data?.user);
    }
  };

  const getListInvestor = async () => {
    const result = await customerAPi.getCustomersList();
    setListUserSelect(result?.data || []);
  };

  const onStartUserSession = (user, sessionId) => {
    user?.id &&
      sessionId &&
      navigate({
        search: `?customer=${user.id}&session=${sessionId}`,
      });
    analytics.track("Start Session", {
      customerType: userSelectType,
      customerFirstName: user?.firstname,
      customerLastName: user?.surname,
      customerEmail: user?.email,
    });

    return startDiscover(user?.id, sessionId);
  };

  const [customerSelected, setCustomerSelected] = useState(null);

  return (
    <div
      id="customer-profile"
      style={{ backgroundImage: `url(${getS3FEMediaUrl("/bg-holding.png")})` }}
    >
      {isCreate ? (
        <CreateProfile
          agents={agents}
          isPresentation={isPresentation}
          showCreate={showCreate}
          onStartUserSession={onStartUserSession}
        />
      ) : (
        <InputProfile
          listUserSelect={listUserSelect}
          customerSelected={customerSelected}
          onStartUserSession={onStartUserSession}
          setCustomerSelected={setCustomerSelected}
          isPresentation={isPresentation}
          showCreate={showCreate}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default Profile;
