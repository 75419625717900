import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import StartNewSession from "./start-new-session";
import ContinueSession from "./continue-session";
import { Button, Modal } from "../../../../pages/cms/components/commons";
import sessionApi from "../../../../apis/api/session";
import CancelIcon from "../../../svgs/cancelIcon";
import { useForm } from "react-hook-form";

//Props ={
//  onSuccessSelectProfile: () => void
//  setIsShow: (v: boolean) => void
//  customerId: string
// }

const SessionSelect = (props) => {
  const { onSuccessSelectProfile, setIsShow, customerId, selectedUser } = props;
  const user = useSelector((state) => state.user?.data);

  const form = useForm();
  const { handleSubmit, formState, watch } = form;
  const [loading, setLoading] = React.useState(false);
  const [currentSession, setCurrentSession] = React.useState(null);

  const agentId = selectedUser?.agent?.id;
  const isExitAgent = Boolean(agentId);

  const isSalesAgentRole = isExitAgent || user?.isAgent;

  const onStartNewSession = async (data, onSuccess) => {
    setLoading(true);
    const res = await sessionApi.createSession({
      customer: customerId,
      agent: data?.agentId,
    });

    if (res?.data) {
      setLoading(false);
      onSuccessSelectProfile({
        id: res?.data?.id, // id is id of session
      });
      onSuccess && onSuccess();
      toast.success("Session created successfully");
      setIsShow(false);
    } else {
      toast.error("Session created failed");
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    if (!values?.session?.value && !currentSession) {
      toast.warn("Please create a new session");

      return;
    }

    const res = await sessionApi.updateSession(
      values?.session?.value ?? currentSession?.id,
      {
        customer: customerId,
      }
    );

    if (res?.data) {
      onSuccessSelectProfile({
        id: values?.session?.value ?? currentSession?.id, // id is id of session
      });
      setIsShow && setIsShow(false);
      toast.success("Session updated successfully");
    } else {
      toast.error("Session updated failed");
    }
  };

  return (
    <div>
      <h2 className="text-center font-bold text-xl mt-5">Select session</h2>

      <p className="text-center my-5">
        You can select session or start a new one
      </p>
      <div className="absolute top-3 right-3" onClick={() => setIsShow(false)}>
        <CancelIcon className="[&>circle]:fill-black cursor-pointer" />
      </div>
      <div>
        <ContinueSession
          {...props}
          form={form}
          setCurrentSession={setCurrentSession}
        />
      </div>
      <div className="flex gap-5 pt-20 justify-space-between">
        <Button
          className="flex-1"
          disabled={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
          isLoading={formState.isSubmitting}
        >
          {!formState.isSubmitting && "CONTINUE SESSION"}
        </Button>
        <div className="flex-1">
          {isSalesAgentRole && (
            <Button
              isLoading={loading}
              disabled={loading}
              variant="text"
              className="w-full py-4"
              onClick={() =>
                onStartNewSession({
                  agentId: isExitAgent ? agentId : user?.id,
                })
              }
            >
              {!loading && "START A NEW SESSION"}
            </Button>
          )}
          {!isSalesAgentRole && (
            <Modal
              panelClassName="w-[700px] overflow-visible"
              zIndex="z-[9998]"
              maskClassName="bg-opacity-50"
              content={({ setIsShow }) => (
                <StartNewSession
                  onStartNewSession={onStartNewSession}
                  onClose={() => setIsShow(false)}
                />
              )}
              triggerClassName="flex-1"
              trigger={
                <Button variant="text" className="w-full py-4">
                  START A NEW SESSION
                </Button>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionSelect;
