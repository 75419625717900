/** @format */

import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getUnitList = (data) => client.get(endPoint.UNITS_GET_LIST, data);
const createUnit = (data) => defaultClient.post(endPoint.UNITS_CREATE, data);

const getUnitDetail = (unitId, data) =>
  client.get(endPoint.UNITS_GET_DETAIL.replace(":unitId", unitId), data);

const putUnitUpdate = (unitId, data) =>
  defaultClient.put(endPoint.UNITS_PUT_UPDATE.replace(":unitId", unitId), data);

const deleteUnit = (unitId) =>
  defaultClient.delete(endPoint.UNITS_DELETE.replace(":unitId", unitId));

const getUnitMedia = (unitId) =>
  defaultClient.get(endPoint.UNITS_GET_MEDIA.replace(":unitId", unitId));

const getUnitMediaType = (unitId, data) =>
  defaultClient.get(endPoint.UNITS_GET_MEDIA.replace(":unitId", unitId), data);
const removeMediaUnit = (unitId, data) =>
  defaultClient.put(
    endPoint.UNITS_REMOVE_MEDIA.replace(":unitId", unitId),
    data
  );
const addUnitMedia = (unitId, data) =>
  defaultClient.put(endPoint.UNITS_ADD_MEDIA.replace(":unitId", unitId), data);
const getAllMediaUnit = (unitId, data) =>
  defaultClient.get(
    endPoint.UNITS_GET_ALL_MEDIA.replace(":unitId", unitId),
    data
  );

export default {
  getUnitList,
  putUnitUpdate,
  getUnitDetail,
  deleteUnit,
  createUnit,
  getUnitMedia,
  getUnitMediaType,
  removeMediaUnit,
  addUnitMedia,
  getAllMediaUnit,
};
