import { toast } from "react-toastify";
import React, { useEffect, useMemo } from "react";
import { MODEL_MEDIA_TYPES } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import canvasProfileApi from "../../../../../../apis/api/canvas-profiles";
import { reqGetModelsMediaList } from "../../../../../../reduxs/media/action";
import { actSetSelectedCanvasProfileId, reqGetActiveConfig, reqGetCanvasProfiles } from "../../../../../../reduxs/cms/action";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { openDatabase, getFile, saveFile, deleteFile } from "../../../../../../components/3d-scene/GLTF3DCache";
import { getS3BEMediaUrl } from "../../../../../../helper/media";

export const CellModel = ({ rowComponent, modelType }) => {
  const dispatch = useDispatch();

  const canvasProfiles = useSelector((state) => state.cms.canvasProfiles);
  const activeConfig = useSelector((state) => state.cms.activeConfig);
  const selectedCanvasProfileId = useSelector((state) => state.cms.selectedCanvasProfileId);

  const selectedCanvasProfile = useMemo(() => {
    if (selectedCanvasProfileId) {
      return canvasProfiles?.find(t => t.id === selectedCanvasProfileId) || null;
    }
    return null;
  }, [selectedCanvasProfileId, canvasProfiles]);

  useEffect(() => {
    if (!selectedCanvasProfileId) {
      dispatch(actSetSelectedCanvasProfileId(activeConfig?.canvasProfile?.id));
    }
  }, [selectedCanvasProfileId, activeConfig]);

  const extractLayer = async (gltf) => {
    const modelLayers = gltf.scene.children.map(c => c.children[0].name).map(name => {
      const match = name.match(/Layer0*(\d+)/);
      return match ? Number(match[1]) : null; 
    }).filter(Boolean);

    const uniqueLayers = [...new Set(modelLayers)];
    const payload = {
      layers: uniqueLayers
    }
    const res = await canvasProfileApi.updateCanvasProfile(selectedCanvasProfile?.id, payload);
    if (res?.data) {
      dispatch(reqGetCanvasProfiles());
    }
  };

  const customGLTFLoader = async (path, onComplete) => {
    let loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco/");
    loader.setDRACOLoader(dracoLoader);
  
    let response = null;
    const db = await openDatabase();
    const cachedFile = await getFile(db, path);
  
    if (cachedFile) {
      // Load model from cache
      const blobUrl = URL.createObjectURL(cachedFile.file);
      loader.load(blobUrl, (gltf) => {
        onComplete(gltf);    
        URL.revokeObjectURL(blobUrl);
      });
    } else {
      try {
        // Load models from network
        const response = await fetch(getS3BEMediaUrl(path));
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'model/gltf-binary' });
        const blobUrl = URL.createObjectURL(blob);
  
        loader.load(blobUrl, async (gltf) => {
          onComplete(gltf);    
          URL.revokeObjectURL(blobUrl);
        })
  
        // Delete old cache, and save new file
        if (cachedFile) {
          await deleteFile(db, path);
        }
  
        await saveFile(db, path, blob, 1);
      } catch (error) {
        console.error("Error fetching the model:", error);
      }
    }
    return response;
  }

  const toggleVisibleComponent = async (modelMedia, checked) => {
    try {
      if (!modelMedia?.id) return;
      const payload = {};
      if (modelType === MODEL_MEDIA_TYPES.MAIN) {
        payload.modelMedia = checked ? modelMedia.id : null;
      } else if (modelType === MODEL_MEDIA_TYPES.HELPERS) {
        payload.helperMedia = checked ? modelMedia.id : null;
      }
      const res = await canvasProfileApi.updateCanvasProfile(selectedCanvasProfile?.id, payload);

      if (checked && modelType === MODEL_MEDIA_TYPES.MAIN) {
        customGLTFLoader(modelMedia?.path, extractLayer)
      }
      if (res) {
        if (modelType === MODEL_MEDIA_TYPES.MAIN && checked) {
          toast.success(`Model ${modelMedia.name} set as MAIN for ${selectedCanvasProfile?.name}`);
        } else if (modelType === MODEL_MEDIA_TYPES.HELPERS && checked) {
          toast.success(`Model ${modelMedia.name} set as HELPER for ${selectedCanvasProfile?.name}`);
        } else {
          toast.success(`Model list updated`);
        }
        dispatch(reqGetModelsMediaList());
        dispatch(reqGetCanvasProfiles());
        dispatch(reqGetActiveConfig());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isChecked = useMemo(() => {
    switch (modelType) {
      case MODEL_MEDIA_TYPES.MAIN:
        return rowComponent?.id === selectedCanvasProfile?.modelMedia?.id;
      case MODEL_MEDIA_TYPES.HELPERS:
        return rowComponent?.id === selectedCanvasProfile?.helperMedia?.id;
      default:
        return false;
    }
  }, [selectedCanvasProfile]);

  const isDisabled = useMemo(() => {
    switch (modelType) {
      case MODEL_MEDIA_TYPES.MAIN:
        return rowComponent?.id === selectedCanvasProfile?.helperMedia?.id;
      case MODEL_MEDIA_TYPES.HELPERS:
        return rowComponent?.id === selectedCanvasProfile?.modelMedia?.id;
      default:
        return false;
    }
  }, [selectedCanvasProfile]);

  return (
    <div className="flex justify-center relative">
      <input
        type="checkbox"
        className="w-5 h-5 rounded-full disabled:opacity-40 disabled:cursor-not-allowed disabled:bg-gray-400/30"
        checked={isChecked}
        disabled={isDisabled}
        onChange={(e) =>
          toggleVisibleComponent(
            rowComponent,
            e.target.checked
          )
        }
      />
    </div>
  );
};
