import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const getCustomerList = (data) => client.get(endPoint.CUSTOMER_LIST, data);
const getCustomerDetail = (id, data) =>
  client.get(endPoint.CUSTOMER_DETAIL.replace(':id', id), data);
const getCustomerFavorites = (id) =>
  client.get(endPoint.CUSTOMER_FAVORITE.replace(':id', id));
const createCustomer = (data) => client.post(endPoint.CUSTOMER_CREATE, data);
const updateCustomer = (id, data) =>
  client.put(endPoint.CUSTOMER_UPDATE.replace(':id', id), data);
const updateCustomerFavorite = (id, data) =>
  client.put(endPoint.CUSTOMER_FAVORITE.replace(':id', id), data);
const deleteCustomer = (id, data) =>
  client.delete(endPoint.CUSTOMER_DELETE.replace(':id', id), data);

const getCustomersList = (data) =>
  client.get(endPoint.CUSTOMER_LIST, {
    ...data,
    'userGroups[equal]': [],
    'isActive[equal]': true,
  });

const findCustomer = (data) => client.get(endPoint.CUSTOMER_FIND_ONE, data);

export default {
  getCustomerList,
  getCustomerDetail,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomersList,
  findCustomer,
  getCustomerFavorites,
  updateCustomerFavorite,
};
