import React from "react";
import AsyncSelect from "react-select/async";
import { useController } from "react-hook-form";
import { cn } from "../../../../helper/utils";

const AutocompleteField = (props) => {
  const {
    autocompleteProps,
    isDisabled,
    controller,
    label,
    placeholder = "",
    isRequired,
    callbackOnchange,
  } = props;
  const { field, fieldState } = useController(controller);
  const { error } = fieldState;

  const onchange = (v) => {
    field.onChange(v);
    callbackOnchange && callbackOnchange(v);
  };

  return (
    <>
      {label && (
        <legend className="text-gray-700 mb-2 text-sm font-medium">
          {label} {isRequired && <span className="text-red-500">*</span>}
        </legend>
      )}
      <AsyncSelect
        isDisabled={isDisabled}
        {...autocompleteProps}
        defaultOptions
        placeholder={placeholder}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        value={field.value}
        onChange={onchange}
        className={cn("text-sm", {
          "border-red-500 border-solid border-[1px]": error,
        })}
      />
      {error && (
        <p className={error ? "text-red-600 text-xs" : ""}>{error.message}</p>
      )}
    </>
  );
};

export default AutocompleteField;
