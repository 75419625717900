import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import newProfileImage from "../../../assets/images/new-profile.png";
import customerAPi from "../../../apis/api/customer";
import socket from "../../../helper/socket";
import { ACTION_NAME } from "../../../constants/options";
import sessionApi from "../../../apis/api/session";

const CreateProfile = (props) => {
  const { isPresentation, onStartUserSession, agents, showCreate } = props;

  //form create profile
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agent, setAgent] = useState(null);
  const [isErrFirstName, setErrFirstName] = useState(false);
  const [isErrLastName, setErrLastName] = useState(false);
  const [isErrEmail, setErrEmail] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isErrAgent, setErrAgent] = useState(false);

  const createProfile = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName) setErrFirstName(true);
    if (!lastName) setErrLastName(true);
    if (!emailAddress) setErrEmail(true);
    if (!agent) setErrAgent(true);

    if (!emailPattern.test(emailAddress)) {
      setIsEmailInvalid(true);
      return;
    }

    if (firstName && lastName && emailAddress) onCreateNewProfile();
    return;
  };

  const onCreateNewProfile = async () => {
    try {
      const data = {
        userGroups: [],
        email: emailAddress,
        firstname: firstName,
        surname: lastName,
        address: "",
        mobile: "",
        countryCode: "",
        agent: agent?.value,
      };

      const res = await customerAPi.createCustomer(data);
      const newCustomer = res?.data;
      if (res && !newCustomer) {
        return toast.error("Customer's email exists!");
      }

      const newSessionRes = await sessionApi.createSession({
        customer: newCustomer?.id,
        agent: newCustomer?.agent?.id || newCustomer?.agent,
      });

      analytics.track("Agent Created Client", {
        agentId: res.data?.id,
        clientId: res.data?.id,
        clientFistName: res.data?.firstname,
        clientEmail: res.data?.email,
        clientSurname: res.data?.surname,
      });

      if (newSessionRes) {
        onStartUserSession(newCustomer, newSessionRes?.data?.id);

        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.CREATE_PROFILE_SUCCESS, {
            user: newCustomer,
          });
        }
      }

      handleResetData();
      toast.success(`Created successfully!`);
    } catch (err) {
      toast.error(`Customer's email already exists`);
    }
  };

  const handleResetData = () => {
    setEmailAddress("");
    setFirstName("");
    setLastName("");
    setAgent(null);
    setErrEmail(false);
    setIsEmailInvalid(false);
    setErrFirstName(false);
    setErrLastName(false);
    setErrAgent(undefined);
  };

  const ErrMessage = () => (
    <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">
      This field is required
    </p>
  );

  return (
    <div
      id="select-profile-wrapper"
      className="absolute top-0 left-0 w-screen h-screen bg-white text-[#242422] p-[80px_110px] flex justify-between overflow-y-scroll"
    >
      <div className="flex flex-col w-[534px] h-[846px] relative justify-between before:content-[''] before:block before:absolute before:top-0 before:left-[574px] before:h-full before:w-[1px] before:bg-[#24242226]">
        <div className="pt-[42px] h-[307px] text-left">
          <h2 className="text-[75px] !font-normal font-domaine">NEW PROFILE</h2>
        </div>
        <div className="border-t border-[#24242226] flex flex-col items-start w-full pt-[20px] pb-[30px]">
          <p className="text-[22px] mb-[5px] font-normal text-black text-left leading-[30px] tracking-tight font-suisse">
            First time at Bay Harbor Towers? Enter your details below to create
            a new customer profile.
          </p>
          <div className="w-full grid gap-y-[25px] gap-x-[15px] grid-cols-2 mb-[30px]">
            <div className="relative flex flex-col">
              <input
                className={`outline-none placeholder:text-[12px] placeholder:font-normal placeholder:text-[#242422] placeholder:tracking-[0.5px] placeholder:font-suisse w-full h-[64px] border-none bg-[#f4f4f4] pl-[20px]`}
                type="text"
                placeholder="FIRST NAME"
                name="firstName"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setErrFirstName(false);
                }}
                style={{
                  border: isErrLastName ? "1px solid #c02b0a" : "none",
                }}
              />
              {isErrFirstName && (
                <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">
                  This field is required
                </p>
              )}
              {firstName && (
                <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">
                  FIRST NAME
                </span>
              )}
            </div>
            <div className="relative flex flex-col">
              <input
                className={`outline-none placeholder:text-[12px] placeholder:font-normal placeholder:text-[#242422] placeholder:tracking-[0.5px] placeholder:font-suisse w-full h-[64px] bg-[#f4f4f4] pl-[20px]`}
                type="text"
                placeholder="LAST NAME"
                name="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setErrLastName(false);
                }}
                style={{
                  border: isErrLastName ? "1px solid #c02b0a" : "none",
                }}
              />
              {isErrLastName && <ErrMessage />}
              {lastName && (
                <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">
                  LAST NAME
                </span>
              )}
            </div>
            <div className="relative flex flex-col">
              <input
                className={`outline-none placeholder:text-[12px] placeholder:font-normal placeholder:text-[#242422] placeholder:tracking-[0.5px] placeholder:font-suisse w-full h-[64px] border-none bg-[#f4f4f4] pl-[20px]`}
                type="text"
                placeholder="EMAIL"
                name="email"
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                  setErrEmail(false);
                  setIsEmailInvalid(false);
                }}
                style={{
                  border:
                    isErrEmail || isEmailInvalid ? "1px solid #c02b0a" : "none",
                }}
              />
              {isErrEmail ? (
                <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">
                  This field is required
                </p>
              ) : (
                <>
                  {isEmailInvalid && (
                    <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">
                      Email not valid
                    </p>
                  )}
                </>
              )}
              {emailAddress && (
                <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">
                  EMAIL
                </span>
              )}
            </div>

            <div className="relative flex flex-col">
              <Select
                className="w-full h-full focus:outline-none cursor-pointer "
                classNames={{
                  control: () =>
                    "!bg-[#f4f4f4] px-2 h-full !rounded-none !border-navigation !border-none !outline-none !shadow-xl !shadow-gray-600 !cursor-pointer",
                  singleValue: () => "text-left text-[14px] font-normal",
                  dropdownIndicator: () => "!cursor-pointer",
                  indicatorSeparator: () => "!hidden",
                  menu: () => "",
                  option: (state) => `text-left cursor-pointer`,
                  placeholder: () => "text-left text-[12px] font-normal",
                }}
                classNamePrefix={isErrAgent ? "select-error" : "select"}
                isDisabled={false}
                placeholder="SALES AGENT"
                menuPlacement="top"
                maxMenuHeight={250}
                value={agent}
                isSearchable={false}
                options={agents?.map(
                  ({ id, firstname = "", surname = "" }) => ({
                    value: id,
                    label: `${firstname} ${surname}`,
                  })
                )}
                name="type"
                onChange={(item) => {
                  setAgent(item);
                  setErrAgent(false);
                }}
                style={{ border: isErrAgent ? "1px solid #c02b0a" : "none" }}
              />
              {isErrAgent && <ErrMessage />}
            </div>
          </div>
          <button
            onClick={createProfile}
            className="w-full h-[60px] bg-black text-white border-none text-[14px] font-normal mb-[25px]"
          >
            SUBMIT
          </button>
          <div className="button-group flex">
            <button
              onClick={() => {
                showCreate(false);
                handleResetData();
              }}
              className="text-[12px] font-normal"
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
      <img
        className="md:block h-auto object-cover"
        width="534"
        height="846"
        src={newProfileImage}
        alt="new-profile-image"
      />
    </div>
  );
};

export default CreateProfile;
