/** @format */

import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { cn } from "../../../helper/utils";
import { Modal } from "../components/commons";
import usersAPI from "../../../apis/api/user";
import { CheckCircleSolid, Reload } from "../../../components/svgs/icons";
import { generatePassword, getStaffType } from "../utils";
import React, { useState, useEffect, useMemo } from "react";
import closeIcon from "../../../assets/images/close-white.svg";
import ChevronDown from "../../../assets/images/chevron-down.svg";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";

const validationSchema = yup.object().shape({
  firstname: yup.string().trim().required("First name is a required field"),
  surname: yup.string().trim().required("Last name is a required field"),
  userGroups: yup.array().of(yup.string().required()).min(1, "Groups is a required field"),
  password: yup.string().trim().required("Password is a required field"),
  confirmPassword: yup
    .string()
    .trim()
    .required("ConfirmPassword is a required field")
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  email: yup
    .string()
    .trim()
    .email("Invalid email format")
    .required("Email is a required field"),
});

export const AddStaffModal = ({ show, setShow, onCreateSuccess }) => {
  const [firstname, setFirstName] = useState("");
  const [surname, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(generatePassword(8));
  const [confirmPassword, setConfirmPassword] = useState();
  const [errors, setErrors] = useState({});
  const [userGroups, setUserGroups] = useState([]);

  const groups = useSelector((state) => state.cms.userGroups);

  const handleAddNew = async () => {
    try {
      const payload = {
        userGroups,
        firstname,
        surname,
        email,
        password,
        confirmPassword,
      };
      setErrors({});
      const result = await validationSchema.validate(payload, {
        abortEarly: false,
      });
      const res = await usersAPI.createUser(result);

      if (res.data) {
        toast.success("User created!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      } else {
        toast.error("Create user failed!");
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const regeneratePass = () => {
    const newPassword = generatePassword(8);
    setPassword(newPassword);
  }

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      panelClassName="w-[609px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-100"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] ">
          <h2 className="text-black text-[30px] mb-[40px] font-[700] uppercase font-poppins">
            CREATE NEW USER
          </h2>
          <div className="wrap-modal-body">
            <div className="wrap-form-group max-w-[480px] flex gap-5">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  First Name*
                </span>
                <input
                  id="firstname"
                  name="firstname"
                  className="border-solid  border-t-0 border-l-0 border-r-0 text-black w-full font-poppins text-sm font-normal leading-normal border-b !border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={firstname}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <div className="mt-2 text-sm text-red-500 w-full">{errors?.firstname}</div>
              </div>
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  Last Name*
                </span>
                <input
                  id="surname"
                  name="surname"
                  className=" border-solid  border-t-0 border-l-0 border-r-0 text-black w-full font-poppins text-sm font-normal leading-normal border-b !border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={surname}
                  placeholder="Last Name"
                  onChange={(e) => setSurName(e.target.value)}
                />
                <div className="mt-2 text-sm text-red-500 w-full">{errors?.surname}</div>
              </div>
            </div>
            <div className="wrap-form-group max-w-[480px] flex gap-5">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  Email*
                </span>
                <input
                  id="email"
                  name="email"
                  value={email}
                  className=" border-solid border-t-0 border-l-0 border-r-0 text-black w-full font-poppins text-sm font-normal leading-normal border-b !border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="mt-2 text-sm text-red-500 w-full">{errors?.email}</div>
              </div>
            </div>
            <div className="wrap-form-group max-w-[480px] flex gap-5">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  ROLES*
                </span>
                <div className="w-full relative border-b border-black">
                  <Listbox value={userGroups} onChange={setUserGroups} multiple>
                    <ListboxButton className="min-h-[23px] pr-5 w-full text-left text-sm font-normal font-poppins">
                      {userGroups?.length && groups?.length
                        ? userGroups.reduce((acc, uG) => {
                          const matchingGr = groups.find(g => g.id === uG);
                          return matchingGr ? acc.concat(matchingGr.name) : acc;
                        }, []).join(', ')
                        : <span className="text-gray-500">Select groups</span>
                      }
                    </ListboxButton>
                    <ListboxOptions
                      anchor="bottom start"
                      className="w-[480px] bg-white z-[10000] py-2 mt-1 rounded-lg border-2 border-black/40"
                    >
                      {groups?.length && groups.map((gr, _i) => (
                        <ListboxOption
                          key={gr.id}
                          value={gr.id}
                          className={`px-2 bg-white text-sm font-normal font-poppins data-[focus]:bg-green-100 cursor-pointer ${_i !== 0 ? 'border-t-2 border-t-gray-100' : ''}`}
                        >
                          <div className="flex justify-between items-center">
                            {gr.name}
                            {
                              userGroups?.some(selectedId => selectedId === gr.id) &&
                              <CheckCircleSolid className="!size-4" />
                            }
                          </div>
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Listbox>
                  <img
                    className="pointer-events-none absolute right-0 top-[50%] transform translate-y-[-50%]"
                    src={ChevronDown}
                  />
                </div>
                <div className="mt-2 text-sm text-red-500 w-full">{errors?.userGroups}</div>
              </div>
            </div>
            <div className="wrap-form-group max-w-[480px] flex gap-5">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  Password*
                </span>
                <div className="flex w-full border-solid border-t-0 border-l-0 border-r-0 l border-b !border-[#1A1A1A]">
                  <input
                    id="new_password"
                    name="new_password"
                    value={password}
                    className="!outline-none pr-5 text-black w-full font-poppins text-sm font-normal border-none border-black leading-normal rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className={"p"} onClick={regeneratePass}>
                    <Reload className={"py-1"} />
                  </div>
                </div>
                <div className="mt-2 text-sm text-red-500 w-full">{errors?.password}</div>
              </div>
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  Confirm Password*
                </span>
                <div className="flex w-full border-solid border-t-0 border-l-0 border-r-0 l border-b !border-[#1A1A1A]">
                  <input
                    id="confirm_new_password"
                    name="confirm_new_password"
                    value={confirmPassword}
                    className="!outline-none pr-5 text-black w-full font-poppins text-sm font-normal border-none border-black leading-normal rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="mt-2 text-sm text-red-500 w-full">{errors?.confirmPassword}</div>
              </div>
            </div>
          </div>
          <div
            className="flex mt-[50px] justify-center items-center flex-1 h-[37px] px-[18px] py-[12px] m-0 gap-[10px] border border-black font-poppins text-[14px] font-bold leading-normal hover:text-white hover:bg-black hover:cursor-pointer"
            onClick={handleAddNew}
          >
            SAVE
          </div>
        </div>
      }
    />
  );
};
