/** @format */

import React, { Fragment, useMemo, useState } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import OpenIcon from "../../../components/svgs/openIcon";
import { cn } from "../../../helper/utils";
import _, { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { useForm as useFilterForm } from "react-hook-form";

import Filters from "./filters";
import Residence from "./residence";

const MAX_SELECTIONS = 4;

const PopoverResidence = ({
  popoverClassName,
  popoverBtnClassName,
  form,
  selectedUnits,
  pages,
  preferenceUnitIds,
}) => {
  const { control } = form;
  const filtersForm = useFilterForm();
  const [isOpen, setIsOpen] = useState(true);

  const units = useSelector((state) => state.unitExplore?.filteredUnits);

  const [isFilter, setIsFilter] = useState(false);

  const unitGroup = useMemo(() => {
    const bedroomValue = filtersForm?.watch("bedroom");
    const bathroomValue = filtersForm
      ?.watch("bathroom")
      ?.map((item) => item?.split("_")[1]);
    const availabilityValue = filtersForm?.watch("status");
    const poolValue = filtersForm?.watch("pool");

    const minPriceValue = filtersForm?.watch("minPrice");
    const maxPriceValue = filtersForm?.watch("maxPrice");

    const minSizeValue = filtersForm?.watch("minSize");
    const maxSizeValue = filtersForm?.watch("maxSize");

    const minExteriorValue = filtersForm?.watch("minExterior");

    const maxExteriorValue = filtersForm?.watch("maxExterior");

    const minInteriorValue = filtersForm?.watch("minInterior");

    const maxInteriorValue = filtersForm?.watch("maxInterior");

    const filterUnits = units?.filter((unit) => {
      if (bedroomValue?.length) {
        if (!bedroomValue.includes(String(unit.bedrooms))) return false;
      }
      if (bathroomValue?.length) {
        if (!bathroomValue.includes(String(unit.bathrooms))) return false;
      }
      if (availabilityValue?.length) {
        if (!availabilityValue.includes(unit.availabilityStatus)) return false;
      }
      if (poolValue !== undefined) {
        return poolValue?.includes(String(unit.hasPool));
      }
      if (minPriceValue && unit.price < minPriceValue) return false;
      if (maxPriceValue && unit.price > maxPriceValue) return false;
      if (minSizeValue && unit.areaSqm < minSizeValue) return false;
      if (maxSizeValue && unit.areaSqm > maxSizeValue) return false;
      if (minExteriorValue && unit.exterior < minExteriorValue) return false;
      if (maxExteriorValue && unit.exterior > maxExteriorValue) return false;
      if (minInteriorValue && unit.interior < minInteriorValue) return false;
      if (maxInteriorValue && unit.interior > maxInteriorValue) return false;

      return unit;
    });

    return groupBy(filterUnits || [], "level");
  }, [units, filtersForm.watch()]);

  const isMaxSelected = selectedUnits.length >= MAX_SELECTIONS;

  return (
    <Popover
      className={cn(
        "absolute z-[9999] top-[60px] left-[20px]",
        popoverClassName
      )}
      as="div"
    >
      {({ close }) => (
        <>
          <PopoverButton
            className={cn(
              "bg-black w-[50px] h-[50px] rounded-full block",
              {
                "opacity-50": !isOpen,
              },
              popoverBtnClassName
            )}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="w-full flex justify-center items-center">
              <OpenIcon />
            </div>
            {isOpen && (
              <div className="w-full flex justify-center items-center mt-1">
                <div className="w-[4px] h-[4px] rounded-full bg-white" />
              </div>
            )}
          </PopoverButton>
          <Transition
            show={isOpen}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <PopoverPanel
              static
              className={cn({
                flex: isFilter,
              })}
            >
              <Filters
                pages={pages}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
                {...filtersForm}
              />
              <Residence
                preferenceUnitIds={preferenceUnitIds}
                unitGroup={unitGroup}
                setIsFilter={setIsFilter}
                isFilter={isFilter}
                setIsOpen={setIsOpen}
                isMaxSelected={isMaxSelected}
                control={control}
                selectedUnits={selectedUnits}
              />
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PopoverResidence;
