import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { CheckboxGroupField } from "../../../cms/components/commons";
import { cn } from "../../../../helper/utils";

const BathroomForm = ({ control, data, watch, setValue }) => {
  const filterUnitBathroom = useSelector(
    (state) => state.unitExplore.filterUnitBathroom
  );

  useEffect(() => {
    if (filterUnitBathroom) {
      setValue(
        "bathroom",
        _.map(filterUnitBathroom, (item) => `bathroom_${item.value}`)
      );
    }
  }, [filterUnitBathroom]);

  return (
    <div>
      <h2 className="font-normal mb-4 text-primary font-sans text-[14px] leading-[12px] tracking-[1.4px] uppercase mb-0">
        bathrooms
      </h2>
      <CheckboxGroupField
        controller={{
          control,
          name: "bathroom",
        }}
        inputClassName="peer hidden"
        inputWrapperClassName="w-1/2"
        optionsWrapperClassName="flex flex-wrap items-center"
        options={data?.subComponents?.map((item) => ({
          value: `bathroom_${item?.props?.value}`,
          label: item?.props?.value,
        }))}
        customLabel={(v) => (
          <Fragment>
            <label
              htmlFor={v.value}
              className={cn(
                "cursor-pointer flex items-center flex-wrap gap-2 peer-disabled:opacity-50 peer-disabled:cursor-not-allowed"
              )}
            >
              <div
                className={cn(
                  "w-[15px] h-[15px] border-black border-[1px] relative",
                  {
                    "after:w-[8px] after:h-[8px] after:content-[''] after:bg-[var(--brand-border-color)] after:top-1/2 after:right-1/2 after:transform after:translate-y-[-50%] after:translate-x-[50%] after:absolute":
                      watch("bathroom")?.includes(v.value),
                  }
                )}
              />
              <div className="flex gap-1 items-center">
                <span>{v?.label}</span>
              </div>
            </label>
          </Fragment>
        )}
      />
    </div>
  );
};

export default BathroomForm;
