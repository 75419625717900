import React, { useState, useEffect } from "react";
import closeIcon from "../../../assets/images/close-white.svg";
import preferenceApi from "../../../apis/api/preference";
import LoadingIcon from "../../../components/loading-icon";
import { formatMoney } from "../../../helper/unit";
import { getS3FEMediaUrl } from "../../../helper/media";
import { Modal } from "../components/commons";

const Row = ({ data }) => {
  return (
    <div className="grid-cols-[19%_13%_12%_11%_11%_11%_11%_12%] grid">
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center">
        {data?.name}
      </div>
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center capitalize">
        {data?.availabilityStatus}
      </div>
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center">
        {formatMoney(data?.price) || 0}
      </div>
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center">
        {data?.level}
      </div>
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center">
        {data?.areaSqm}
      </div>
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center">
        {data?.bedrooms}
      </div>
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center">
        {data?.bathrooms}
      </div>
      <div className="text-black font-poppins text-[14px] border-b-[1px] border-solid border-gray-100 py-2 font-normal leading-normal flex items-center">
        {data?.hasPool ? "Yes" : "No"}
      </div>
    </div>
  );
};

const CustomerFavoritesModal = ({ customer, show, setShow }) => {
  const [preference, setPreference] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    customer && getCustomerPreference();
  }, [customer]);

  const getCustomerPreference = async () => {
    try {
      setLoading(true);
      const res = await preferenceApi.getCustomerPreference({
        customer: customer?.id,
      });
      setLoading(false);
      setPreference(res.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const renderListProperty = () => {
    return (preference?.units || []).map((item, index) => {
      return <Row key={item?.id} data={item} />;
    });
  };

  const renderGallery = () => {
    if (!preference || !preference?.units) return null;
    const sampleGalleries = preference?.units[0]?.view?.media || [];
    return (
      <>
        <div className="gallery-title">CONTENT</div>
        <div className="gallery">
          {sampleGalleries.slice(0, 3).map((item, index) => {
            return (
              <div key={item.id} className="gallery-item">
                <img src={getS3FEMediaUrl(`/${item.path}`)} />
                <span>Image Title</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (loading) return <LoadingIcon />;

    if (!preference || !preference?.units || !preference?.units?.length)
      return (
        <div className="text-[#1A1A1A] text-[24px] overflow-x-hidden">
          No favorites found.
        </div>
      );

    return (
      <div className="text-[#1A1A1A] text-[24px] overflow-x-hidden">
        <div className="grid pb-[60px] grid-cols-[19%_13%_12%_11%_11%_11%_11%_12%]">
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            RESIDENCE
          </div>
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            AVAILABILITY
          </div>
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            PRICE
          </div>
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            floor
          </div>
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            SQ.FT.
          </div>
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            BEDS
          </div>
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            BATHS
          </div>
          <div className="text-[#000] font-poppins text-[14px] font-bold leading-normal uppercase">
            PRIVATE POOL
          </div>
        </div>
        <div className="max-h-[221px] overflow-y-auto">
          {renderListProperty()}
        </div>
        {/* {renderGallery()} */}
      </div>
    );
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName="wrap-customer-favorites-modal"
      panelClassName="w-[1160px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-100"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] modal-content !min-h-[224px] !w-[1160px]">
          <h2 className="text-black border-b-[1px] border-solid pb-[10px] border-[#ccc] text-[30px] font-[700] uppercase font-poppins">
            {customer?.name}’s Favorites
          </h2>
          <div className="modal-body wrap-modal-body">{renderContent()}</div>
        </div>
      }
    />
  );
};

export default CustomerFavoritesModal;
