import * as Types from "./type";
import virtualTourApi from "../../apis/api/virtual-tour";
import { VIRTUAL_TOUR } from "./type";

export const actSetVirtualTours = (data) => ({
  type: Types.VIRTUAL_TOURS,
  data,
});

export const actSetVirtualTour = (data) => ({
  type: Types.VIRTUAL_TOUR,
  data,
});

//list
export const reqGetVirtualTours = (query, setLoading) => (dispatch) => {
  setLoading && setLoading(true);
  return virtualTourApi
    .getVirtualTours(query)
    .then((data) => {
      dispatch(actSetVirtualTours(data));
      setLoading && setLoading(false);
    })
    .finally(() => {
      setLoading && setLoading(false);
    });
};

//detail
export const reqGetVirtualTour = (id) => (dispatch) => {
  return virtualTourApi.getVirtualTour(id).then((data) => {
    dispatch(actSetVirtualTour(data));
  });
};
