import { VIRTUAL_TOUR, VIRTUAL_TOURS } from "./type";

const initialState = {
  list: [],
  detail: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case VIRTUAL_TOURS:
      return {
        ...state,
        list: action.data.data,
      };

    case VIRTUAL_TOUR:
      return {
        ...state,
        detail: action.data.data,
      };

    default:
      return state;
  }
};
