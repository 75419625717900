/** @format */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingTable from "../../../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../../../configuration/components/Table/EmptyTable";
import playlistApi from "../../../../../../apis/api/playlist";
import mediaApi from "../../../../../../apis/api/media";
import { getS3BEMediaUrl } from "../../../../../../helper/media";
import { Button, Modal } from "../../../../components/commons";
import defaultVideo from "../../../../../../assets/images/cms/icVideo.webp";
import defaultImage from "../../../../../../assets/images/cms/icImg.webp";

const MediasContent = ({ isLoadingTable, medias, unitId }, ref) => {
  const [mediasState, setMediasState] = useState(medias);
  const [removeLoading] = useState(false);
  useEffect(() => {
    setMediasState(medias);
  }, [medias]);

  const onDragEnd = async (result) => {
    if (result && mediasState?.length > 1) {
      const indexDestination = result?.destination?.index;
      const indexSource = result?.source?.index;

      if (
        !result?.destination ||
        !result?.source ||
        indexDestination === indexSource
      )
        return;

      const newMedias = [...mediasState];
      const [reorderedItem] = newMedias.splice(indexSource, 1);
      newMedias.splice(indexDestination, 0, reorderedItem);
      setMediasState(newMedias);

      const playlistOverride = newMedias.map((item) => item.id);

      const res = await playlistApi.updateMediaPlaylist(playlistId, {
        medias: playlistOverride,
      });

      if (res?.data) {
        toast.success("Update playlist successfully");
      } else {
        toast.error("Update playlist failed");
      }
    }
  };

  useImperativeHandle(ref, () => ({
    triggerDragEnd: onDragEnd,
  }));

  const handleHide = (idMedia, index, callBack) => {
    try {
      const param = {
        unitId: unitId,
      };
      mediaApi.addExcludeUnit(idMedia, param).then((res) => {
        if (res && res?.data) {
          const arr = [...medias];
          arr[index].unitMetadata = res.data.unitMetadata;
          setMediasState(arr);
        }
      });
      callBack();
    } catch (e) {
      console.log("Errro hide media", e);
      callBack();
    }
  };

  const handleShow = (idMedia, index, callBack) => {
    try {
      const param = {
        unitId: unitId,
      };
      mediaApi.removeExcludeUnit(idMedia, param).then((res) => {
        if (res && res?.data) {
          const arr = [...medias];
          arr[index].unitMetadata = res.data.unitMetadata;
          setMediasState(arr);
        }
        callBack();
      });
    } catch (e) {
      console.log("Errro hide media", e);
      callBack();
    }
  };

  return (
    <>
      {!isLoadingTable && (
        <Droppable droppableId="gallery-list">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {!!mediasState?.length &&
                mediasState?.map((v, index) => {
                  const isHide =
                    v?.unitMetadata?.excludeUnits?.includes(unitId);
                  return (
                    <Draggable key={v?.id} draggableId={v?.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          key={v?.id}
                          className={`flex items-center rounded gap-5 bg-gray-100 mb-2 p-2`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="flex-1">
                            <div className="flex gap-5">
                              <LazyLoadImage
                                className="w-16 h-16 rounded-lg m-auto object-cover"
                                alt={v?.name}
                                src={getS3BEMediaUrl(v?.thumbnailPath)}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    v?.type === "videos"
                                      ? defaultVideo
                                      : defaultImage;
                                }}
                              />
                              <div className="flex-1 min-w-0">
                                <div>{v?.type}</div>
                                <div className="break-words overflow-wrap-anywhere">
                                  {v?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Modal
                            maskClassName="bg-opacity-50"
                            zIndex="z-[9999]"
                            trigger={
                              <div className="flex gap-5 items-center">
                                <span className="underline text-sm">
                                  {isHide ? "Show" : "Hide"}
                                </span>
                              </div>
                            }
                            content={({ setIsShow }) => (
                              <div>
                                <p className="text-center text-xl">
                                  {isHide
                                    ? "Do you want to show this media from playlist ?"
                                    : "Do you want to hide this media from playlist ?"}
                                </p>
                                <div className="flex gap-4 items-center mt-5">
                                  <Button
                                    disabled={removeLoading}
                                    variant="text"
                                    className="flex-1"
                                    onClick={() => setIsShow(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    isLoading={removeLoading}
                                    className="flex-1 bg-red-500 hover:bg-red-400"
                                    onClick={
                                      isHide
                                        ? () =>
                                            handleShow(v?.id, index, () =>
                                              setIsShow(false)
                                            )
                                        : () =>
                                            handleHide(v?.id, index, () =>
                                              setIsShow(false)
                                            )
                                    }
                                  >
                                    Confirm
                                  </Button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

              {(!mediasState || !mediasState?.length) && (
                <div className="flex justify-center items-center h-full">
                  <EmptyTable />
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      {isLoadingTable && (
        <div className="flex justify-center items-center h-full">
          <LoadingTable />
        </div>
      )}
    </>
  );
};

export default forwardRef(MediasContent);
