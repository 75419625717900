import "./StarButton.css";
import StarAnimation from "./StarAnimation";
import StarIcon from "./StarIcon";
import { cn } from "../../../../helper/utils";

function StarButton({ starred = false, selected = false, ...rest }) {
  return (
    <button
      className={cn(
        `absolute leading-none p-0 bg-transparent border-0 cursor-pointer left-[209px] StarButton ${
          starred ? "StarButton--starred" : ""
        }`,
        rest?.wrapperClassName
      )}
      type="button"
      {...rest}
    >
      <StarAnimation starred={starred} />
      <StarIcon selected={selected} {...rest} />
    </button>
  );
}

export default StarButton;
