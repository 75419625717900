import endPoint from '../endPoint';
import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';

const client = new ApiClient(defaultFetcher);

const createUser = (data) => client.post(endPoint.USER_LIST, data);
const getUserList = (params) => client.get(endPoint.USER_LIST, params);
const getUserGroupsList = (params) => client.get(endPoint.USER_GROUP_LIST, params);
const getUserDetail = (id, data) =>
  client.get(endPoint.USER_DETAIL.replace(':id', id), data);
const updateUser = (id, data) =>
  client.put(endPoint.USER_DETAIL.replace(':id', id), data);
const deleteUser = (id, data) =>
  client.delete(endPoint.USER_DETAIL.replace(':id', id), data);

const getAgentList = (data) =>
  client.get(endPoint.USER_LIST, {
    ...data,
    'isAgent': true,
  });

export default {
  createUser,
  getUserList,
  getUserDetail,
  updateUser,
  deleteUser,
  getUserGroupsList,
  getAgentList,
};
