/** @format */

import _ from "lodash";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { cn } from "../../../helper/utils";
import usersAPI from "../../../apis/api/user";
import { Modal } from "../components/commons";
import React, { useState, useEffect } from "react";
import closeIcon from "../../../assets/images/close-white.svg";
import ChevronDown from "../../../assets/images/chevron-down.svg";
import { CheckCircleSolid } from "../../../components/svgs/icons";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";

const validationSchema = yup.object().shape({
  firstname: yup.string().trim().required("First name is a required field"),
  surname: yup.string().trim().required("Last name is a required field"),
  userGroups: yup.array().of(yup.string().required()).min(1, "Groups is a required field"),
  email: yup
    .string()
    .trim()
    .email("Invalid email format")
    .required("Email is a required field"),
});

export const EditStaffModal = ({ staff, show, setShow, onSaveSuccess, disableRole = false }) => {
  const [firstname, setFirstName] = useState("");
  const [surname, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const [userGroups, setUserGroups] = useState([]);

  const groups = useSelector((state) => state.cms.userGroups);

  useEffect(() => {
    if (staff) {
      setFirstName(staff.firstname || "");
      setSurName(staff.surname || "");
      setEmail(staff.email || "");
      setUserGroups(staff?.userGroups?.map(uG => uG.id) || []);
    }
  }, [staff, groups]);

  const handleUpdate = async () => {
    if (!staff) return;

    try {
      const payload = {
        firstname,
        surname,
        email,
        userGroups,
      };
      setErrors({});
      const result = await validationSchema.validate(payload, {
        abortEarly: false,
      });
      const res = await usersAPI.updateUser(staff.id, result);
      if (res.data) {
        toast.success("User updated!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      } else {
        toast.error("Update user failed!");
      }
    } catch (err) {
      if (err?.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Update user failed!");
    }
  };

  const handleDelete = async () => {
    if (!staff) return;

    try {
      const res = await usersAPI.deleteUser(staff.id);
      if (res.data) {
        toast.success("User deleted successfully!");
        handleCloseDeleteModal();
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      setErrors(err);
      toast.error(err.message);
    }
  };

  const handleOpenDeleteModal = () => {
    setIsShowDelete(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseDeleteModal = () => {
    setIsShowDelete(false);
  };

  if (isShowDelete) {
    return (
      <Modal
        iconCancelMark={() => (
          <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
            <img src={closeIcon} alt="close-icon" />
          </div>
        )}
        panelClassName="w-[396px] p-0 rounded-none"
        zIndex="z-[9998]"
        maskClassName="opacity-100"
        show={isShowDelete}
        setShow={handleCloseDeleteModal}
        content={
          <div className="flex flex-col gap-[14px] p-[40px]">
            <h2 className="text-black font-poppins text-[30px] font-bold uppercase">
              Delete User?
            </h2>
            <div className="text-black mb-[40px] font-poppins text-[14px] font-[400] leading-normal">
              Are you sure you want to delete {firstname} {surname} ({email})?
            </div>
            <div
              className="flex px-[18px] py-2.5 m-0 justify-center items-center gap-[10px] flex-1 border-[1px] border-solid border-[#E11736] text-[#E11736] font-poppins text-sm font-bold uppercase hover:text-white hover:bg-[#E11736] cursor-pointer"
              onClick={handleDelete}
            >
              YES, DELETE
            </div>
          </div>
        }
      />
    );
  }

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName=""
      panelClassName="w-[609px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-100"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] modal-content">
          <h2 className="text-black text-[30px] pb-[50px] font-[700] uppercase font-poppins">
            Edit User
          </h2>
          <div className="wrap-modal-body">
            <div className="max-w-[480px] flex gap-5">
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  First Name*
                </span>
                <input
                  id="firstname"
                  name="firstname"
                  className="border-solid  border-t-0 border-l-0 border-r-0 text-black w-full font-poppins text-sm font-normal leading-normal border-b !border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={firstname}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <span className="mt-2 text-sm text-red-500 w-full">{errors?.firstname}</span>
              </div>
              <div className="flex-1 pt-[13px]">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  Last Name*
                </span>
                <input
                  id="surname"
                  name="surname"
                  className="border-solid  border-t-0 border-l-0 border-r-0 text-black w-full font-poppins text-sm font-normal leading-normal border-b !border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  value={surname}
                  placeholder="Last Name"
                  onChange={(e) => setSurName(e.target.value)}
                />
                <span className="mt-2 text-sm text-red-500 w-full">{errors?.surname}</span>
              </div>
            </div>
            <div className="max-w-[480px] flex gap-5 mt-[50px]">
              <div className="flex-1">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  Email*
                </span>
                <input
                  id="email"
                  name="email"
                  value={email}
                  className=" border-solid  border-t-0 border-l-0 border-r-0 text-black  w-full font-poppins text-sm font-normal leading-normal border-b !border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[23px] [&>*]:bg-white [&>*]:w-[100px]"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="mt-2 text-sm text-red-500 w-full">{errors?.email}</span>
              </div>
            </div>
            <div className="max-w-[480px] flex gap-5 mt-[50px]">
              <div className="flex-1 ">
                <span className="w-full inline-block text-black font-poppins text-sm font-bold leading-normal uppercase mb-[15px]">
                  ROLES*
                </span>
                <div className="w-full relative border-b border-black">
                  <Listbox value={userGroups} onChange={setUserGroups} multiple>
                    <ListboxButton
                      disabled={disableRole}
                      className="min-h-[23px] pr-5 w-full text-left text-sm font-normal font-poppins"
                    >
                      {disableRole
                        ? (staff?.userGroups || [])?.map(uG => uG?.name)?.join(', ')
                        : userGroups?.length && groups?.length
                          ? userGroups.reduce((acc, uG) => {
                            const matchingGr = groups.find(g => g.id === uG);
                            return matchingGr ? acc.concat(matchingGr.name) : acc;
                          }, []).join(', ')
                          : <span className="text-gray-500">Select roles</span>
                      }
                    </ListboxButton>
                    <ListboxOptions
                      anchor="bottom start"
                      className="w-[480px] bg-white z-[10000] py-2 mt-1 rounded-lg border-2 border-black/40"
                    >
                      {groups?.length && groups.map((gr, _i) => (
                        <ListboxOption
                          key={gr.id}
                          value={gr.id}
                          className={`px-2 bg-white text-sm font-normal font-poppins data-[focus]:bg-green-100 cursor-pointer ${_i !== 0 ? 'border-t-2 border-t-gray-100' : ''}`}
                        >
                          <div className="flex justify-between items-center">
                            {gr.name}
                            {
                              userGroups?.some(selectedId => selectedId === gr.id) &&
                              <CheckCircleSolid className="!size-4" />
                            }
                          </div>
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Listbox>
                  {
                    !disableRole &&
                    <img
                      className="pointer-events-none absolute right-0 top-[50%] transform translate-y-[-50%]"
                      src={ChevronDown}
                    />
                  }
                </div>
                <span className="mt-2 text-sm text-red-500 w-full">{errors?.userGroups}</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-[50px]">
            <span
              className="flex justify-center items-center h-[37px] px-[18px] py-[12px] m-0 gap-[10px] border border-black font-poppins text-[14px] font-bold leading-normal hover:text-white hover:bg-black hover:cursor-pointer"
              onClick={handleUpdate}
            >
              Save
            </span>
            <span
              className="text-black/50 text-center font-poppins text-sm font-semibold p-0 border-none bg-none m-0 cursor-pointer hover:text-black"
              onClick={handleOpenDeleteModal}
            >
              DELETE USER
            </span>
          </div>
        </div>
      }
    />
  );
};
