import React, { Fragment } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { StarIcon } from "@heroicons/react/20/solid";
import { CheckboxGroupField } from "../../../cms/components/commons";
import { cn } from "../../../../helper/utils";

const Residence = ({
  setIsFilter,
  isFilter,
  setIsOpen,
  preferenceUnitIds,
  unitGroup,
  isMaxSelected,
  selectedUnits,
  control,
}) => {
  return (
    <div className="border-solid mt-5 bg-white w-[250px] max-h-[600px] overflow-y-auto border-[1px] border-[#242422]">
      <div className="p-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <FunnelIcon
              width={20}
              height={20}
              className="text-black cursor-pointer"
              onClick={() => setIsFilter(!isFilter)}
            />
            <h2 className="text-[14px]">RESIDENCES</h2>
          </div>
          <XMarkIcon
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              setIsFilter(false);
            }}
          />
        </div>
        <div className="mt-1 pb-3 border-b-[1px] border-gray-300">
          <div className="flex items-center gap-2">
            {!!preferenceUnitIds?.length && (
              <Fragment>
                <span className="text-green-600 text-[12px]">
                  <StarIcon width={12} height={12} className="text-blue-500" />
                </span>
                <span className="text-[12px]">Favorited</span>
              </Fragment>
            )}
          </div>
        </div>
        <div className="mt-3">
          {unitGroup &&
            (Object.keys(unitGroup) || []).map((valueKey, key) => {
              const items = unitGroup[valueKey];

              return (
                <li
                  className={
                    "text-primary text-left text-sm font-sans list-none font-normal leading-[200%] p-0 opacity-100"
                  }
                  key={`floor-${key}`}
                >
                  <div className="mb-5">
                    <p
                      className={
                        "text-base uppercase mb-[15px] tracking-[1.4px] cursor-default"
                      }
                    >
                      {`Floor ${valueKey}`}
                    </p>
                    <CheckboxGroupField
                      controller={{
                        control,
                        name: `floor-${valueKey}`,
                      }}
                      inputClassName="peer hidden"
                      options={items.map((item) => ({
                        value: item.id,
                        label:
                          item.name.includes("Penthouse") ||
                          item.name.includes("Bayhouse")
                            ? item.name
                            : `${item.name}`,
                        disabled:
                          isMaxSelected && !selectedUnits.includes(item.id),
                      }))}
                      customLabel={(v) => (
                        <Fragment>
                          <label
                            htmlFor={v.value}
                            className={cn(
                              "cursor-pointer flex items-center flex-wrap gap-2 peer-disabled:opacity-50 peer-disabled:cursor-not-allowed"
                            )}
                          >
                            <div
                              className={cn(
                                "w-[15px] h-[15px] border-black border-[1px] relative",
                                {
                                  "after:w-[8px] after:h-[8px] after:content-[''] after:bg-[var(--brand-border-color)] after:top-1/2 after:right-1/2 after:transform after:translate-y-[-50%] after:translate-x-[50%] after:absolute":
                                    selectedUnits.includes(v.value),
                                }
                              )}
                            />
                            <div className="flex gap-1 items-center">
                              <span>{v?.label}</span>
                              {preferenceUnitIds?.includes(v?.value) && (
                                <StarIcon
                                  width={12}
                                  height={12}
                                  className="text-blue-500"
                                />
                              )}
                            </div>
                          </label>
                          {!!selectedUnits?.length &&
                            selectedUnits.includes(v.value) && (
                              <span className="text-[10px]">
                                ({selectedUnits?.length}/4)
                              </span>
                            )}
                        </Fragment>
                      )}
                    />
                  </div>
                </li>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Residence;
