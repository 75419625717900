import React from "react";

import { cn } from "../../../../../helper/utils";
import { Button, Modal } from "../../../components/commons";
import TrashIcon from "../../../configuration/components/svgs/TrashIcon";

const PanoramaList = ({
  virtualTourData,
  setSceneIndex,
  sceneIndex,
  setVirtualTourData,
}) => {
  const handleRemoveScene = (sceneIndex, onSuccess) => {
    const newScenes = [...virtualTourData.scenes];

    newScenes.splice(sceneIndex, 1);

    setVirtualTourData((prevData) => ({
      ...prevData,
      scenes: newScenes,
    }));
    setSceneIndex(0);
    onSuccess && onSuccess();
  };

  return (
    <div className="mt-4 border rounded border-gray-400 p-2">
      {!!virtualTourData?.scenes?.length && (
        <h3 className="font-medium mb-2">List Panorama</h3>
      )}
      {virtualTourData?.scenes?.map((scene, index) => (
        <div className="flex items-center gap-5" key={scene?.id}>
          <div
            onClick={() => setSceneIndex(index)}
            className={cn(
              "p-2 flex-1 rounded cursor-pointer hover:bg-gray-200 mb-2 transition-colors",
              {
                "bg-blue-100": index === sceneIndex,
              }
            )}
          >
            {"Scene # " + scene?.mediaId?.slice(-4)}
          </div>
          <Modal
            maskClassName="bg-opacity-50"
            zIndex="z-[9998]"
            trigger={
              <Button variant="icon" className="text-red-500 p-0">
                <TrashIcon />
              </Button>
            }
            content={({ setIsShow }) => (
              <div>
                <h2 className="text-center text-lg pb-3 font-medium">
                  Are you sure you want to delete this scene ?
                </h2>
                <div className="flex gap-4 justify-center">
                  <Button variant="text" onClick={() => setIsShow(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() =>
                      handleRemoveScene(index, () => setIsShow(false))
                    }
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default PanoramaList;
