import React, { Fragment, useEffect, useRef } from "react";
import { Button, Modal } from "../../components/commons";
import {
  PlusIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/16/solid";
import CreateNewVirtualTour from "./CreateNewVirtualTour";
import useQuery from "../../../../hooks/useQuery";
import { cn } from "../../../../helper/utils";
import { useParams } from "react-router-dom";

const Header = ({ virtualToursRes }) => {
  const { push, query } = useQuery();
  const params = useParams();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (virtualToursRes?.length && !query?.id) {
      push({
        id: virtualToursRes[0]?.id,
      });
    }
  }, [virtualToursRes, params, query]);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200;
      scrollContainerRef.current.scrollLeft += direction * scrollAmount;
    }
  };

  return (
    <div className="border-b border-gray-300">
      <div className="px-5">
        <div className="flex py-5 items-center justify-between gap-4">
          <h1 className="font-bold text-xl shrink-0 w-48">Authored content</h1>

          <div className="relative max-w-[calc(100%-350px)]">
            {!!virtualToursRes?.length && (
              <Fragment>
                <button
                  onClick={() => handleScroll(-1)}
                  className="absolute left-[-10px] top-1/2 -translate-y-1/2 z-20 p-1 "
                >
                  <ChevronLeftIcon className="w-8 h-8" />
                </button>
                <button
                  onClick={() => handleScroll(1)}
                  className="absolute right-[-10px] top-1/2 -translate-y-1/2 z-20 p-1 rounded-full "
                >
                  <ChevronRightIcon className="w-8 h-8" />
                </button>
              </Fragment>
            )}

            <div
              ref={scrollContainerRef}
              className="overflow-x-auto mx-6 no-scrollbar"
            >
              <div className="flex min-w-fit border border-gray-300 rounded">
                {virtualToursRes?.map((v) => (
                  <Button
                    onClick={() =>
                      push({
                        id: v?.id,
                      })
                    }
                    key={v?.id}
                    className={cn(
                      "whitespace-nowrap bg-transparent rounded-none text-black border-r border-gray-300 hover:bg-gray-500 hover:text-white last:border-r-0 px-3 py-1.5 text-sm",
                      {
                        "bg-gray-500 text-white": query?.id === v?.id,
                      }
                    )}
                  >
                    {v?.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>

          {/* Fixed width button container */}
          <div className="shrink-0 w-32">
            <Modal
              maskClassName="bg-opacity-50"
              zIndex="z-[9998]"
              title="Add new virtual tour"
              maskClosable
              trigger={
                <Button
                  icon={<PlusIcon className="w-5 h-5" />}
                  className="w-full"
                >
                  Add New
                </Button>
              }
              content={({ setIsShow }) => {
                return (
                  <CreateNewVirtualTour onClose={() => setIsShow(false)} />
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
