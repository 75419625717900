import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { Button, InputField } from "../../components/commons";
import virtualTourApi from "../../../../apis/api/virtual-tour";

import { reqGetVirtualTours } from "../../../../reduxs/virtual-tour/action";

const CreateVirtualTourForm = ({ onClose }) => {
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    virtualTourName: yup
      .string()
      .required("Virtual tour name is required")
      .max(20, "Max length is 20 characters"),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      virtualTourName: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const handleCreate = async (v) => {
    const res = await virtualTourApi.createVirtualTour({
      name: v.virtualTourName,
    });

    if (res.data) {
      toast.success("Create virtual tour successfully");
      dispatch(reqGetVirtualTours());
    } else {
      toast.error("Create virtual tour failed");
    }
    onClose();
  };

  return (
    <div className="bg-white rounded-lg  relative">
      <div className="mb-5">
        <InputField
          required
          label="Virtual tour name"
          controller={{ control, name: "virtualTourName" }}
          inputProps={{ placeholder: "Please input name of virtual tour" }}
        />
      </div>

      <div className="flex gap-3 mt-8">
        <Button
          disabled={formState?.isSubmitting}
          variant="text"
          onClick={onClose}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          disabled={formState?.isSubmitting || !formState?.isDirty}
          isLoading={formState?.isSubmitting}
          onClick={handleSubmit(handleCreate)}
          className="flex-1"
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreateVirtualTourForm;
