import { COMPARE_HISTORY } from "./type";

const initialState = {
  compareHistory: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case COMPARE_HISTORY:
      return {
        ...state,
        compareHistory: action.data,
      };

    default:
      return state;
  }
};
