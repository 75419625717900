/** @format */

import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../assets/images/close-white.svg";
import { Modal, Button } from "../components/commons";
import sessionApi from "../../../apis/api/session";
import { toast } from "react-toastify";
import ItemUnitData from "./ItemUnitData";
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const ViewModal = ({ show, setShow, idCustomer }) => {
  const [detailSession, setDetailSession] = useState([]);
  const [search, setSearch] = useState('');
  const refUnitId = useRef(null); // use to delete unit
  const [showModalDeleteUnit, setShowModalDeleteUnit] = useState(false);
  const [showModalUnlink, setShowModalUnlink] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const refSessionSelected = useRef();

  useEffect(() => {
    handleGetListSession();
  }, []);

  const handleGetListSession = async () => {
    try {
      const res = await sessionApi.getSessionList({
        "customer[equalId]": idCustomer.id,
      });
      if (res && res?.data) {
        setDetailSession(res.data);
      }
    } catch (e) {
      console.log("Error load list session", e);
    } finally {
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDeleteUnit = async () => {
    try {
      const params = {
        removeUnit: refUnitId.current?.unitId,
      };
      const deleted = await sessionApi.deleteSessionUnit(
        refUnitId.current?.preferenceId,
        params
      );
      if (deleted.data) {
        toast.success("Session deleted successfully!");
        handleGetListSession();
        refUnitId.current = null;
      }
    } catch (err) {
      toast.error(`Error Deteted!`);
      console.log("Error Deteted", err);
    } finally {
      setShowModalDeleteUnit(false);
    }
  };

  const handleUnlink = async () => {
    try {
      const params = {
        customer: null,
      };
      const res = await sessionApi.unLinkSessionToCustomer(
        refSessionSelected.current,
        params
      );
      if (res && res.data) {
        toast.success("Successfully!");
        handleGetListSession();
      }
    } catch (err) {
      toast.error(`Error, Try again`);
      console.log("Error Deteted", err);
    } finally {
      setShowModalUnlink(false);
    }
  };

  const handleShowModalUnlink = (item) => {
    refSessionSelected.current = item.id;
    setShowModalUnlink(true);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName=""
      panelClassName="w-[calc(100vw-300px)] p-0 rounded-lg"
      zIndex="z-[9998]"
      maskClassName="opacity-100"
      show={show}
      setShow={handleClose}
      content={
        <div className="modal-content py-2 px-5">
          <div className="flex justify-between pb-[50px]">
            <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
              {idCustomer?.name} <span className="font-[400]">Sessions</span>
            </h2>
            <div class="flex items-center">
              <div class="relative w-full">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <MagnifyingGlassIcon className="size-4 text-gray-500" />
                </div>
                <input
                  type="text"
                  id="simple-search"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                  placeholder="Search session/agent/customer"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                  required
                />
              </div>
              <button
                class="rounded-xl ml-2 bg-gray-50 border-gray-300 text-gray-900 p-2 border-2 text-center text-sm transition-all shadow-sm hover:shadow-lg active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                onClick={() => setExpandAll(!expandAll)}
              >
                {expandAll
                  ? <ArrowsPointingOutIcon className="size-5 text-gray-900" />
                  : <ArrowsPointingInIcon className="size-5 text-gray-900" />
                }

              </button>
            </div>
          </div>
          <div className="wrap-modal-body">
            <div className="h-[calc(100dvh-300px)] overflow-y-scroll pr-3">
              <div>
                {detailSession &&
                  detailSession.length > 0 &&
                  detailSession
                    .filter(ss => (
                      String(ss.name).toLowerCase().includes(search.toLowerCase()) ||
                      String(ss?.agent?.name).toLowerCase().includes(search.toLowerCase()) ||
                      String(ss?.customer?.name).toLowerCase().includes(search.toLowerCase())
                    ))
                    .map((i) => {
                      return (
                        <ItemUnitData
                          data={i}
                          key={i.id}
                          expandAll={expandAll}
                          handleShowModalUnlink={handleShowModalUnlink}
                          setShowModalUnlink={setShowModalUnlink}
                          handleGetListSession={handleGetListSession}
                        />
                      );
                    })}
              </div>
            </div>
          </div>
          <Modal
            show={showModalDeleteUnit}
            maskClassName="bg-opacity-50"
            zIndex="z-[9999]"
            content={() => (
              <div>
                <p className="text-center text-xl">
                  Do you want to remove this Unit ?
                </p>
                <div className="flex gap-4 items-center mt-5">
                  <Button
                    variant="text"
                    className="flex-1"
                    onClick={() => setShowModalDeleteUnit(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="flex-1 bg-red-500 hover:bg-red-400"
                    onClick={handleDeleteUnit}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            )}
          />
          {showModalUnlink && (
            <Modal
              show={showModalUnlink}
              maskClassName="bg-opacity-50"
              zIndex="z-[9999]"
              content={() => (
                <div>
                  <p className="text-center text-xl">
                    Do you want to unlink this session ?
                  </p>
                  <div className="flex gap-4 items-center mt-5">
                    <Button
                      variant="text"
                      className="flex-1"
                      onClick={() => setShowModalUnlink(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="flex-1 bg-red-500 hover:bg-red-400"
                      onClick={handleUnlink}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      }
    />
  );
};

export default ViewModal;
